import styled from 'styled-components'
import { colorPallete, viewSize, defaultSize } from '../../../config'


export const Input = styled.input`
    border : ${props => props.border ? props.border : `1px solid ${colorPallete.line}`};
    height : ${props => props.height ? props.height : defaultSize.desktop.inputHeight};
    padding : ${props => props.padding ? props.padding : defaultSize.desktop.inputPadding};
    box-sizing : border-box;
    width :100%;
    @media screen and (max-width: ${viewSize.mobile}) {
        height : ${props => props.height ? props.height : defaultSize.mobile.inputHeight};
        padding : ${props => props.padding ? props.padding : defaultSize.mobile.inputPadding};
    }
`

