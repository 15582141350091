import React from "react";
import * as S from "./style";

const Link = ({
	children,
	color,
	fontSize,
	fontWeight,
	href,
	onClick,
	decoration,
	border,
	radius,
	bgColor,
	padding,
	lIcon,
	minWidth,
	cursor,
	target,
	hoverColor,
	hoverBgColor,
}) => {
	const linkProps = {
		color,
		fontSize,
		fontWeight,
		href,
		onClick,
		decoration,
		border,
		bgColor,
		radius,
		padding,
		minWidth,
		cursor,
		target,
		hoverColor,
		hoverBgColor,
	};
	return (
		<S.Link {...linkProps}>
			{lIcon && lIcon}
			{children}
		</S.Link>
	);
};

export default Link;
