import styled from "styled-components";

export const Container = styled.div`
	height: 80vh;
	width: 100%;
`;

export const IconWrapper = styled.div`
	display: flex;
	flex-direction: column;
	align-item: center;
	justify-content: center;
	width: 41px;
	height: 91px;
	margin-top: -90px;
	margin-left: -20px;
`;

export const RowWrapper = styled.div`
	display: flex;
	align-item: center;
	justify-content: center;
`;

export const Icon = styled.img``;
