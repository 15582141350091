import {
	fontWeight,
	colorPallete,
	desktopFontSize,
	mobileFontSize,
} from "../config";

export const sectionProps = {
	title: {
		color: colorPallete.black,
		fontSize: {
			desktop: desktopFontSize.xl5,
			tablet: desktopFontSize.xl5,
			mobile: mobileFontSize.xl6,
		},
		fontWeight: fontWeight.extraBold,
	},
	description: {
		color: colorPallete.black,
		fontSize: {
			desktop: desktopFontSize.lg,
			tablet: desktopFontSize.lg,
			mobile: mobileFontSize.base,
		},
		fontWeight: fontWeight.medium,
	},
};

export const radioProps = {
	fontSize: {
		desktop: desktopFontSize.xl4,
		mobile: mobileFontSize.xl5,
	},
	fontWeight: fontWeight.bold,
	opacity: 0.7,
};
export const partnersRadioProps = {
	fontSize: {
		desktop: desktopFontSize.xl2,
		mobile: mobileFontSize.xl,
	},
	fontWeight: fontWeight.bold,
	opacity: 0.7,
};

export const cardProps = {
	width: {
		desktop: " 95%", //"19.5rem",
		mobile: " 90%", // "9.375rem",
	},
	height: {
		desktop: "11rem", // "10rem",
		mobile: "7.875rem", //"4.875rem",
	},
	bgColor: colorPallete.bg,
};

export const breadcrumbsProps = {
	breadcrumb: {
		color: colorPallete.txt,
		fontSize: {
			desktop: desktopFontSize.sm2,
			tablet: desktopFontSize.sm2,
			mobile: mobileFontSize.xs1,
		},
		fontWeight: fontWeight.semiBold,
	},
	title: {
		fontSize: {
			desktop: desktopFontSize.xl6,
			tablet: desktopFontSize.xl6,
			mobile: mobileFontSize.xl6,
		},
		fontWeight: fontWeight.extraBold,
		color: colorPallete.display,
	},
};

export const subCategoryProps = {
	color: colorPallete.head,
	fontSize: {
		desktop: desktopFontSize.lg,
	},
	fontWeight: fontWeight.semiBold,
};
