import styled from 'styled-components'
import { colorPallete, viewSize } from '../../../config'
import desktopBg from '../../../static/images/desktop_contact_wave.svg'
import { SeaWave } from '../../../animation/intro'

export const Container = styled.div`
    display: flex;
    position: relative ;
    flex-direction : column;
    align-items : center;
    justify-content : center;
    padding : 4rem auto;
    overflow: hidden;
    background: linear-gradient(white, 20%, ${colorPallete.sky});
    z-index: 0;
`

export const WaveContact = styled.img`
    position: absolute;
    width: 100%;
    z-index: -1;
    top: 8rem;
    left: 0;
    animation: ${SeaWave} 5s linear infinite;
`

export const Wrapper = styled.div`
    max-width : 1210px;
    margin : auto;
    width : 100%;
`

export const TitleWrapper = styled.div`
    margin : 1rem auto;
    padding-top : 6rem;
    
`
export const FormWave = styled.div`
    width: 100%;
    background-size : cover;
    background-repeat: no-repeat;
    background-image : url(${desktopBg});
    padding: 12rem 0 7rem 0;
    @media screen and (max-width : ${viewSize.mobile}){
        padding: 7rem 0;
    }
`

export const FormWrapper = styled.div`
    display : flex;
    flex-wrap : wrap;
    width : 100%;
    
    @media screen and (max-width : ${viewSize.mobile}){
        flex-direction : column;
    }
`

export const LeftForm = styled.div`
    display : flex;
    width : 48%;
    flex-direction : column;
    margin : auto;

    > div {
        margin-top : 1rem;
    }
    @media screen and (max-width : ${viewSize.mobile}){
        width : 90%
    }
`

export const RightForm = styled.div`
    display : flex;
    width : 48%;
    margin : 0 auto;

    > div {
        margin-top : 1rem;
    }
    @media screen and (max-width : ${viewSize.mobile}){
        width : 90%
    }
`

export const BottomForm = styled.div`
    display : flex;
    width : 100%;
    margin : 1rem auto;
    @media screen and (max-width : ${viewSize.mobile}){
        flex-direction : column;
    }
`

export const CheckFormWrapper = styled.div`
    width : 48%;
    margin : auto;

    @media screen and (max-width : ${viewSize.mobile}){
        width : 90%
    }
`

export const ButtonFormWrapper = styled.div`
    width : 48%;
    margin : auto;

    @media screen and (max-width : ${viewSize.mobile}){
        margin-top : 1rem;
        width : 90%
    }
`