import styled from "styled-components";
import { viewSize } from "../../../config";

export const Container = styled.div`
    width : 100%;
    display : flex;
    flex-direction : column;
    align-items : ${props => props.alignItem ? props.alignItem : 'center'};

    @media screen and (max-width : ${viewSize.tablet}){
        align-items : center;
    }
`

export const TitleWrapper = styled.div`
    margin-bottom : 1rem;
    @media screen and (max-width : ${viewSize.tablet}){
        margin-bottom : 0.5rem;
    }
`

export const DesriptionWrapper = styled.div``