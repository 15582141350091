import { CgClose, CgMenuRightAlt } from "react-icons/cg";

export const desktopFontSize = {
  xs: "0.75rem",
  sm1: "0.875rem",
  sm2: "0.938rem",
  base: "1rem",
  lg: "1.125rem",
  xl: "1.25rem",
  xl2: "1.5rem",
  xl3: "1.75rem",
  xl4: "2rem",
  xl5: "3rem",
  xl6: "3.75rem",
  xl7: "4.375rem",
  xl8: "7.5rem",
};

export const mobileFontSize = {
  xs1: "0.5rem",
  xs2: "0.625rem",
  sm: "0.75rem",
  base: "0.875rem",
  lg: "0.938rem",
  xl: "1rem",
  xl2: "1.125rem",
  xl3: "1.25rem",
  xl4: "1.75rem",
  xl5: "2rem",
  xl6: "2.25rem",
  xl7: "3.25rem",
};

export const viewSize = {
  smallMobile: "30rem",
  mobile: "50rem",
  tablet: "87.5rem",
  desktop: "130rem",
};

export const fontWeight = {
  black: 900,
  extraBold: 800,
  bold: 700,
  semiBold: 600,
  medium: 500,
  normal: 400,
};

export const colorPallete = {
  primary: "#61b7f5",
  secondary: "#005a97",
  tertiary: "#009949",
  sky: "#b7e1ff",
  black: "black",
  white: "white",
  bg: "#f6f8fa",
  display: "#1e232b",
  head: "#3c4051",
  txt: "#666f7e",
  subtxt: "#949ba8",
  cancel: "#c8cdd0",
  warn: "#ff4747",
  line: "#d9e0e1",
  modalBackground: "rgba(0,0,0,0.3)",
};

export const defaultSize = {
  desktop: {
    inputHeight: "3.75rem",
    inputPadding: "1.25rem",
    buttonHeight: "5rem",
    container: "85.25rem",
    cardWidth: "19.5rem",
    selectCardWidth: "26.75rem",
    storyWidth: "15.75rem",
    iconButtonHeight: "3rem",
    iconButtonWidth: "3rem",
  },
  mobile: {
    inputHeight: "3rem",
    inputPadding: "0.875rem",
    buttonHeight: "3.5rem",
    container: "29.75rem",
    cardWidth: "10rem",
    selectCardWidth: "12.5rem",
    storyWidth: "10rem",
  },
};

export const navIcon = {
  openIcon: CgMenuRightAlt,
  closeIcon: CgClose,
  size: 30,
};

export const GOOGLE_API_KEY = "AIzaSyClxWaEmVXQC8taNaRWzy9PC_fWZKL-pig";
export const LAMBDA_URL =
  "https://faq6lnms3h.execute-api.ap-northeast-2.amazonaws.com/jira/chat";
