import styled from 'styled-components'
import { colorPallete } from '../../../config'


export const Container = styled.div`
    border : ${props => props.border ? props.border : `1px solid ${colorPallete.line}`};
    background-color : ${props => props.bgColor ? props.bgColor : `${colorPallete.white}`};
    display : flex;
    align-items : center;
    justify-content : center;
    box-sizing : border-box;
    padding : 0.25rem;
    border-radius : 0.25rem;
    height : ${props => props.height ? props.height : '2rem'};
    width : ${props => props.width ? props.width : '2rem'};
`

export const HiidenCheckBox = styled.input`
    position: fixed;
    top : 99999999999px;
`

export const CheckBox = styled.div`
    display: flex;
    align-items : center;
    justify-content : center;
    color : ${props => props.color ? props.color : colorPallete.line};
`