import React from "react";
import * as S from './style'
import { Text } from '../index'

const Button = ({ text, onClick, bgColor, height, RightIcon, LeftIcon }) => {
    const buttonProps = {
        onClick,
        bgColor,
        height,
    }

    return (
        <S.Button {...buttonProps}>
            {LeftIcon}
            <Text {...text} />
            {RightIcon}
        </S.Button>
    )
}

export default Button