import React, { useState, useEffect } from "react";
import { Link, Text, Button } from "../../atom";
import { FooterLink } from "../Footer";
import * as S from "./style";


import ImageViewer from "react-simple-image-viewer";

const Menus = ({ mains = [] }) => {
	return mains.map((menu, index, array) => (<Link key={index} {...menu} />))
}

const DetailMenuItem = ({ menus = [] }) => {
	return menus.map((menu, index, array) => (<S.MenuItem key={index}><Link {...menu} /></S.MenuItem>))
}

const DetailMenus = ({ details = [] }) => {
	return details.map((detail, index, array) => (
		<S.DetailMenu key={index}>
			<S.MenuTitle>
				<Link {...detail.title} />
			</S.MenuTitle>
			<S.MenuItemWrapper>
				<DetailMenuItem menus={detail.menus} />
			</S.MenuItemWrapper>
		</S.DetailMenu>))
}

export const Info = ({ info, margin }) => {
	return info.map((item, index, array) => (
		<S.Text key={index} margin={margin}>
			<Text {...item.title} />
			<Text {...item.content} />
		</S.Text>
	))
}

const Buttons = ({ buttons }) => {
	return buttons.map((button, index, array) => (
		<S.Button key={index}>
			<Button {...button} />
		</S.Button>
	))
}

const Nav = ({ mains = [], details = [], links = [], buttons = [], info, logo, images, isOpen, setOpen }) => {
	const [isViewerOpen, setIsViewerOpen] = useState(false)
	const [hide, setHide] = useState(false);
	const [scrollY, setScrollY] = useState(0);

	const scrollHandler = () => {
		const { pageYOffset } = window;
		const deltaY = pageYOffset - scrollY;
		setScrollY(window.scrollY || document.documentElement.scrollTop);
		setHide(pageYOffset !== 0 && deltaY >= 0 && !isOpen);
	};

	useEffect(() => {
		window.addEventListener('scroll', scrollHandler);
	});


	buttons[0].onClick = () => {
		setIsViewerOpen(true)
	}
	return (
		<React.Fragment>
			<S.Container hide={hide} isTop={scrollY <= 300} open={isOpen}>
				<S.NavWrapper open={isOpen}>
					<S.LogoWrapper src={logo} alt='로고 이미지' onClick={() => window.location.href = '/'} />
					{isOpen ? (
						<></>
					) : (
						<S.MenuWrapper>
							<Menus mains={mains} />
						</S.MenuWrapper>
					)}
					<S.Icon open={isOpen}>
						<label>
							<input className="check" type="checkbox" checked={isOpen} onChange={() => isOpen ? setOpen(false) : setOpen(true)} />
							<svg viewBox="0 0 100 100" xmlns="http://www.w3.org/2000/svg">
								<path className="line--1" d="M0 40h62c13 0 6 28-4 18L35 35" />
								<path className="line--2" d="M0 50h70" />
								<path className="line--3" d="M0 60h62c13 0 6-28-4-18L35 65" />
							</svg>
						</label>
					</S.Icon>
				</S.NavWrapper>
				<S.DetailMenuMove open={isOpen}>
					<S.DetailMenuWrapper >
						<DetailMenus details={details} />
						<S.FooterWrapper>
							<S.InfoWrapper>
								<S.LinkWrapper>
									<FooterLink links={links} />
								</S.LinkWrapper>
								<S.TextWrapper>
									<Info info={info} />
								</S.TextWrapper>
							</S.InfoWrapper>
							<S.ButtonWrapper>
								<Buttons buttons={buttons} />
							</S.ButtonWrapper>
						</S.FooterWrapper>
					</S.DetailMenuWrapper>
				</S.DetailMenuMove>
				{isViewerOpen && (
					<ImageViewer
						backgroundStyle={{ zIndex: 100000000000, backgroundColor: 'black' }}
						src={images}
						currentIndex={0}
						disableScroll={false}
						closeOnClickOutside={true}
						onClose={() => setIsViewerOpen(false)}
					/>
				)}
			</S.Container>
		</React.Fragment>
	)
}
export default Nav
