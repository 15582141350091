import { keyframes, css } from "styled-components";

export const firstBack = keyframes`
	0%{
		opacity:1;
		z-index : 1;
	}
	50% {
		opacity: 0;
		z-index : 0;
	}
	100% {
		opacity:1;
		z-index : 1;
	}
`;

export const firstBackAnimation = css`
	${firstBack} 8s linear infinite;
`

export const secondBack = keyframes`
	0%{
		opacity:0;
		transform: translate(0);
		z-index : 0;
	}
	50% {
		opacity: 1;
		transform: translate(-5px, 5px);
		z-index : 1;
	}
	100% {
		opacity:0;
		transform: translate(0);
		z-index : 0;
	}
`;

export const mainSecondBack = keyframes`
	0%{
		opacity:0;
		transform: translate(0) rotate(310deg);
		z-index : 0;
	}
	50% {
		opacity: 1;
		transform: translate(-5px, 5px) rotate(310deg);
		z-index : 1;
	}
	100% {
		opacity:0;
		transform: translate(0) rotate(310deg);
		z-index : 0;
	}
`;

export const secondBackAnimation = css`
	${secondBack} 8s linear infinite;
`

export const mainSecondBackAnimation = css`
	${mainSecondBack} 8s linear infinite;
`