import styled from 'styled-components'


export const Container = styled.div`
    width : 100%;
    display : flex;
    flex-direction : column;
    align-items : start;
`

export const FormWrapper = styled.div`
    display : flex;
    align-items : center;
`

export const CheckBoxWrapper = styled.div`
    margin-right : 0.5rem;
`

export const TextWrapper = styled.div``

export const LinkWrapper = styled.div``

export const DescriptionWrapper = styled.div`
    margin-left : 2.55rem;
`