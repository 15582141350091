import React from "react";
import { breadcrumbProps, privacyProps } from "./privacy_data";
import { Wave } from "../../components/atom";
import * as S from "../../components/template/ProjectsSection/style";
import { TextForms } from "../../components/organism";
import { Breadcrumb } from "../../components/template";
import wave_project from "../../static/images/wave_main.png";
import { waveProps } from "../project/data";

const Privacy = () => {
  return (
    <React.Fragment>
      <Breadcrumb {...breadcrumbProps} />
      <S.WaveBlock>
        <S.WaveProject src={wave_project} />
      </S.WaveBlock>
      <Wave {...waveProps} />
      <TextForms texts={privacyProps} />
    </React.Fragment>
  );
};

export default Privacy;
