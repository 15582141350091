import { keyframes, css } from "styled-components";


export const buoyActive = keyframes`
    from{
        transform: translateY(5px)
    }
    50%{
        transform: translateY(-10px)
    }
    to{
        transform: translateY(5px)
    }
`

export const sBuoyActive = keyframes`
    from{
        transform: translateY(0)
    }
    50%{
        transform: translateY(-5px)
    }
    to{
        transform: translateY(0)
    }
`

export const buoyActiveAnimation = css`
	${buoyActive} 3s infinite
`

export const sBuoyActiveAnimation = css`
    ${sBuoyActive} 4s infinite
`

export const fishActive1 = keyframes`
    from{
        transform: translateY(0)
    }
    50%{
        transform: translateY(-5px)
    }
    to{
        transform: translateY(0)
    }
`

export const fishActive2 = keyframes`
    from{
        transform: translateY(0)
    }
    50%{
        transform: translateY(5px)
    }
    to{
        transform: translateY(0)
    }
`


export const fishActiveAnimation1 = css`
     ${fishActive1} 3s infinite
`


export const fishActiveAnimation2 = css`
     ${fishActive2} 4s infinite
`

export const droneActive = keyframes`
    from{
        transform: translateY(0)
    }
    50%{
        transform: translateY(12px)
    }
    to{
        transform: translateY(0)
    }
`
export const droneActiveAnimation = css`
     ${droneActive} 7s infinite
`

export const buoyObjRotate = keyframes`
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(-360deg);
    }
`

export const buoyObjRotateAnimation = css`
     ${buoyObjRotate} 6s linear infinite
`


