import { selector } from 'recoil'
import { navState } from './atoms'

export const navGetState = selector({
    key: 'navGetState',
    get: ({ get }) => {
        const page = get(navState)

        return page
    }
})