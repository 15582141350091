import styled from "styled-components";
import { viewSize } from "../../../config";

export const Container = styled.div`
	width: 100%;
	position: relative;
	background-color: white;
	margin-top: ${(props) => props.marginTop && props.marginTop.desktop};
	@media screen and (max-width: ${viewSize.tablet}) {
		margin-top: ${(props) => props.marginTop && props.marginTop.tablet};
	}
	@media screen and (max-width: ${viewSize.mobile}) {
		margin-top: ${(props) => props.marginTop && props.marginTop.mobile};
	}
`;

export const Wave = styled.img`
	width: 100%;
	position: absolute;
`;
