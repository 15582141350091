import {
  colorPallete,
  desktopFontSize,
  fontWeight,
  mobileFontSize,
} from "../../config";
import {
  Blog,
  Company,
  Facebook,
  GooglePlay,
  Instagram,
  Product,
  YouTube,
  leafletOneImg,
  leafletThreeImg,
  leafletTwoImg,
  odnIcon,
  slideOneImg,
  slideTwoImg,
} from "../../config/images";
import { breadcrumbsProps } from "../common";

const textProps = {
  description: {
    fontSize: { desktop: desktopFontSize.lg, mobile: mobileFontSize.base },
    fontWeight: fontWeight.bold,
    color: colorPallete.black,
  },
};

export const breadcrumbProps = {
  current: {
    ...breadcrumbsProps.title,
    children: "주식회사 오든",
  },
  lines: [
    { bgColor: "#8DC63F" },
    { bgColor: "#FFCB05" },
    { bgColor: "#ED1C24" },
    { bgColor: "#00AEEF" },
  ],
  breadcrumbs: [
    { children: "HOME", ...breadcrumbsProps.breadcrumb },
    {
      ...breadcrumbsProps.breadcrumb,
      children: "SNS",
      color: colorPallete.head,
    },
  ],
};

export const siteMapProps = {
  productImages: [slideOneImg, slideTwoImg],
  companyImages: [leafletOneImg, leafletTwoImg, leafletThreeImg],
  iconsProps: {
    textStyle: {
      ...textProps.description,
    },
    items: [
      {
        src: odnIcon,
        onClick: () => window.open("https://odn.us"),
        children: "웹 사이트",
      },
      {
        src: Blog,
        onClick: () => window.open("https://blog.naver.com/odnus"),
        children: "네이버 블로그",
      },
      {
        src: Facebook,
        onClick: () => window.open("https://www.facebook.com/odncorp"),
        children: "Facebook",
      },
      {
        src: Instagram,
        onClick: () => window.open("https://www.instagram.com/odn_promo"),
        children: "Instagram",
      },
      {
        src: YouTube,
        onClick: () => window.open("https://www.youtube.com/@odncorp"),
        children: "YouTube",
      },
      {
        src: GooglePlay,
        onClick: () =>
          window.open("https://play.google.com/store/apps/developer?id=ODN"),
        children: "Google Play",
      },
      {
        src: Company,
        onClick: () => console.log("click"),
        children: "회사소개서",
      },
      {
        src: Product,
        onClick: () => console.log("click"),
        children: "제품소개서",
      },
    ],
  },
};

export const iconProps = {};
