import React from "react";
import * as S from "./style";
import { Text } from "../../atom";

const SubTitle = ({ childrens, text }) => {
	return childrens.map((children, index, array) => (
		<Text key={index} children={children} {...text} />
	));
};

const Device = ({
	title,
	subDesktopTitles = [],
	subMobileTitles = [],
	descriptions = [],
	descriptionsTitle,
}) => {
	return (
		<S.Container>
			<S.TtitleWrapper>
				<Text {...title} />
			</S.TtitleWrapper>
			<S.SubTitleWrapper>
				{subDesktopTitles.map((subTitle, index, array) => (
					<S.SubTitle key={index}>
						<SubTitle {...subTitle} />
					</S.SubTitle>
				))}
			</S.SubTitleWrapper>
			<S.SubTitleMobileWrapper>
				{subMobileTitles.map((subTitle, index, array) => (
					<S.SubTitle key={index}>
						<SubTitle {...subTitle} />
					</S.SubTitle>
				))}
			</S.SubTitleMobileWrapper>
			<S.DescriptionWrapper {...descriptionsTitle}>
				{descriptions.map((description, index, array) => (
					<S.Descirption key={index} {...descriptionsTitle}>
						{description.title && (
							<S.ContentWrapper>
								<Text {...description.title} />
							</S.ContentWrapper>
						)}
						<S.SubContentWrapper>
							<Text {...description.subTitle} />
						</S.SubContentWrapper>
					</S.Descirption>
				))}
			</S.DescriptionWrapper>
		</S.Container>
	);
};

export default Device;
