import React from "react";
import { Text } from "../../atom";
import * as S from "./style";

const GenerateBg = () => {
	return (
		<S.BgWrapper>
			<S.TopBg />
			<S.MiddleBg />
			<S.BottomBg />
		</S.BgWrapper>
	);
};


const ImgText = ({ bottom, image = [] }) => {
	return (
		<S.Container>
			<S.Wrapper>
				<GenerateBg />
				<S.Img {...image} />
			</S.Wrapper>
			{bottom && (
				<S.TitleWrapper>
					<Text {...bottom} />
				</S.TitleWrapper>
			)}
		</S.Container>
	);
};

export default ImgText;
