import { keyframes, css } from "styled-components";

export const firstBubble = keyframes`
	0% {
		opacity: 0;
		transform: translateY(20px)
	}
	10% {
		opacity: 1;
		transform: none;
	}
	65% {
		opacity: 1;
		transform: translateY(-100px);}
	75%{
		opacity: 0;
		transform: none;
	}
	100%{
		opacity: 0;
	}
`;

export const firstBubbleAnimation = css`
	${firstBubble} 12s infinite
`

export const secondBubble = keyframes`
	0% {
		opacity: 0;
		transform: translateY(500px)
	}
	10% {
		opacity: 1;
		transform: none;
		transform: translateY(200px)
	}
	65% {
		opacity: 1;
		transform: translateY(100px);
	}
	75%{
		opacity: 0;
		transform: translateY(300px);
	}
	100%{
		opacity: 0;
		transform: translateY(500px);
	}
`;
export const secondBubbleAnimation = css`
	${secondBubble} 12s infinite
`