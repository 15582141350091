import styled from "styled-components";

export const Image = styled.img`
    position : absolute;
    top : ${props => props.top && props.top};   
    left : ${props => props.left && props.left};   
    right : ${props => props.right && props.right};  
    width : ${props => props.width && props.width};   
    bottom : ${props => props.bottom && props.bottom};
    z-index : ${props => props.zIndex && props.zIndex};
    animation: ${props => props.animation && props.animation}; 
    ${props => props.hover && `&:hover{
        ${props.hover}
    }`}; 
`

export const Container = styled.div`
    position : absolute;
    height : ${props => props.height && props.height};
    top : ${props => props.top && props.top};   
    left : ${props => props.left && props.left};   
    right : ${props => props.right && props.right};  
    width : ${props => props.width && props.width};   
    bottom : ${props => props.bottom && props.bottom};
    z-index : ${props => props.zIndex && props.zIndex};
    animation: ${props => props.animation && props.animation}; 
    ${props => props.hover && `&:hover{
        ${props.hover}
    }`}; 
`

export const HeightImage = styled.img`
    width: auto;
    height : fit-content;
`