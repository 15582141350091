import styled from "styled-components";
import { defaultSize, viewSize } from "../../../config";

export const Container = styled.a`
  display: flex;
  flex-direction: column;
  align-items: start;
  width: ${(props) =>
    props.width ? props.width.desktop : defaultSize.desktop.cardWidth};
  text-decoration: none;
  @media screen and (max-width: ${viewSize.mobile}) {
    width: ${(props) =>
      props.width ? props.width.mobile : defaultSize.mobile.cardWidth};
  }
`;

export const Img = styled.img`
  width: 100%;
`;

export const TitleWrapper = styled.div`
  margin: 0.375rem 0px;
  height: 3.75rem;

  @media screen and (max-width: ${viewSize.mobile}) {
    margin: 0.25rem 0px;
  }
`;

export const DateWrapper = styled.div``;
