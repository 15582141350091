import styled from "styled-components";
import { viewSize } from "../../../config";

export const Container = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	min-height: 100vh;
	margin-top : 5rem;
`;

export const Wrapper = styled.div`
	max-width: 3450px;
	width: 65%;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	@media screen and (max-width: ${viewSize.mobile}) {
		width: 100%;
	}
`;

export const TitleWrapper = styled.div``;

export const RadioWrapper = styled.div`
	display: flex;
	margin: auto;
	align-items: center;
	justify-content: center;
	> div {
		margin: 1rem;
	}
`;

export const ImgCardWrapper = styled.div`
	margin: 2rem auto;
	display: flex;
	flex-direction: column;
	flex-wrap: wrap;
	align-items: center;
	justify-content: center;
	width: 100%;
	> div {
		margin-top: 1rem;
		margin-bottom: 1rem;
	}
	/* 	> div:nth-child(2) {
		animation-duration: reverse;
	} */

	@media screen and (max-width: ${viewSize.mobile}) {
		> div {
			margin-top: 0.5rem;
			margin-bottom: 0.5rem;
		}
	}
`;
