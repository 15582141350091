import styled from "styled-components";
import { colorPallete, defaultSize, viewSize } from '../../../config'


export const Container = styled.div`
    width : 100%;
    position : fixed;
    top : ${props => props.hide ? '-5em' : '0'};
    display : flex;
    flex-direction : column;
    height : ${props => props.open ? '100vh' : '5em'};
    z-index : 99999;
    overflow : scroll;
    background-color : ${props => props.open || !props.isTop ? 'white' : 'transparent'}; 
    
    transition: 0.5s ease;
    &::-webkit-scrollbar {
        display: none;
    }
    @media screen and (max-width : ${viewSize.mobile}){
        height : ${props => props.open ? '100vh' : '4em'};
    }
`

export const NavWrapper = styled.div`
    position: relative ;
    width : 98%;
    max-width : ${defaultSize.desktop.container};
    display : flex;
    align-items : center;
    margin : 0.8rem auto;
    z-index: 2;
    /* background-color : ${props => props.open ? 'black' : 'transparent'}; */
    @media screen and (max-width : ${viewSize.mobile}){
        max-width : ${defaultSize.mobile.container};
        margin : 0.7rem auto;
    }        
`

export const LogoWrapper = styled.img`
    cursor : pointer;
    
    @media screen and (max-width : ${viewSize.mobile}){
        width : 5.375rem;
        height : 2rem;
    }  
`

export const MenuWrapper = styled.div`
    margin : auto 0 auto auto;
    max-width : 21.5rem;
    width : 50%;
    display : flex;
    justify-content : space-between;
    @media screen and (max-width : ${viewSize.mobile}){
        display : none;
    }     
`

export const Icon = styled.div`
    margin : ${props => props.open ? 'auto 0 auto auto' : 'auto 0 auto 2rem'};
    cursor : pointer;
    .line--1,
    .line--3 {
        --total-length: 126.64183044433594;
    }
    .line--2 {
        --total-length: 70;
    }
    label {
        display: block;
        cursor: pointer;
        position: absolute;
        width: 8em;
        max-width: 150px;
        max-height: 150px;
        top: -2.7em;
        right: -2.2em;
        width: 6.2em;
        @media screen and (max-width : ${viewSize.mobile}){
            top: -3.3em;
        }  
        input:checked+svg {
            .line--1,
            .line--3 {
            --offset: -94.1149185097;
            --length: 22.627416998;
            }
            .line--2 {
            --offset: -50;
            --length: 0;
            }
        }
        path{
            fill: none;
            stroke: black;
            stroke-width: 3;
            stroke-linecap: round;
            stroke-linejoin: round;
            --length: 24;
            --offset: -38;
            stroke-dasharray: var(--length) var(--total-length);
            stroke-dashoffset: var(--offset);
            transition: all .8s cubic-bezier(.645, .045, .355, 1);
        }
    }
    @media screen and (max-width : ${viewSize.mobile}){
        margin : auto 0 auto auto;
    }  
`

export const DetailMenuWrapper = styled.div`
    display : flex;
    /* display : ${props => props.open ? 'flex' : 'none'}; */
    flex-direction : column;
    margin : 0 auto;
    max-width : 68.25rem;
    height : ${props => props.open ? '0%' : '100%'};
    justify-content : center;
    z-index : 1;
    @media screen and (max-width : ${viewSize.mobile}){
        margin : 2rem auto;
    }  
`

export const DetailMenuMove = styled.div`
    position: relative;
    width: 100%;
    height: 100%;
    top: ${props => props.open ? "0em" : "-100vh;"};
    width: 100%;
    z-index: 1;
    transition: 0.5s ease;
`;

export const DetailMenu = styled.div`
    display : flex;
    margin : 2rem 0;
    flex-wrap : wrap;
`

export const MenuTitle = styled.div`
    width : 30%;
    text-align : left;

    @media screen and (max-width : ${viewSize.mobile}){
        width: 100%;
        text-align : center;
    }      
`

export const MenuItemWrapper = styled.div`
    display : flex;
    width : 65%;
    align-items : center;

    @media screen and (max-width : ${viewSize.mobile}){
        width: 100%;
        flex-wrap : wrap;
        justify-content : space-between;
    }  
`

export const MenuItem = styled.div`
    border-right : 1px solid ${colorPallete.cancel};
    width : 20%;
    height : fit-content;
    display : flex;
    align-items : center;
    justify-content : center;

    &:last-child {
        border-right : none;
    }

    @media screen and (max-width : ${viewSize.mobile}){
        width: 50%;
        margin : 1.5rem 0;
        border-right : none;
    }  
`

export const FooterWrapper = styled.div`
    display : flex;
    align-items : end;
    margin-top : 8rem;

    @media screen and (max-width : ${viewSize.mobile}){
        flex-wrap : wrap-reverse;
        margin-top : 3rem;
    }  
`

export const InfoWrapper = styled.div`
    display : flex;
    flex-direction : column;

    @media screen and (max-width : ${viewSize.mobile}){
        align-items : center;
        margin : auto;
    }  
`

export const ButtonWrapper = styled.div`
    margin : auto 0 auto auto;
    display : flex;
    
    @media screen and (max-width : ${viewSize.mobile}){
        margin : auto auto 2rem auto;
        flex-wrap : wrap;
        justify-content : space-around;
        width : 90%;
    }  
`

export const Button = styled.div`
    width : 11.125rem;
    margin-right : 1.25rem;

    &:last-child {
        margin-right : 0;
    }

    @media screen and (max-width : ${viewSize.mobile}){
        width : 9.375rem;
        margin : 0.25rem 0 ;
    }  
`

export const LinkWrapper = styled.div`
    display : flex;
    margin-bottom :0.5rem;
`



export const TextWrapper = styled.div`
    display : flex;
    flex-wrap : wrap;

    @media screen and (max-width : ${viewSize.mobile}){
        flex-wrap : wrap;
        justify-content : center;
    } 
    
`

export const Text = styled.div`
    margin : ${props => props.margin ? props.margin : 'auto 1rem auto 0'};

    > span {
        margin : auto 0.25rem auto 0;
    }

    @media screen and (max-width : ${viewSize.mobile}){
        margin : 0.5rem;
        margin : ${props => props.margin ? props.margin : '0.5rem'};
    }
`

export const Fake = styled.div`
    margin-top: 5.75rem;
`
