export const cloneObject = (obj) => {
    var clone = {};
    for (var key in obj) {
        if (typeof obj[key] == "object" && obj[key] != null) {
            clone[key] = cloneObject(obj[key]);
        } else {
            clone[key] = obj[key];
        }
    }
    return clone;
}

export const setInputForm = (e, prevState, setFunc) => {
    setFunc({
        ...prevState,
        [e.target.name]: e.target.value
    })
}

export const setRadioForm = (e, prevState, setFunc) => {
    setFunc({
        ...prevState,
        [e.target.name]: e.target.value
    })
}