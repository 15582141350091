import styled from 'styled-components'
import { firstBubbleAnimation, secondBubbleAnimation } from '../../../animation/bubbles'
import { viewSize } from '../../../config'

export const Container = styled.div`
    display : flex;
    flex-direction : column;
    align-items : center;
    justify-content : center;
    background: linear-gradient(rgb(168, 219, 254), rgb(214, 238, 254));
    margin-top : 10rem;
    padding-bottom : 10rem;
`

export const TitleWrapper = styled.div`
    margin : 1rem auto;
    z-index: 1;

    @media screen and (max-width : ${viewSize.tablet}){
        margin : 0.5rem auto;
    }
`

export const ImgWrapper = styled.div`
    max-width : 896px;
    display : flex;
    width : 100%;
    align-items : center;
    justify-content : center;
    margin : 1rem auto;

    @media screen and (max-width : ${viewSize.tablet}){
        flex-direction : column;
        margin : 0rem auto;
    }
    
`

export const ColImgs = styled.div`
    display : flex;
    flex-direction : column;
    justify-content : space-between;
    width : 100%;
    @media screen and (max-width : ${viewSize.tablet}){
        display : none;
    }
`

export const MainImg = styled.div`
    display : flex;
    align-items : center;
    justify-content : center;
    margin : 2rem 0 0 0;
`


export const RowImgs = styled.div`
    display : none;
    align-items : center;
    justify-content : space-between;
    width : 100%;
    margin : 2rem auto;


    @media screen and (max-width : ${viewSize.tablet}){
        display : flex;
        margin : 0 auto;

    }
`

export const Image = styled.div`
    width : 100%;
    display : flex;
    align-items : center;
    justify-content : center;
    min-height: 20rem;
    @media screen and (max-width : ${viewSize.tablet}){
        margin : 0.5rem auto;
        min-height: 15rem;
        > img {
            width : 70%;
        }
    }
    
    @media screen and (max-width : ${viewSize.mobile}){
        width : 70%;
        min-height: 8rem;
    }
`


export const Bubble = styled.img`
    position : absolute;
    width :100%;
    animation : ${firstBubbleAnimation};

    @media screen and (max-width : ${viewSize.mobile}){
        display : none;
    }
`

export const MobileBubble = styled.img`
    position : absolute;
    display : none;
    width :100%;
    animation : ${secondBubbleAnimation};

    @media screen and (max-width : ${viewSize.mobile}){
        display : flex;
    }
`