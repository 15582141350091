import React from "react";
import styled from "styled-components";
import { Wave } from "../../components/atom";
import { Breadcrumb, SiteMapSection } from "../../components/template";
import { waveProps } from "../project/data";
import { breadcrumbProps, siteMapProps } from "./sns_data";

const Container = styled.div`
  height: 100%;
`;

const SNS = () => {
  return (
    <Container>
      <Breadcrumb {...breadcrumbProps} />
      <Wave {...waveProps} />
      <SiteMapSection {...siteMapProps} />
    </Container>
  );
};

export default SNS;
