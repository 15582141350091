import React from "react";
import { LinkImg, Text } from "../../atom";
import * as S from "./style";

const MovingCard = ({
	color,
	fontSize,
	fontWeight,
	children,
	items = [],
	bgColor,
	width,
	height,
	animation,
}) => {
	const imgCardProps = {
		bgColor,
		width,
		height,
	};
	const animationProps = {
		animation,
	};
	const subCategoryProps = {
		color,
		fontSize,
		fontWeight,
		children,
	};

	return (
		<S.Container {...imgCardProps}>
			<Text {...subCategoryProps} />
			<S.Images {...animationProps}>
				{items.map((imgCard, index, array) => (
					<LinkImg key={index} {...imgCard} />
				))}
			</S.Images>
		</S.Container>
	);
};

export default MovingCard;
