import React from "react";
import GoogleMapReact from 'google-map-react'
import * as S from './style'
import { cameraIcon, locationIcon, sensorIcon, buoyIcon } from "../../../config/images";
import { GOOGLE_API_KEY } from '../../../config'

const Map = ({ location = { lat: 34.709597, lng: 126.905018 }, zoom = 9, items = [] }) => {
    const defaultProps = {
        center: location,
        zoom
    };

    return (
        <S.Container>
            <GoogleMapReact
                bootstrapURLKeys={{ key: GOOGLE_API_KEY }}
                defaultCenter={defaultProps.center}
                defaultZoom={defaultProps.zoom}
            >
                {items.map(item => (
                    <S.IconWrapper {...item}>
                        <S.RowWrapper>
                            {item.buoy && (<S.Icon src={buoyIcon} />)}
                            {item.camera && (<S.Icon src={cameraIcon} />)}
                            {item.sensor && (<S.Icon src={sensorIcon} />)}
                            <S.Icon />
                            <S.Icon />
                        </S.RowWrapper>
                        <S.RowWrapper>
                            <S.Icon src={locationIcon} />
                        </S.RowWrapper>
                    </S.IconWrapper>
                ))}
            </GoogleMapReact>
        </S.Container>

    )
}

export default Map