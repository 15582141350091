import React from 'react'
import * as S from './style'


const TextArea = ({ value, onChange, color, border, padding, height, type, name }) => {
    const inputProps = {
        value, onChange, border, padding, height, name
    }

    return (
        <S.TextArea {...inputProps} />
    )
}

export default TextArea