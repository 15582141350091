import React from "react";
import * as S from "./style";
import { SectionTitle, ImgCard, MovingCard } from "../../organism";
import { Radio } from "../../atom";
import { useState } from "react";

const PartnerSection = React.forwardRef(
	({ sectionTitle, radios = [], imgCards = [], value }, ref) => {
		const [add, setAdd] = useState(true);
		let selectedImgCards = [];
		let categorys;
		imgCards.forEach((cards, index, array) => {
			if (cards.category === "investor") {
				selectedImgCards = cards.items;
			} else {
				categorys = cards.items;
				if (add) {
					GenerateItems(categorys);
					setAdd(false);
				}
			}
		});
		function GenerateItems(categorys) {
			categorys.forEach((category) => {
				const categoryItem = category.items;
				for (let i = 0; i < 2; i++) {
					categoryItem.forEach((item) => category.items.push(item));
				}
			});
		}
		return (
			<S.Container ref={ref}>
				<S.TitleWrapper>
					<SectionTitle {...sectionTitle} />
				</S.TitleWrapper>
				<S.Wrapper>
					<S.RadioWrapper>
						{radios.map((radio, index, array) => {
							return <Radio key={index} {...radio} />
						})}
					</S.RadioWrapper>
					<S.ImgCardWrapper>
						{value === "investor"
							? selectedImgCards.map((imgCard, index, array) => {
								return <ImgCard key={index} {...imgCard} />
							})
							: categorys.map((imgCards, index, array) => {
								return <MovingCard key={index} {...imgCards} />
							})}
					</S.ImgCardWrapper>
				</S.Wrapper>
			</S.Container>
		);
	},
);

export default PartnerSection;
