import {
	desktopMessageImg,
	shadowImg,
	buoyImg,
	centerImg,
	rBuoyImg,
	cameraImg,
	droneImg,
	netImg,
	lFishImg,
	rFishImg,
	mobileBuoyImg,
	mobileMonitorImg,
	mobileNetImg,
	monitorImg,
	cloudImg,
	mobileCenter,
	mobileMonitor,
} from "../../config/images";
import {
	buoyActiveAnimation,
	sBuoyActiveAnimation,
	fishActiveAnimation1,
	fishActiveAnimation2,
	droneActiveAnimation,
} from "../../animation/business";
import { sectionProps } from "../common";

const props = {
	sectionTitle: {
		title: {
			...sectionProps.title,
			children: "BUSINESS",
		},
		description: {
			...sectionProps.description,
			children: "오든이 만들어갈 스마트 아쿠아팜 서비스입니다.",
		},
	},
	main: {
		desktop: {
			src: monitorImg,
			width: "80%",
			maxWidth: "1176px",
			margin: "0 0 0 7% ",
		},
		mobile: {
			src: mobileMonitorImg,
			width: "70%",
			maxWidth: "400px",
			margin: "0 0 0 0%",
		},
	},
	desktopImages: [
		{ src: desktopMessageImg, width: "98px", left: "-4%", top: "5%" },
		{ src: monitorImg, width: "1176px", top: "-50%", margin: "0 0 0 7% " },
		{ src: shadowImg, width: "8.15%", left: "-3%", top: "35%" },
		{ src: shadowImg, width: "6%", right: "-3%", top: "33%" },
		{ src: shadowImg, width: "20%", right: "5.5%", top: "33%" },
		{
			src: buoyImg,
			width: "125px",
			left: "-4%",
			top: "20%",
			zIndex: 999,
			animation: buoyActiveAnimation,
			hover: "cursor: pointer;",
			link: true,
		},
		{ src: centerImg, width: "767px", left: "7.5%", top: "-7%", zIndex: 1 },
		{
			src: rBuoyImg,
			width: "89px",
			right: "-4%",
			top: "20%",
			animation: sBuoyActiveAnimation,
		},
		{ src: cameraImg, width: "219px", right: "5.25%", top: "3%" },
		{
			src: droneImg,
			width: "307px",
			right: "10.5%",
			top: "47%",
			animation: droneActiveAnimation,
		},
		{ src: netImg, width: "660px", left: "12.8%", top: "29%", zIndex: 1 },
		{
			src: lFishImg,
			width: "6%",
			left: "30%",
			top: "55%",
			animation: fishActiveAnimation1,
		},
		{
			src: lFishImg,
			width: "3%",
			left: "20%",
			top: "55%",
			animation: fishActiveAnimation1,
		},
		{
			src: lFishImg,
			width: "4%",
			left: "60%",
			top: "65%",
			animation: fishActiveAnimation1,
		},
		{
			src: lFishImg,
			width: "2%",
			left: "50%",
			top: "65%",
			animation: fishActiveAnimation1,
		},
		{
			src: lFishImg,
			width: "3%",
			left: "55%",
			top: "60%",
			animation: fishActiveAnimation1,
		},
		{
			src: lFishImg,
			width: "3%",
			left: "30%",
			top: "65%",
			animation: fishActiveAnimation2,
		},
		{
			src: lFishImg,
			width: "3%",
			left: "54%",
			top: "69%",
			animation: fishActiveAnimation1,
		},
		{
			src: lFishImg,
			width: "3%",
			left: "20%",
			top: "79%",
			animation: fishActiveAnimation2,
		},
		{
			src: rFishImg,
			width: "6%",
			right: "65.5%",
			top: "75%",
			animation: fishActiveAnimation2,
		},
		{
			src: rFishImg,
			width: "4%",
			right: "35.5%",
			top: "75%",
			animation: fishActiveAnimation2,
		},
		{
			src: rFishImg,
			width: "3%",
			right: "40.5%",
			top: "40%",
			animation: fishActiveAnimation2,
		},
		{
			src: rFishImg,
			width: "3%",
			right: "35.5%",
			top: "45%",
			animation: fishActiveAnimation2,
		},
		{
			src: rFishImg,
			width: "2%",
			right: "44.5%",
			top: "45%",
			animation: fishActiveAnimation1,
		},
		{
			src: rFishImg,
			width: "5%",
			right: "80.5%",
			top: "39%",
			animation: fishActiveAnimation1,
		},
		{
			src: rFishImg,
			width: "5%",
			right: "72.5%",
			top: "35%",
			animation: fishActiveAnimation2,
		},
		{
			src: rFishImg,
			width: "5%",
			right: "72.5%",
			top: "65%",
			animation: fishActiveAnimation1,
		},
		{
			src: rFishImg,
			width: "5%",
			right: "35.5%",
			top: "50%",
			animation: fishActiveAnimation2,
		},
		{
			src: cloudImg,
			width: "100%",
			right: "0%",
			top: "-70%",
			animation: fishActiveAnimation1,
		},
	],
	tabletImages: [
		{ src: desktopMessageImg, width: "68px", left: "-4%", top: "14%" },
		{ src: monitorImg, width: "800px", top: "-25%", margin: "0 0 0 7% " },
		{ src: shadowImg, width: "8.15%", left: "-3%", top: "35%" },
		{ src: shadowImg, width: "6%", right: "-3%", top: "33%" },
		{ src: shadowImg, width: "20%", right: "5.5%", top: "33%" },
		{
			src: buoyImg,
			width: "87px",
			left: "-4%",
			top: "24%",
			zIndex: 999,
			animation: buoyActiveAnimation,
			hover: "cursor: pointer;",
			link: true,
		},
		{ src: centerImg, width: "536px", left: "7.5%", top: "3%", zIndex: 1 },
		{
			src: rBuoyImg,
			width: "62px",
			right: "-4%",
			top: "23%",
			animation: sBuoyActiveAnimation,
		},
		{ src: cameraImg, width: "153px", right: "5.25%", top: "12%" },
		{
			src: droneImg,
			width: "214px",
			right: "10.5%",
			top: "40%",
			animation: droneActiveAnimation,
		},
		{ src: netImg, width: "462px", left: "12.8%", top: "29%", zIndex: 1 },
		{
			src: lFishImg,
			width: "5%",
			left: "30%",
			top: "50%",
			animation: fishActiveAnimation1,
		},
		{
			src: lFishImg,
			width: "2%",
			left: "20%",
			top: "50%",
			animation: fishActiveAnimation1,
		},
		{
			src: lFishImg,
			width: "3%",
			left: "60%",
			top: "60%",
			animation: fishActiveAnimation1,
		},
		{
			src: lFishImg,
			width: "1%",
			left: "50%",
			top: "60%",
			animation: fishActiveAnimation1,
		},
		{
			src: lFishImg,
			width: "2%",
			left: "55%",
			top: "55%",
			animation: fishActiveAnimation1,
		},
		{
			src: lFishImg,
			width: "2%",
			left: "30%",
			top: "60%",
			animation: fishActiveAnimation2,
		},
		{
			src: lFishImg,
			width: "2%",
			left: "54%",
			top: "64%",
			animation: fishActiveAnimation1,
		},
		{
			src: lFishImg,
			width: "2%",
			left: "20%",
			top: "68%",
			animation: fishActiveAnimation2,
		},
		{
			src: rFishImg,
			width: "5%",
			right: "65.5%",
			top: "65%",
			animation: fishActiveAnimation2,
		},
		{
			src: rFishImg,
			width: "3%",
			right: "35.5%",
			top: "65%",
			animation: fishActiveAnimation2,
		},
		{
			src: rFishImg,
			width: "2%",
			right: "40.5%",
			top: "35%",
			animation: fishActiveAnimation2,
		},
		{
			src: rFishImg,
			width: "2%",
			right: "35.5%",
			top: "40%",
			animation: fishActiveAnimation2,
		},
		{
			src: rFishImg,
			width: "1%",
			right: "44.5%",
			top: "40%",
			animation: fishActiveAnimation1,
		},
		{
			src: rFishImg,
			width: "4%",
			right: "80.5%",
			top: "34%",
			animation: fishActiveAnimation1,
		},
		{
			src: rFishImg,
			width: "4%",
			right: "72.5%",
			top: "30%",
			animation: fishActiveAnimation2,
		},
		{
			src: rFishImg,
			width: "4%",
			right: "72.5%",
			top: "60%",
			animation: fishActiveAnimation1,
		},
		{
			src: rFishImg,
			width: "4%",
			right: "35.5%",
			top: "45%",
			animation: fishActiveAnimation2,
		},
		{
			src: cloudImg,
			width: "100%",
			right: "0%",
			top: "-70%",
			animation: fishActiveAnimation1,
		},
	],
	mobileImages: [
		{ src: desktopMessageImg, width: "49px", left: "-4%", top: "17%" },
		{ src: monitorImg, width: "588px", top: "-10%", margin: "0 0 0 7% " },
		{ src: shadowImg, width: "8.15%", left: "-3%", top: "34%" },
		{ src: shadowImg, width: "6%", right: "-1%", top: "31%" },
		{ src: shadowImg, width: "20%", right: "7.5%", top: "33%" },
		{
			src: buoyImg,
			width: "62px",
			left: "-4%",
			top: "26%",
			zIndex: 999,
			animation: buoyActiveAnimation,
			hover: "cursor: pointer;",
			link: true,
		},
		{ src: centerImg, width: "383px", left: "7.5%", top: "11%", zIndex: 1 },
		{
			src: rBuoyImg,
			width: "44px",
			right: "-2%",
			top: "25%",
			animation: sBuoyActiveAnimation,
		},
		{ src: cameraImg, width: "109px", right: "7.25%", top: "18%" },
		{
			src: droneImg,
			width: "183px",
			right: "10.5%",
			top: "39%",
			animation: droneActiveAnimation,
		},
		{ src: netImg, width: "330px", left: "12.8%", top: "29%", zIndex: 1 },
		{
			src: lFishImg,
			width: "5%",
			left: "30%",
			top: "50%",
			animation: fishActiveAnimation1,
		},
		{
			src: lFishImg,
			width: "2%",
			left: "20%",
			top: "50%",
			animation: fishActiveAnimation1,
		},
		{
			src: lFishImg,
			width: "3%",
			left: "60%",
			top: "55%",
			animation: fishActiveAnimation1,
		},
		{
			src: lFishImg,
			width: "1%",
			left: "50%",
			top: "55%",
			animation: fishActiveAnimation1,
		},
		{
			src: lFishImg,
			width: "2%",
			left: "55%",
			top: "55%",
			animation: fishActiveAnimation1,
		},
		{
			src: lFishImg,
			width: "2%",
			left: "30%",
			top: "57%",
			animation: fishActiveAnimation2,
		},
		{
			src: lFishImg,
			width: "2%",
			left: "54%",
			top: "50%",
			animation: fishActiveAnimation1,
		},
		{
			src: lFishImg,
			width: "2%",
			left: "20%",
			top: "45%",
			animation: fishActiveAnimation2,
		},
		{
			src: rFishImg,
			width: "5%",
			right: "65.5%",
			top: "43%",
			animation: fishActiveAnimation2,
		},
		{
			src: rFishImg,
			width: "3%",
			right: "35.5%",
			top: "50%",
			animation: fishActiveAnimation2,
		},
		{
			src: rFishImg,
			width: "2%",
			right: "40.5%",
			top: "35%",
			animation: fishActiveAnimation2,
		},
		{
			src: rFishImg,
			width: "2%",
			right: "35.5%",
			top: "40%",
			animation: fishActiveAnimation2,
		},
		{
			src: rFishImg,
			width: "1%",
			right: "44.5%",
			top: "40%",
			animation: fishActiveAnimation1,
		},
		{
			src: rFishImg,
			width: "4%",
			right: "80.5%",
			top: "34%",
			animation: fishActiveAnimation1,
		},
		{
			src: rFishImg,
			width: "4%",
			right: "72.5%",
			top: "30%",
			animation: fishActiveAnimation2,
		},
		{
			src: rFishImg,
			width: "4%",
			right: "72.5%",
			top: "55%",
			animation: fishActiveAnimation1,
		},
		{
			src: rFishImg,
			width: "4%",
			right: "35.5%",
			top: "45%",
			animation: fishActiveAnimation2,
		},
		{
			src: cloudImg,
			width: "100%",
			right: "0%",
			top: "-70%",
			animation: fishActiveAnimation1,
		},
	],
	smallMobileImages: [
		{ src: desktopMessageImg, width: "80", left: "5%", top: "-3%" },
		{
			src: mobileMonitor,
			width: "222px",
			top: "-50%",
			left: "15%",
			margin: "0 0 0 7% ",
		},
		{ src: shadowImg, width: "25.15%", left: "2.5%", top: "17%", zIndex: 2 },
		{ src: mobileCenter, width: "242px", right: "-1%", top: "5%", zIndex: 4 },
		{
			src: mobileBuoyImg,
			width: "76px",
			left: "6%",
			top: "10%",
			zIndex: 2,
			animation: buoyActiveAnimation,
			link: true,
		},
		{ src: mobileNetImg, width: "198px", right: "6.2%", top: "12%", zIndex: 3 },
		{
			src: lFishImg,
			width: "15%",
			left: "45%",
			top: "10%",
			zIndex: 2,
			animation: fishActiveAnimation1,
		},
		{
			src: lFishImg,
			width: "10%",
			left: "57%",
			top: "15%",
			zIndex: 2,
			animation: fishActiveAnimation2,
		},
		{
			src: lFishImg,
			width: "14%",
			left: "65%",
			top: "35%",
			zIndex: 2,
			animation: fishActiveAnimation1,
		},
		{
			src: lFishImg,
			width: "10%",
			left: "55%",
			top: "38%",
			zIndex: 2,
			animation: fishActiveAnimation2,
		},
		{
			src: rFishImg,
			width: "12%",
			right: "18.5%",
			top: "10%",
			zIndex: 2,
			animation: fishActiveAnimation1,
		},
		{
			src: rFishImg,
			width: "19%",
			right: "38.5%",
			top: "20%",
			zIndex: 2,
			animation: fishActiveAnimation2,
		},
		{
			src: rFishImg,
			width: "11%",
			right: "13.5%",
			top: "20%",
			zIndex: 2,
			animation: fishActiveAnimation2,
		},
		{
			src: rFishImg,
			width: "11%",
			right: "30.5%",
			top: "30%",
			zIndex: 2,
			animation: fishActiveAnimation1,
		},
		{
			src: rFishImg,
			width: "11%",
			right: "13.5%",
			top: "15%",
			zIndex: 2,
			animation: fishActiveAnimation2,
		},
	],
};

export default props;
