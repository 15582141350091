import React, { useState } from 'react'
import * as S from './style'
import { colorPallete } from '../../../config'
import { Icon as DefaultIcon } from '@iconify/react'

const CheckBox = ({ Icon, color = colorPallete.white, bgColor = colorPallete.primary, value, onChange, height, width }) => {
    const [focus, setFocus] = useState(false)

    return (
        <S.Container height={height} width={width} bgColor={value ? bgColor : colorPallete.white} onClick={onChange} border={focus || value ? `1px solid ${colorPallete.primary}` : ''}>
            <S.HiidenCheckBox type='checkbox' onChange={onChange} checked={value} onFocus={() => setFocus(true)} onBlur={() => setFocus(false)} />
            <S.CheckBox>
                {Icon ? (
                    <Icon stroke={value ? color : colorPallete.cancel} />
                ) : (
                    <DefaultIcon icon="bi:check" fontSize='1.5rem' />
                )}
            </S.CheckBox>
        </S.Container>
    )
}

export default CheckBox