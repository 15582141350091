import styled from "styled-components";
import { viewSize } from "../../../config";

export const Img = styled.img`
	max-width: ${(props) => (props.imageWidth ? props.imageWidth : "140px")};
	//max-width: ${(props) => (props.imageWidth ? props.imageWidth : "120px")};
	margin: 2.5rem;
	min-width: ${(props) => !props.imageWidth && "110px"};
	@media screen and (max-width: ${viewSize.mobile}) {
		width: 40%;
		max-height: -webkit-fill-available;
		max-width: max-content;
	}
`;
