import {
  desktopFontSize,
  mobileFontSize,
  fontWeight,
  colorPallete,
} from "../../config";
import { footerLogo, odnMaker } from "../../config/images";

const footerTextProps = {
  logo: {
    fontSize: {
      desktop: desktopFontSize.xs,
      mobile: "0rem",
    },
    color: colorPallete.line,
    fontWeight: fontWeight.bold,
  },
  title: {
    fontSize: {
      desktop: desktopFontSize.sm1,
      mobile: mobileFontSize.sm,
    },
    color: colorPallete.bg,
    fontWeight: fontWeight.bold,
  },
  content: {
    fontSize: {
      desktop: desktopFontSize.sm1,
      mobile: mobileFontSize.sm,
    },
    color: colorPallete.cancel,
    fontWeight: fontWeight.medium,
  },
  copyright: {
    fontSize: {
      desktop: desktopFontSize.xs,
      mobile: mobileFontSize.xs1,
    },
    color: colorPallete.cancel,
    fontWeight: fontWeight.medium,
  },
  link: {
    fontSize: {
      desktop: desktopFontSize.sm1,
      mobile: mobileFontSize.sm,
    },
    color: colorPallete.white,
    fontWeight: fontWeight.medium,
  },
};

export const footerProps = {
  logo: {
    src: footerLogo,
    text: { ...footerTextProps.logo, children: "주식회사 오든" },
  },
  links: [
    { ...footerTextProps.link, children: "서비스 이용약관" },
    { ...footerTextProps.link, children: "개인정보처리방침", href: "/privacy" },
  ],
  copyright: [
    {
      ...footerTextProps.copyright,
      children: "Copyright © 2022. ODN Corp. All rights reserved.",
    },
  ],
  maker: [
    {
      type: "maker",
      ...footerTextProps.copyright,
      children: "Made By",
      image: { src: odnMaker, alt: "오든메이커" },
    },
  ],
  sections: [
    {
      title: { ...footerTextProps.title, children: "본사" },
      contents: [
        {
          ...footerTextProps.content,
          children:
            "전라남도 순천시 해룡면 율촌산단4로 13,\n 지식산업센터동 218호(전남테크노파크)",
          type: "single",
        },
        {
          type: "multiple",
          items: [
            {
              title: { ...footerTextProps.content, children: "TEL" },
              content: { ...footerTextProps.content, children: "061-752-3655" },
            },
            {
              title: { ...footerTextProps.content, children: "FAX" },
              content: { ...footerTextProps.content, children: "061-752-3655" },
            },
            {
              title: { ...footerTextProps.content, children: "E-mail" },
              content: {
                ...footerTextProps.content,
                children: "odn_promo@odn.us",
              },
            },
          ],
        },
      ],
    },
    {
      title: { ...footerTextProps.title, children: "광주 연구소 지점" },
      contents: [
        {
          ...footerTextProps.content,
          children: "광주광역시 북구 첨단과기로 399, 2층 3호",
        },
      ],
    },
  ],
};
