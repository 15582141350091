import styled from "styled-components";
import { colorPallete } from "../../../config";

export const Container = styled.div`
    z-index : 5;
    position : fixed;    
    display : flex;
    flex-direction : column;
    top : 40%;
    right : 3%;
    align-items : center;
    justify-content : center;

    > div {
        margin : 0.5rem 0;
    }
    > img { 
        margin : 0.5rem 0;
    }
`

export const Item = styled.div`
    cursor : pointer;
`

export const WaterImg = styled.img`

`

export const Unselect = styled.div`
    width : 7.2px;
    height: 7.2px;
    border-radius: 100%;
    background-color : ${colorPallete.txt};
`