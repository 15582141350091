import styled from "styled-components";
import { colorPallete, defaultSize, viewSize } from "../../../config";

export const Container = styled.div`
	width: ${(props) =>
		props.width ? props.width.desktop : defaultSize.desktop.cardWidth};
	height: ${(props) =>
		props.height ? props.height.desktop : defaultSize.desktop.cardHeight};
	display: flex;
	align-items: center;
	justify-content: center;
	background-color: ${(props) =>
		props.bgColor ? props.bgColor : colorPallete.bg};
	box-sizing: border-box;
	padding: 1rem;

	@media screen and (max-width: ${viewSize.mobile}) {
		width: ${(props) =>
			props.width ? props.width.mobile : defaultSize.mobile.cardWidth};
		height: ${(props) =>
			props.height ? props.height.mobile : defaultSize.mobile.cardHeight};
	}
`;
