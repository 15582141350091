import styled from "styled-components";

export const Container = styled.div`
`

export const HiddenRadio = styled.input`
    width : 1px;
    height : 1px;
    overflow : hidden;
    position : fixed;
    top: 999999px;
`

export const Label = styled.label`
    cursor : pointer;
`