import React from 'react'
import * as S from './style'
import { SectionTitle } from '../../organism'
import { AbsoluteImg } from '../../atom'
import { colorPallete } from '../../../config'
import { businessSea } from '../../../config/images'

const BussinessSection = React.forwardRef(({
    sectionTitle,
    desktopImages = [],
    tabletImages = [],
    mobileImages = [],
    smallMobileImages = [],
    main }, ref) => {
    return (
        <S.Container ref={ref}>
            <S.TitleWrapper>
                <SectionTitle {...sectionTitle} />
            </S.TitleWrapper>
            <S.DesktopImgWrapper {...main.desktop}>
                <S.MainImg src={businessSea} style={{ position: 'absolute', width: '100%', height: '600px', zIndex: -0 }} />
                <div style={{ maxWidth: '1176px', margin: 'auto auto auto 6%', width: '100%', position: 'absolute', height: '500px' }}>
                    {desktopImages.map((image, index, array) => (
                        image.link !== true ? <AbsoluteImg key={index} {...image} /> :
                            <S.BuoyLink href='/projects#device'>
                                <AbsoluteImg key={index} {...image} />
                                <S.FocusObj><span /></S.FocusObj>
                            </S.BuoyLink>
                    ))}
                </div>
            </S.DesktopImgWrapper>
            <S.TabletImgWrapper>
                <S.MainImg src={businessSea} style={{ position: 'absolute', width: '100%', height: '600px', zIndex: -0 }} />
                <div style={{ maxWidth: '800px', margin: 'auto auto auto 6%', width: '100%', position: 'absolute', height: '500px' }}>
                    {tabletImages.map((image, index, array) => (
                        image.link !== true ? <AbsoluteImg key={index} {...image} /> :
                            <S.BuoyLink href='/projects#device'>
                                <AbsoluteImg key={index} {...image} />
                                <S.FocusObj><span /></S.FocusObj>
                            </S.BuoyLink>
                    ))}
                </div>
            </S.TabletImgWrapper>
            <S.MobileImgWrapper>
                <S.MainImg src={businessSea} style={{ position: 'absolute', width: '100%', height: '500px', zIndex: -0 }} />
                <div style={{ maxWidth: '600px', margin: 'auto auto auto 6%', width: '100%', position: 'absolute', height: '500px' }}>
                    {mobileImages.map((image, index, array) => (
                        image.link !== true ? <AbsoluteImg key={index} {...image} /> :
                            <S.BuoyLink href='/projects#device'>
                                <AbsoluteImg key={index} {...image} />
                                <S.FocusObj><span /></S.FocusObj>
                            </S.BuoyLink>
                    ))}
                </div>
            </S.MobileImgWrapper>
            <S.SmallMobileImgWrapper {...main.mobile}>
                <div style={{ position: 'absolute', top: '20%', width: '100%', height: '500px', zIndex: -0, backgroundColor: colorPallete.secondary }}></div>
                <S.MainImg src={businessSea} style={{ position: 'absolute', width: '100%', height: '200px', zIndex: -0 }} />
                <div style={{ maxWidth: '330px', margin: 'auto auto auto 6%', width: '100%', position: 'absolute', height: '500px' }}>
                    {smallMobileImages.map((image, index, array) => (
                        image.link !== true ? <AbsoluteImg key={index} {...image} /> :
                            <S.BuoyLink href='/projects#device'>
                                <AbsoluteImg key={index} {...image} />
                                <S.FocusObj><span /></S.FocusObj>
                            </S.BuoyLink>
                    ))}
                </div>
            </S.SmallMobileImgWrapper>
        </S.Container>
    )
})

export default BussinessSection