import styled from "styled-components";
import { defaultSize, viewSize } from "../../../config";
import { colorPallete } from "../../../config";
import desktopWave from "../../../static/images/footer_desktop_wave.svg";
import mobileWave from "../../../static/images/footer_mobile_wave.svg";

export const Container = styled.div`
  position: relative;
  background-image: url(${desktopWave});
  background-size: cover;
  background-repeat: no-repeat;
  background-color: white;
  @media screen and (max-width: ${viewSize.mobile}) {
    background-image: url(${mobileWave});
  }
`;

export const Wave = styled.div`
  position: absolute;
  top: 0;
  z-index: -99999;
  width: 100%;
`;

export const FooterWrapper = styled.div`
  width: 98%;
  max-width: ${defaultSize.desktop.container};
  display: flex;
  align-items: center;
  margin: auto;
  height: 12rem;
  justify-content: space-around;
  padding-top: 3em;
  flex-wrap: wrap;

  @media screen and (min-width: ${viewSize.mobile}) and (max-width: ${viewSize.tablet}) {
    flex-direction: column;
    padding: 4rem 0;
  }

  @media screen and (max-width: ${viewSize.mobile}) {
    flex-direction: column;
    flex-wrap: nowrap;
    text-align: left;
    padding: 4rem 0;
  }
`;

export const LogoWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: end;

  @media screen and (min-width: ${viewSize.mobile}) and (max-width: ${viewSize.tablet}) {
    margin: ${(props) => (props.margin ? props.margin : "auto auto auto 0px")};
  }

  @media screen and (max-width: ${viewSize.mobile}) {
    flex-direction: column;
    margin-bottom: 1rem;
    padding-top: 2rem;
    min-width: 20rem;
    align-items: start;
  }
`;

export const Logo = styled.img`
  @media screen and (max-width: ${viewSize.mobile}) {
    width: 6rem;
    height: 2.25rem;
  }
`;

export const SectionWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: ${(props) => (props.alignItems ? props.alignItems : "start")};

  @media screen and (max-width: ${viewSize.mobile}) {
    align-items: start;
    padding-bottom: 1rem;
  }

  @media screen and (min-width: ${viewSize.mobile}) and (max-width: ${viewSize.tablet}) {
    margin: ${(props) => (props.margin ? props.margin : "auto auto auto 0px")};
  }
`;

export const Link = styled.div``;

export const Sep = styled.div`
  width: 0.125rem;
  height: 0.125rem;
  background-color: ${(props) =>
    props.bgColor ? props.bgColor : colorPallete.head};
  border-radius: 100%;
  margin: auto 1rem;
`;

export const LinkWrapper = styled.div`
  display: flex;
`;

export const TextWrapper = styled.div`
  display: flex;
  margin: ${(props) => props.margin && props.margin};
  &.maker {
    align-items: center;
  }
  @media screen and (max-width: ${viewSize.mobile}) {
    flex-direction: column;
    white-space: pre-line;
    min-width: 20rem;
    text-align: left;
    &.maker {
      flex-direction: row;
      align-items: center;
      /* display: none; */
    }
  }
`;
export const Image = styled.img`
  width: 4rem;
  height: 1.2rem;
  filter: brightness(0) invert(1);
  -webkit-filter: brightness(0) invert(1);
  opacity: 0.5;
  margin-right: 0.5rem;
  @media screen and (max-width: ${viewSize.mobile}) {
    width: 3.5rem;
    height: 1rem;
  }
`;
