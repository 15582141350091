import React from "react";
import * as S from "./style";
import { NewsCard, SectionTitle } from "../../organism";
import { ReactComponent as LeftArrow } from "../../../static/images/leftArrow.svg";
import { ReactComponent as RightArrow } from "../../../static/images/rightArrow.svg";
import { IconButton, Wave } from "../../atom";

const StorySection = React.forwardRef(
	({ sectionTitle, story, waveStyle }, ref) => {
		const sliderRef = React.useRef(null);
		const settings = {
			dots: false,
			infinite: true,
			speed: 500,
			slidesToShow: 3,
			slidesToScroll: 1,
			arrows: false,
			centerPadding: "50px",
			variableHeight: true,
			centerMode: true,
			responsive: [
				{
					breakpoint: 900,
					settings: {
						slidesToShow: 2,
						slidesToScroll: 1,
						variableWidth: true,
					},
				},
				{
					breakpoint: 480,
					settings: {
						slidesToShow: 1,
						slidesToScroll: 1,
						variableWidth: true,
					},
				},
			],
		};

		const next = () => {
			sliderRef.current.slickNext();
		};

		const prev = () => {
			sliderRef.current.slickPrev();
		};
		return (
			<S.Container ref={ref}>
				<Wave {...waveStyle} />
				<S.Wrapper>
					<S.TitleWrapper>
						<SectionTitle {...sectionTitle} />

						<S.ButtonWrapper>
							<IconButton onClick={prev} icon={<LeftArrow />}></IconButton>
							<IconButton onClick={next} icon={<RightArrow />}></IconButton>
						</S.ButtonWrapper>
					</S.TitleWrapper>

					<S.StoryWrapper {...settings} ref={sliderRef}>
						{story.map((item, index, array) => (
							<S.CardWrapper key={index}>
								<NewsCard {...item} />
							</S.CardWrapper>
						))}
					</S.StoryWrapper>
				</S.Wrapper>
			</S.Container>
		);
	},
);

export default StorySection;
