import React from 'react'
import * as S from './style'
import { SectionTitle, InputForm, CheckForm } from '../../organism'
import wave_contact from '../../../static/images/wave_main.png'
import { Button } from '../../atom'

const ContactSection = React.forwardRef(({ sectionTitle, leftForms = [], rightForms = [], buttonForm, checkForm }, ref) => {
    return (
        <S.Container ref={ref}>
            <S.TitleWrapper>
                <SectionTitle {...sectionTitle} />
                <S.WaveContact src={wave_contact} />
            </S.TitleWrapper>
            <S.FormWave>
                <S.Wrapper>
                    <S.FormWrapper>
                        <S.LeftForm>
                            {leftForms.map((form, index, array) => (
                                <InputForm key={index} {...form} />
                            ))}
                        </S.LeftForm>
                        <S.RightForm>
                            {rightForms.map((form, index, array) => (
                                <InputForm key={index} {...form} />
                            ))}
                        </S.RightForm>
                        <S.BottomForm>
                            <S.CheckFormWrapper>
                                <CheckForm {...checkForm} />
                            </S.CheckFormWrapper>
                            <S.ButtonFormWrapper>
                                <Button {...buttonForm} />
                            </S.ButtonFormWrapper>
                        </S.BottomForm>
                    </S.FormWrapper>
                </S.Wrapper>
            </S.FormWave>
        </S.Container>
    )
})

export default ContactSection