import {
  colorPallete,
  desktopFontSize,
  fontWeight,
  mobileFontSize,
} from "../../config";
import {
  avingtv,
  aws기술미팅,
  leaflet,
  promotionvideo,
  관상어박람회,
  기술블로그,
  데이터사업자,
  두바이이벤트,
  뿌리기업확인서,
  솔루션데모,
  스마트양식,
  아이알,
  업무협약,
  엔젤투자매칭펀드,
  우수기관,
  월간_7월,
  유망중소기업지정,
  장관상,
  전남간담회,
  전남최초,
  창업경진대회,
  최우수상,
  포엠mou,
  프랑스그룹제품조명,
  피해예방,
  해남어란어촌계,
  해양조사기술자,
} from "../../config/images";
import { sectionProps } from "../common";

const textProps = {
  title: {
    fontSize: {
      desktop: desktopFontSize.xl2,
      mobile: mobileFontSize.base,
    },
    fontWeight: fontWeight.black,
    color: colorPallete.white,
  },
  date: {
    fontSize: {
      desktop: desktopFontSize.sm1,
      mobile: mobileFontSize.xs1,
    },
    color: colorPallete.cancel,
    fontWeight: fontWeight.bold,
  },
};

const props = {
  sectionTitle: {
    title: {
      ...sectionProps.title,
      children: "NEWS",
      color: colorPallete.white,
    },
    description: {
      ...sectionProps.description,
      children: "오든의 새로운 소식",
      color: colorPallete.line,
    },
  },
  news: [
    {
      image: { src: 데이터사업자, alt: "뉴스 이미지" },
      title: {
        children: "데이터사업자 신고",
        ...textProps.title,
      },
      href: "https://blog.naver.com/odnus/223552799561",
      date: { children: "2024-08-19", ...textProps.date },
    },
    {
      image: { src: 뿌리기업확인서, alt: "뉴스 이미지" },
      title: {
        children: "뿌리기업 확인서",
        ...textProps.title,
      },
      href: "https://blog.naver.com/odnus/223552794765",
      date: { children: "2024-08-19", ...textProps.date },
    },
    {
      image: { src: 유망중소기업지정, alt: "뉴스 이미지" },
      title: {
        children: "전라남도 유망 중소기업 지정(2024-12호)",
        ...textProps.title,
      },
      href: "https://blog.naver.com/odnus/223552792566",
      date: { children: "2024-08-19", ...textProps.date },
    },
    {
      image: { src: 월간_7월, alt: "뉴스 이미지" },
      title: {
        children: "월간 오든 2024 8월호",
        ...textProps.title,
      },
      href: "https://blog.naver.com/odnus/223537338393",
      date: { children: "2024-08-05", ...textProps.date },
    },
    {
      image: { src: 관상어박람회, alt: "뉴스 이미지" },
      title: {
        children: "2024 한국 관상어산업박람회 부스참여",
        ...textProps.title,
      },
      href: "https://blog.naver.com/odnus/223507751755",
      date: { children: "2024-07-10", ...textProps.date },
    },
    {
      image: { src: 월간_7월, alt: "뉴스 이미지" },
      title: {
        children: "월간 오든 2024 7월호",
        ...textProps.title,
      },
      href: "https://blog.naver.com/odnus/223497103313",
      date: { children: "2024-07-01", ...textProps.date },
    },
    {
      image: { src: 기술블로그, alt: "뉴스 이미지" },
      title: {
        children: "AWS 기술 블로그 내 ODN 제품 소개 자료 게시",
        ...textProps.title,
      },
      href: "https://blog.naver.com/odnus/223306414935",
      date: { children: "2023-12-29", ...textProps.date },
    },
    {
      image: { src: leaflet, alt: "뉴스 이미지" },
      title: {
        children: "오든 기업 및 제품 소개서 Unveil",
        ...textProps.title,
      },
      href: "https://blog.naver.com/odnus/223245304430",
      date: { children: "2023-10-24", ...textProps.date },
    },
    {
      image: { src: 엔젤투자매칭펀드, alt: "뉴스 이미지" },
      title: {
        children: "전남테크노파크, 입주기업 오든 엔젤투자매칭펀드 투자 유치",
        ...textProps.title,
      },
      href: "https://blog.naver.com/odnus/223186742531",
      date: { children: "2023-08-18", ...textProps.date },
    },
    {
      image: { src: aws기술미팅, alt: "뉴스 이미지" },
      title: {
        children: "230725 ODN x AWS 3차 기술 미팅 진행",
        ...textProps.title,
      },
      href: "https://blog.naver.com/odnus/223165813472",
      date: { children: "2023-07-25", ...textProps.date },
    },
    {
      image: { src: 두바이이벤트, alt: "뉴스 이미지" },
      title: {
        children: "Dubai International Best Practices 이벤트 참여",
        ...textProps.title,
      },
      href: "https://blog.naver.com/odnus/223149362905",
      date: { children: "2023-07-07", ...textProps.date },
    },
    {
      image: { src: 전남최초, alt: "뉴스 이미지" },
      title: {
        children: "전남 최초 해양정보서비스업 등록 기업 '오든'",
        ...textProps.title,
      },
      href: "https://blog.naver.com/odnus/223147253624",
      date: { children: "2023-07-05", ...textProps.date },
    },
    {
      image: { src: 장관상, alt: "뉴스 이미지" },
      title: {
        children: "오든 중소벤처기업부 장관상 수상",
        ...textProps.title,
      },
      href: "https://blog.naver.com/odnus/223135125115",
      date: { children: "2023-06-21", ...textProps.date },
    },
    {
      image: { src: 솔루션데모, alt: "뉴스 이미지" },
      title: {
        children: "기관, 연구소 대상 해양환경 측정 실시간 모니터링 솔루션 DEMO",
        ...textProps.title,
      },
      href: "https://blog.naver.com/odnus/223120852892",
      date: { children: "2023-06-05", ...textProps.date },
    },
    {
      image: { src: 프랑스그룹제품조명, alt: "뉴스 이미지" },
      title: {
        children: "프랑스 Aqualabo그룹 오든 제품 조명",
        ...textProps.title,
      },
      href: "https://blog.naver.com/odnus/223117972651",
      date: { children: "2023-05-31", ...textProps.date },
    },
    {
      image: { src: 해양조사기술자, alt: "뉴스 이미지" },
      title: {
        children: "해양조사기술자 교육 참여",
        ...textProps.title,
      },
      href: "https://blog.naver.com/odnus/223106356755",
      date: { children: "2023-05-19", ...textProps.date },
    },
    {
      image: { src: avingtv, alt: "뉴스 이미지" },
      title: {
        children: "IoT스마트 부표로 新 해양 서비스업 기틀 만들어 나갈 것!",
        ...textProps.title,
      },
      href: "https://blog.naver.com/odnus/223105587559",
      date: { children: "2023-05-18", ...textProps.date },
    },
    {
      image: { src: 포엠mou, alt: "뉴스 이미지" },
      title: {
        children:
          "친환경 소재 고성능 슈퍼 커패시터 개발 주식회사 포엠과 MOU체결",
        ...textProps.title,
      },
      href: "https://blog.naver.com/odnus/223059838148",
      date: { children: "2023-03-30", ...textProps.date },
    },
    {
      image: { src: 전남간담회, alt: "뉴스 이미지" },
      title: {
        children: "광주 전남 지역 스타트업 생태계 활성화 현장간담회",
        ...textProps.title,
      },
      href: "https://blog.naver.com/odnus/223059949784",
      date: { children: "2023-03-30", ...textProps.date },
    },
    {
      image: { src: promotionvideo, alt: "뉴스 이미지" },
      title: {
        children: "오든 기업 홍보영상 공개",
        ...textProps.title,
      },
      href: "https://blog.naver.com/odnus/223039640483",
      date: { children: "2023-03-09", ...textProps.date },
    },
    {
      image: { src: 해남어란어촌계, alt: "뉴스 이미지" },
      title: {
        children: "해남 어란 어촌계 스마트 부표 인스톨",
        ...textProps.title,
      },
      href: "https://blog.naver.com/odnus/223017755998",
      date: { children: "2023-02-15", ...textProps.date },
    },
    {
      image: { src: 우수기관, alt: "뉴스 이미지" },
      title: {
        children: "2022 데이터바우처 우수기업 사례 선정",
        ...textProps.title,
      },
      href: "https://blog.naver.com/odnus/222969060131",
      date: { children: "2022-12-25", ...textProps.date },
    },
    {
      image: { src: 아이알, alt: "뉴스 이미지" },
      title: {
        children: "2022년 2차 투자유치 기관통합 IR",
        ...textProps.title,
      },
      href: "https://blog.naver.com/odnus/222969062025",
      date: { children: "2022-12-07", ...textProps.date },
    },
    {
      image: { src: 피해예방, alt: "뉴스 이미지" },
      title: {
        children: "친환경 부표 사용과 해양환경 데이터 솔루션",
        ...textProps.title,
      },
      href: "https://blog.naver.com/odnus/222969054412",
      date: { children: "2022-10-29", ...textProps.date },
    },
    {
      image: { src: 업무협약, alt: "뉴스 이미지" },
      title: {
        children: "광주광역시X오든, 광주 AI 조성 업무협약",
        ...textProps.title,
      },
      href: "https://blog.naver.com/odnus/222969049389",
      date: { children: "2022-03-23", ...textProps.date },
    },
    {
      image: { src: 창업경진대회, alt: "뉴스 이미지" },
      title: {
        children: "2021 순천시 창업 경진대회 '대상' 수상",
        ...textProps.title,
      },
      href: "https://blog.naver.com/odnus/222969043910",
      date: { children: "2021-12-06", ...textProps.date },
    },
    {
      image: { src: 스마트양식, alt: "뉴스 이미지" },
      title: {
        children: "바다 샅샅이 기록 '똑똑한 부표'로 스마트 양식업 선도",
        ...textProps.title,
      },
      href: "https://blog.naver.com/odnus/222969048129",
      date: { children: "2021-11-15", ...textProps.date },
    },
    {
      image: { src: 최우수상, alt: "뉴스 이미지" },
      title: {
        children: "호남권 엑셀러레이팅 IR 프로그램 '최우수상' 수상",
        ...textProps.title,
      },
      href: "https://blog.naver.com/odnus/222969040211",
      date: { children: "2021-10-20", ...textProps.date },
    },
  ],
};

export default props;
