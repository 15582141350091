import React from 'react'
import * as S from './style'
import { CheckBox, Link, Text } from '../../atom'

const CheckForm = ({ check, text, link, description }) => {
    return (
        <S.Container>
            <S.FormWrapper>
                <S.CheckBoxWrapper>
                    <CheckBox {...check} />
                </S.CheckBoxWrapper>
                <S.TextWrapper>
                    <Text {...text} />
                </S.TextWrapper>
                <S.LinkWrapper>
                    <Link {...link} />
                </S.LinkWrapper>
            </S.FormWrapper>
            {description && (
                <S.DescriptionWrapper>
                    <Text {...description} />
                </S.DescriptionWrapper>
            )}
        </S.Container>
    )
}

export default CheckForm