import styled from "styled-components";
import { viewSize } from "../../../config";
import {
	firstBackAnimation,
} from "../../../animation/tech-iconback";
import {
	TopAnimation,
	MiddleAnimation,
	BottomAnimation,
} from "../../../animation/techIconsBg";
export const Container = styled.div`
	display: flex;
	flex-direction: column;
`;

export const Wrapper = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
	flex-direction: column;
	text-align: center;
	margin: 1rem auto;
	position: relative;
	@media screen and (max-width: ${viewSize.mobile}) {
		margin: 0.5rem auto;
	}
`;

export const BackImg = styled.img`
	position: absolute;
	width: 200%;
	animation: ${(props) =>
		props.animation ? props.animation : firstBackAnimation};
	${(props) => props.rotate && `transform : rotate(${props.rotate});`};
	@media screen and (max-width: ${viewSize.mobile}) {
		width: 130%;
	}
`;

export const TitleWrapper = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
	margin-bottom: 1rem;
	z-index: 2;
	@media screen and (max-width: ${viewSize.mobile}) {
		margin-bottom: 0.5rem;
	}
`;

export const Img = styled.img`
	margin: 0.5rem;
	z-index: 2;
	@media screen and (max-width: ${viewSize.mobile}) {
		width: 47%;
		margin: 0.5rem 0.5rem 0.2rem 0.5rem;
	}
`;

export const DescriptionWrapper = styled.div`
	z-index: 2;
`;
export const BgWrapper = styled.div`
	position: absolute;
	width: 14rem;
	height: 14rem;
	display: inline;
	display: flex;
	justify-content: center;
	align-items: center;
	margin-top: 50px;
	@media screen and (max-width: ${viewSize.mobile}) {
		width: 8.5rem;
		height: 8.5rem;
		margin-top: 20px;
	}
`;
export const TopBg = styled.div`
	position: absolute;
	top: 0px;
	left: 0px;
	width: 100%;
	height: 100%;
	background-color: white;
	border-radius: 38% 62% 63% 37% / 41% 44% 56% 59%;
	opacity: 1;
	animation: ${TopAnimation};
`;
export const MiddleBg = styled.div`
	position: absolute;
	top: 0px;
	left: 0px;
	width: 100%;
	height: 100%;
	background-color: white;
	border-radius: 38% 62% 63% 37% / 53% 44% 56% 34%;
	opacity: 0.6;
	animation: ${MiddleAnimation};
`;

export const BottomBg = styled.div`
	position: absolute;
	top: 0px;
	left: 0px;
	width: 100%;
	height: 100%;
	background-color: white;
	border-radius: 45% 43% 45% 37% / 35% 50% 56% 59%;
	opacity: 0.3;
	animation: ${BottomAnimation};
`;
