import React from "react";
import * as S from "./style";
//import defaultBg from "../../../static/images/white_wave.png";
import defaultBg from "../../../static/images/white_short_wave.png";
const Wave = ({ bgImage, marginTop, zIndex }) => {
	const waveProps = {
		marginTop,
		zIndex,
	};
	return (
		<S.Container {...waveProps}>
			<S.Wave src={bgImage ? bgImage : defaultBg} />
		</S.Container>
	);
};

export default Wave;
