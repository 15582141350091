import { Icon } from "../../components/atom";
import { cameraIcon, buoyIcon, sensorIcon } from "../../config/images";
import React from 'react'

const modalProps = {
    location: {
        lat: 34.709597, lng: 126.905018
    },
    items: [
        {
            lat: 34.453297,
            lng: 127.022678,
            location: true,
            camera: true,
        },
        {
            lat: 34.760675,
            lng: 126.130660,
            location: true,
            camera: true
        },
        {
            lat: 34.393295,
            lng: 126.872078,
            location: true,
            camera: true
        },
        {
            lat: 34.880009,
            lng: 127.597725,
            location: true,
            buoy: true
        },
        {
            lat: 34.344068,
            lng: 126.738671,
            location: true,
            camera: true
        }
    ]
}


export const bottomContent = (
    <React.Fragment>
        <Icon text={{ children: '부표' }} img={{ src: buoyIcon }} />
        <Icon text={{ children: '센서' }} img={{ src: sensorIcon }} />
        <Icon text={{ children: '카메라' }} img={{ src: cameraIcon }} />
    </React.Fragment>
)
export default modalProps