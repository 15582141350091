import React from 'react'
import * as S from './style'
import { Text } from '../index'

const IconButton = ({
    icon, width, border, radius, height, onClick,
    position, top, right, bottom, left, bgColor, text,
    zIndex
}) => {
    const iconProps = {
        position,
        top,
        right,
        bottom,
        left,
        width,
        border,
        radius,
        height,
        bgColor,
        onClick,
        zIndex
    }
    return (
        <S.IconButton {...iconProps}>
            {icon}
            {text && (
                <S.TextWrapper>
                    <Text {...text} />
                </S.TextWrapper>
            )}
        </S.IconButton>
    )
}

export default IconButton