import React from 'react'
import * as S from './style'
import { Device } from '../../organism'
import { IconButton, Radio, Text } from '../../atom'
import { cloneObject } from '../../../utils'

const MultipleText = ({ childrens = [], text }) => {
    return childrens.map((children, index, array) => (
        <Text key={index} {...text} children={children} />
    ))
}

const MobileDevices = ({ devices = [], selectButton, images = [], idx, setDevice, button, refs }) => {
    return (
        <S.Wrapper isMobile={true}>
            <S.MobileDeviceButtonWrapper>
                {devices.map((device, index, array) => {
                    if (idx === index) {
                        selectButton.text.children = `0${index + 1}`
                        return (<IconButton key={index} {...selectButton} onClick={() => setDevice(index)} />)
                    }
                    let buttonClone = cloneObject(button)
                    buttonClone.text.children = `0${index + 1}`
                    return (<IconButton key={index} {...buttonClone} onClick={() => setDevice(index)} />)
                })}
            </S.MobileDeviceButtonWrapper>
            {devices.map((device, index, array) => (
                <React.Fragment>
                    <S.AnimationWrapper >
                        <S.DeviceWrapper selected={index === idx}>
                            <S.DeviceItem>
                                <Device key={index} {...device} />
                            </S.DeviceItem>
                        </S.DeviceWrapper>
                        <S.DeviceImgWrapper selected={index === idx}>
                            <S.DeviceImg key={index} {...images[index]} />
                        </S.DeviceImgWrapper>
                    </S.AnimationWrapper>
                    <S.VisibilityHidden>
                        <S.DeviceWrapper>
                            <S.DeviceItem>
                                <Device key={index} {...device} />
                            </S.DeviceItem>
                        </S.DeviceWrapper>
                        <S.DeviceImgWrapper ref={e => (refs.current[index] = e)} selected={index === idx}>
                            <S.DeviceImg key={index} {...images[index]} />
                        </S.DeviceImgWrapper>
                    </S.VisibilityHidden>
                </React.Fragment>
            ))}
        </S.Wrapper>
    )
}

const DesktopDevices = ({ devices = [], selectButton, images = [], idx, setDevice, button, refs }) => {
    const category = 'desktop'
    return (
        <S.Wrapper>
            {devices.map((device, index, array) => (
                <React.Fragment>
                    <S.DesktopDeviceWrapper >
                        <S.DeviceWrapper selected={index === idx}>
                            <S.DeviceItem>
                                <Device key={index} {...device} />
                            </S.DeviceItem>
                        </S.DeviceWrapper>
                        <S.DeviceButtonWrapper selected={index === idx} isDesktop={true}>
                            {devices.map((device, index, array) => {
                                if (idx === index) {
                                    selectButton.text.children = `0${index + 1}`
                                    return (<IconButton key={index} {...selectButton} onClick={() => setDevice(index, category)} />)
                                }
                                let buttonClone = cloneObject(button)
                                buttonClone.text.children = `0${index + 1}`
                                return (<IconButton key={index} {...buttonClone} onClick={() => setDevice(index, category)} />)
                            })}
                        </S.DeviceButtonWrapper>
                        <S.DeviceImgWrapper selected={idx === index}>
                            <S.DeviceImg key={index} {...images[index]} />
                        </S.DeviceImgWrapper>
                    </S.DesktopDeviceWrapper>
                    <S.VisibilityHidden ref={e => (refs.current[index] = e)}>
                        <S.DeviceWrapper>
                            <S.DeviceItem>
                                <Device key={index} {...device} />
                            </S.DeviceItem>
                        </S.DeviceWrapper>
                        <S.DeviceButtonWrapper>
                            {devices.map((device, index, array) => {
                                if (idx === index) {
                                    selectButton.text.children = `0${index + 1}`
                                    return (<IconButton key={index} {...selectButton} onClick={() => setDevice(index)} />)
                                }
                                let buttonClone = cloneObject(button)
                                buttonClone.text.children = `0${index + 1}`
                                return (<IconButton key={index} {...buttonClone} onClick={() => setDevice(index)} />)
                            })}
                        </S.DeviceButtonWrapper>
                        <S.DeviceImgWrapper>
                            <S.DeviceImg key={index} {...images[index]} />
                        </S.DeviceImgWrapper>
                    </S.VisibilityHidden>
                </React.Fragment>
            ))}
        </S.Wrapper>
    )
}

const Services = ({ serviceRadios = [], serviceImages = [], serviceDescription = [], serviceIdx, refs, setService, serviceTitle }) => {
    return (
        <S.Services>

            {serviceImages.map((images, index, array) => (
                <React.Fragment>
                    <S.SeviceW key={index}>
                        <S.ServiceTitle selected={index === serviceIdx}>
                            <Text {...serviceTitle} />
                        </S.ServiceTitle>
                        <S.ServiceWrapper>
                            <S.ServiceRadioWrapper selected={index === serviceIdx}>
                                {serviceRadios.map((radio, idx, array) => (
                                    <Radio {...radio} selected={index === idx} onChange={() => setService(idx)} />
                                ))}
                            </S.ServiceRadioWrapper>
                            <S.ServieImgWrapper selected={index === serviceIdx}>
                                <S.ServiceImg {...images} />
                            </S.ServieImgWrapper>
                            <S.ServiceDescriptionWrapper selected={index === serviceIdx}>
                                <S.ServiceItem>
                                    <MultipleText {...serviceDescription[index]} />
                                </S.ServiceItem>
                            </S.ServiceDescriptionWrapper>
                        </S.ServiceWrapper>
                    </S.SeviceW>

                    <S.VisibilityHidden ref={e => (refs.current[index] = e)}>
                        <S.ServiceRadioWrapper>
                        </S.ServiceRadioWrapper>
                        <S.ServieImgWrapper>
                            <S.ServiceImg {...serviceImages[index]} />
                        </S.ServieImgWrapper>
                        <S.ServiceDescriptionWrapper>
                            <S.ServiceItem>
                                <MultipleText {...serviceDescription[index]} />
                            </S.ServiceItem>
                        </S.ServiceDescriptionWrapper>
                    </S.VisibilityHidden>
                </React.Fragment>

            ))}
        </S.Services>
    )
}


const ProjectsSection = ({
    radios = [],
    value,
    devices = [],
    deviceIndex = 0,
    button,
    selectButton,
    images = [],
    serviceRadios = [],
    serviceImages = [],
    serviceDescription = [],
    serviceIdx = 0,
    serviceTitle
}) => {
    const desktopRefs = React.useRef([])
    const mobileRefs = React.useRef([])
    const serviceRefs = React.useRef([])
    const [scrollY, setScrollY] = React.useState(0)
    const [idx, setIdx] = React.useState({
        mobile: 0,
        desktop: 0,
        service: 0
    })
    React.useEffect(() => {
        window.addEventListener('scroll', () => setScrollY(window.scrollY))
        return () => {
            window.addEventListener('scroll', () => setScrollY(window.scrollY))
        }
    }, [])

    const setService = (idx) => {
        serviceRefs.current.forEach((item, index, array) => {
            if (index === idx) {
                setIdx({ ...idx, service: index })
                window.scrollTo(0, item.offsetTop + 100)
            }
        })
    }

    const setDevice = (idx, device) => {
        if (device === 'desktop') {
            desktopRefs.current.forEach((item, index, array) => {
                if (index === idx) {
                    setIdx({ ...idx, desktop: index })
                    window.scrollTo(0, item.offsetTop)
                }
            })
        } else {
            mobileRefs.current.forEach((item, index, array) => {
                if (index === idx) {
                    setIdx({ ...idx, desktop: index })
                    window.scrollTo(0, item.offsetTop)
                }
            })
        }
    }

    React.useEffect(() => {
        let desktopIdx = 0
        let mobileIdx = 0
        let serviceIdx = 0
        if (desktopRefs.current.length !== 0 && desktopRefs.current[0] !== null) {
            desktopRefs.current.forEach((item, index, array) => {
                if (item.offsetTop - 200 <= scrollY) {
                    desktopIdx = index
                    return
                }
            })
        }

        if (mobileRefs.current.length !== 0 && mobileRefs.current[0] !== null) {
            mobileRefs.current && mobileRefs.current.forEach((item, index, array) => {
                if (item.offsetTop - 200 <= scrollY) {
                    mobileIdx = index
                    return
                }
            })
        }

        if (serviceRefs.current.length !== 0 && serviceRefs.current[0] != null) {
            serviceRefs.current && serviceRefs.current.forEach((item, index, array) => {
                if (item.offsetTop + 100 <= scrollY) {
                    serviceIdx = index
                    return
                }
            })
        }

        setIdx({ mobile: mobileIdx, desktop: desktopIdx, service: serviceIdx })
    }, [scrollY])
    return (
        <S.Container>
            <S.StickyBox>
                <S.RadioWrapper>
                    {radios.map((radio, index, array) => (
                        <Radio key={index} {...radio} />
                    ))}
                </S.RadioWrapper>
                {
                    value === 'device' && (
                        <React.Fragment>
                            <MobileDevices devices={devices} selectButton={selectButton} images={images} idx={idx.mobile} button={button} setDevice={setDevice} refs={mobileRefs} />
                            <DesktopDevices devices={devices} selectButton={selectButton} images={images} idx={idx.desktop} button={button} setDevice={setDevice} refs={desktopRefs} />
                        </React.Fragment>
                    )
                }
                {
                    value === 'service' && (
                        <React.Fragment>

                            <Services serviceTitle={serviceTitle} serviceRadios={serviceRadios} serviceImages={serviceImages} serviceDescription={serviceDescription} setService={setService} serviceIdx={idx.service} refs={serviceRefs} />
                        </React.Fragment>
                    )
                }
            </S.StickyBox>
        </S.Container>
    )
}

export default ProjectsSection
