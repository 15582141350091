import {
  colorPallete,
  desktopFontSize,
  fontWeight,
  mobileFontSize,
} from "../../config";
import {
  ESG,
  NewBuoy,
  Tips,
  aws,
  전시회참여,
  도지사표창수상,
  배터리내장형런칭,
  센서,
  스마트부표전시회전시,
  실증테스트,
  어업현장지원사업,
  에이아이테크,
  전남정보문화산업진흥원장상,
  집들이,
  크런치베이스링크드인,
  특강,
  협회상,
} from "../../config/images";
import { sectionProps } from "../common";

const textProps = {
  title: {
    fontSize: {
      desktop: desktopFontSize.lg,
      mobile: mobileFontSize.base,
    },
    fontWeight: fontWeight.bold,
    color: colorPallete.head,
  },
  description: {
    fontSize: {
      desktop: desktopFontSize.sm1,
      mobile: mobileFontSize.xs1,
    },
    color: colorPallete.subtxt,
    fontWeight: fontWeight.bold,
  },
};

const props = {
  sectionTitle: {
    alignItem: "start",
    title: {
      ...sectionProps.title,
      children: "STORY",
    },
    description: {
      ...sectionProps.description,
      children: "오든 팀의 이야기",
    },
  },

  waveStyle: {
    marginTop: { desktop: "-10rem", tablet: "-1rem", mobile: "-2rem" },
  },
  story: [
    {
      category: "story",
      image: { src: 전남정보문화산업진흥원장상, alt: "스토리 이미지" },
      title: {
        children: "전남정보문화산업진흥원장상 수상",
        ...textProps.title,
      },
      href: "https://blog.naver.com/odnus/223291134545",
      date: { children: "2023-12-12", ...textProps.description },
    },
    {
      category: "story",
      image: { src: 도지사표창수상, alt: "스토리 이미지" },
      title: {
        children: "오든 황용희 대표 도지사 표창 수상",
        ...textProps.title,
      },
      href: "https://blog.naver.com/odnus/223286736809",
      date: { children: "2023-12-08", ...textProps.description },
    },
    {
      category: "story",
      image: { src: Tips, alt: "스토리 이미지" },
      title: {
        children:
          "AIoT 솔루션으로 스마트 아쿠아팜 선도기업 ‘오든(ODN)’, 팁스 선정",
        ...textProps.title,
      },
      href: "https://blog.naver.com/odnus/223234690007",
      date: { children: "2023-10-12", ...textProps.description },
    },
    {
      category: "story",
      image: { src: NewBuoy, alt: "스토리 이미지" },
      title: {
        children: "3세대 오든 스마트부표 공개",
        ...textProps.title,
      },
      href: "https://blog.naver.com/odnus/223216659541",
      date: { children: "2023-09-20", ...textProps.description },
    },
    {
      category: "story",
      image: { src: 스마트부표전시회전시, alt: "스토리 이미지" },
      title: {
        children:
          "[AI TECH+ 2023]오든, 해양 정보 수집·이용 가능 '스마트 부표' 전시",
        ...textProps.title,
      },
      href: "https://blog.naver.com/odnus/223202026503",
      date: { children: "2023-09-04", ...textProps.description },
    },
    {
      category: "story",
      image: { src: 전시회참여, alt: "스토리 이미지" },
      title: {
        children: "AI TECH+ 2023 전시회 참여",
        ...textProps.title,
      },
      href: "https://blog.naver.com/odnus/223197939833",
      date: { children: "2023-08-30", ...textProps.description },
    },
    {
      category: "story",
      image: { src: 어업현장지원사업, alt: "스토리 이미지" },
      title: {
        children:
          "거버넌스 회의 및 사업화 진행 관련 회의를 통한 요구사항 기반의 제품 개발",
        ...textProps.title,
      },
      href: "https://blog.naver.com/odnus/223165878963",
      date: { children: "2023-07-25", ...textProps.description },
    },
    {
      category: "story",
      image: { src: 배터리내장형런칭, alt: "스토리 이미지" },
      title: {
        children: "배터리 내장형 5일 단독 구동 해양환경 센싱 제품군 런칭",
        ...textProps.title,
      },
      href: "https://blog.naver.com/odnus/223165818511",
      date: { children: "2023-07-25", ...textProps.description },
    },
    {
      category: "story",
      image: { src: ESG, alt: "스토리 이미지" },
      title: {
        children: "제2회 광주 ESG협력포럼 및 기후환경에너지기업교류회",
        ...textProps.title,
      },
      href: "https://blog.naver.com/odnus/223128550105",
      date: { children: "2023-06-13", ...textProps.description },
    },
    {
      category: "story",
      image: { src: 크런치베이스링크드인, alt: "스토리 이미지" },
      title: {
        children: "오든 크런치베이스, 링크드인 기업 채널 개설",
        ...textProps.title,
      },
      href: "https://blog.naver.com/odnus/223111256853",
      date: { children: "2023-05-25", ...textProps.description },
    },
    {
      category: "story",
      image: { src: aws, alt: "스토리 이미지" },
      title: {
        children: "오든 X AWS 1st 미팅",
        ...textProps.title,
      },
      href: "https://blog.naver.com/odnus/223038293123",
      date: { children: "2023-03-08", ...textProps.description },
    },
    {
      category: "story",
      image: { src: 집들이, alt: "스토리 이미지" },
      title: {
        children: "개발팀 김팀장 이사기념 집들이",
        ...textProps.title,
      },
      href: "https://blog.naver.com/odnus/222969121189",
      date: { children: "2022-12-15", ...textProps.description },
    },
    {
      category: "story",
      image: { src: 특강, alt: "스토리 이미지" },
      title: {
        children: "순천대학교 창업동아리 행사 특강",
        ...textProps.title,
      },
      href: "https://blog.naver.com/odnus/222969119003",
      date: { children: "2022-11-24", ...textProps.description },
    },
    {
      category: "story",
      image: { src: 협회상, alt: "스토리 이미지" },
      title: {
        children: "한국창업보육협회장상 수상",
        ...textProps.title,
      },
      href: "https://blog.naver.com/odnus/222969113546",
      date: { children: "2022-11-24", ...textProps.description },
    },
    {
      category: "story",
      image: { src: 에이아이테크, alt: "스토리 이미지" },
      title: {
        children: "AI TECH+ 전시회 참여",
        ...textProps.title,
      },
      href: "https://blog.naver.com/odnus/222969102549",
      date: { children: "2022-09-28", ...textProps.description },
    },
    {
      category: "story",
      image: { src: 센서, alt: "스토리 이미지" },
      title: {
        children: "포터블 해양 환경 측정 솔루션",
        ...textProps.title,
      },
      href: "https://blog.naver.com/odnus/222969110984",
      date: { children: "2022-03-24", ...textProps.description },
    },
    {
      category: "story",
      image: { src: 실증테스트, alt: "스토리 이미지" },
      title: {
        children: "전국 각지에서 이뤄지는 실증 테스트",
        ...textProps.title,
      },
      href: "https://blog.naver.com/odnus/222969104656",
      date: { children: "2022-03-24", ...textProps.description },
    },
  ],
};

export default props;
