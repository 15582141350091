import styled from "styled-components";
import { viewSize } from "../../../config";
import Slider from "react-slick";
export const BgContainer = styled.div`
	display: flex;

	justify-content: center;
`;
export const Container = styled.div`
	margin: -1rem auto;
	display: flex;
	flex-direction: column;
	align-items: start;
	justify-content: start;
	@media screen and (max-width: ${viewSize.tablet}) {
		flex-direction: column;
		align-items: center;
		margin-bottom: 3rem;
	}

	@media screen and (max-width: ${viewSize.mobile}) {
		margin: 0.5rem auto;
	}
`;

export const Wrapper = styled.div`
	max-width: 1364px;
	margin: 5rem auto;
	width: 100%;
	display: flex;
	z-index: 1;

	@media screen and (max-width: ${viewSize.tablet}) {
		flex-direction: column;
		align-items: center;
		margin-bottom: 3rem;
	}

	@media screen and (max-width: ${viewSize.mobile}) {
		flex-direction: column;
		align-items: center;
		margin-bottom: 3rem;
		margin-top : 3rem;
	}
`;

export const TitleWrapper = styled.div`
	width: 20%;
	align-items: center;

	@media screen and (max-width: ${viewSize.tablet}) {
		width: 100%;
	}
`;

export const CardWrapper = styled.div`
	margin: auto 1rem;
`;

export const ButtonWrapper = styled.div`
	display: flex;
	width: 100%;
	max-width: 10rem;
	justify-content: space-between;
	margin: 4rem auto 0 0;

	@media screen and (max-width: ${viewSize.tablet}) {
		margin: 0.5rem auto 1rem auto;
		justify-content: space-evenly;

		> button {
			width: 2.25rem;
			height: 2.25rem;
		}
	}
`;

export const StoryWrapper = styled(Slider)`
	padding-bottom: 3rem;
	width: 80%;
	margin: 1rem auto;
	.slick-track {
		display: flex;
		align-items: center;
	}

	.slick-slide {
		display: flex;
		align-items: center;
		justify-content: center;
		margin: auto 1rem;
	}

	@media screen and (max-width: ${viewSize.tablet}) {
		width: 100%;
	}

	@media screen and (max-width: ${viewSize.mobile}) {
		margin: 0.5rem auto;
	}
`;
