import React from "react";
import * as S from "./style";
import { Text } from "../../atom";
import { defaultSize } from "../../../config";

const NewsCard = ({ image, title, date, href, category = "card" }) => {
  let cardProps = {
    width: {
      desktop: defaultSize.desktop.cardWidth,
      mobile: defaultSize.mobile.cardWidth,
    },
  };
  switch (category) {
    case "card":
      cardProps = {
        width: {
          desktop: defaultSize.desktop.cardWidth,
          mobile: defaultSize.mobile.cardWidth,
        },
      };
      break;
    case "story":
      cardProps = {
        width: {
          desktop: defaultSize.desktop.storyWidth,
          mobile: defaultSize.mobile.storyWidth,
        },
      };
      break;
    default:
      break;
  }
  return (
    <S.Container {...cardProps} href={href}>
      <S.Img {...image} />
      <S.TitleWrapper>
        <Text {...title} />
      </S.TitleWrapper>
      <S.DateWrapper>
        <Text {...date} />
      </S.DateWrapper>
    </S.Container>
  );
};

export default NewsCard;
