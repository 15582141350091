import { colorPallete, desktopFontSize, fontWeight, mobileFontSize } from '../../config'
import { HiChevronDoubleUp } from 'react-icons/hi'
const props = {
    links: [{}, {}, {}, {}, {}, {}]
}

export const topProps = {
    icon: <HiChevronDoubleUp size={30} color={colorPallete.txt} />,
    position: 'fixed',
    bottom: '10%',
    right: '10%',
    width: {
        desktop: '5rem',
        mobile: '3rem',
    },
    height: {
        desktop: '5rem',
        mobile: '3rem',
    },
    zIndex: 5,
    bgColor: colorPallete.white,
    onClick: () => window.scrollTo({ top: 0, behavior: 'smooth' }),
    text: {
        fontSize: { desktop: desktopFontSize.xs, mobile: mobileFontSize.xs1 },
        children: 'TOP',
        color: colorPallete.txt,
        fontWeight: fontWeight.bold
    }
}

export default props