import styled from 'styled-components'

export const Container = styled.div`
    display : flex;
    align-items : center;
    justify-content : center;
    flex-direction : column;
`

export const Icon = styled.img``

export const Description = styled.div``