import React from 'react'
import * as S from './style'
import { waterImg } from '../../../config/images'

const SideNav = ({ links = [] }) => {
    return (
        <S.Container>
            {links.map(link => (
                <S.Item {...link}>
                    {link.selected ? (
                        <S.WaterImg src={waterImg} />
                    ) : (
                        <S.Unselect />
                    )}
                </S.Item>
            ))}
        </S.Container>
    )
}

export default SideNav