import { sectionProps } from "../common";
import {
  fontWeight,
  mobileFontSize,
  desktopFontSize,
  colorPallete,
} from "../../config";

const textProps = {
  input: {
    fontSize: {
      desktop: desktopFontSize.lg,
      mobile: mobileFontSize.base,
    },
    color: colorPallete.head,
    fontWeight: fontWeight.semiBold,
  },
  check: {
    fontSize: {
      desktop: desktopFontSize.xl,
      mobile: mobileFontSize.base,
    },
    color: colorPallete.head,
    fontWeight: fontWeight.medium,
  },
  button: {
    fontSize: {
      desktop: desktopFontSize.xl2,
      mobile: mobileFontSize.xl,
    },
    fontWeight: fontWeight.bold,
    color: colorPallete.white,
  },
  link: {
    color: colorPallete.secondary,
    decoration: "underline",
    fontWeight: fontWeight.medium,
    fontSize: {
      desktop: desktopFontSize.base,
      mobile: mobileFontSize.base,
    },
  },
  description: {
    fontSize: {
      desktop: desktopFontSize.base,
      mobile: mobileFontSize.sm,
    },
    color: colorPallete.txt,
    fontWeight: fontWeight.medium,
  },
};

const props = {
  sectionTitle: {
    title: {
      ...sectionProps.title,
      children: "CONTACT US",
    },
    description: {
      ...sectionProps.description,
      children: "오든에 대하여 궁금하신 사항을 문의해주세요!",
    },
  },
  leftForms: [
    {
      text: { ...textProps.input, children: "NAME" },
      input: {
        name: "name",
        onChange: (e) => {
          console.log(e);
        },
      },
    },
    {
      text: { ...textProps.input, children: "COMPANY" },
      input: {
        name: "company",
        onChange: (e) => {
          console.log(e);
        },
      },
    },
    {
      text: { ...textProps.input, children: "EMAIL" },
      input: {
        name: "email",
        onChange: (e) => {
          console.log(e);
        },
      },
    },
  ],
  rightForms: [
    {
      text: { ...textProps.input, children: "MESSAGE" },
      textarea: {
        name: "message",
        onChange: (e) => {
          console.log(e);
        },
      },
    },
  ],
  buttonForm: {
    bgColor: colorPallete.secondary,
    text: {
      ...textProps.button,
      children: "문의하기",
    },
  },
  checkForm: {
    check: { onChange: () => console.log("click"), value: false },
    text: { ...textProps.check, children: "개인정보처리방침에 동의합니다." },
    link: { ...textProps.link, children: "자세히 보기", href: "/privacy" },
    description: {
      ...textProps.description,
      children: `문의에 대한 회신 목적으로만 사용되며\n문의 응대 후 파기됩니다.`,
    },
  },
};

export default props;
