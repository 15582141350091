import React from 'react'
import * as S from './style'


const Input = ({ value, onChange, color, border, padding, height, name, type }) => {
    const inputProps = {
        value, onChange, border, padding, height, name
    }

    return (
        <S.Input {...inputProps} />
    )
}

export default Input