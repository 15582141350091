import React from 'react'
import * as S from './style'


const AbsoluteImg = ({ src, top, left, right, bottom, width, zIndex, animation, height, hover }) => {
    const imgProps = {
        src,
        top,
        left,
        right,
        width,
        zIndex,
        animation,
        hover,
        bottom,
        height
    }

    if (height) {
        return (<S.Container {...imgProps}>
            <S.HeightImage {...imgProps} />
        </S.Container>)
    }
    return (
        <S.Image {...imgProps} />
    )
}

export default AbsoluteImg