import React, { useState } from 'react'
import * as S from './style'
import { Text, Link } from '../../atom'
import { ReactComponent as LeftArrow } from '../../../static/images/main_left_arrow.svg'
import { ReactComponent as RightArrow } from '../../../static/images/main_right_arrow.svg'
import { colorPallete } from '../../../config'
import fisherImg from '../../../static/images/fisher.png'
import { odonataImg1, odonataImg2, odonataImg3, odonataImg4, waveMain } from '../../../config/images'

const MainSection = React.forwardRef(({ title, title2, description, lines, subTitle, subDescription, link }, ref) => {
    const [index, setIndex] = useState(0)
    const [videoIdx, setVideoIdx] = useState(0)
    return (
        <S.Container ref={ref}>
            <S.TopWrapper>
                <S.LeftWrapper>
                    <LeftArrow onClick={() => { if (index === 0) { setIndex(2) } else { setIndex(index - 1) } }} style={{ cursor: 'pointer' }} stroke={colorPallete.primary} />
                </S.LeftWrapper>
                {index === 0 && (
                    <S.MainWrapper margin={true}>
                        <S.Lines>
                            {lines.map((line, index, array) => (<S.Line key={index} {...line} />))}
                        </S.Lines>
                        <S.TitleWrapper>
                            <S.Odonata>
                                <S.OdonataImg src={odonataImg2} />
                                <S.OdonataImg src={odonataImg3} />
                                <S.OdonataImg src={odonataImg4} />
                                <S.OdonataImg src={odonataImg1} />
                            </S.Odonata>
                            <Text {...title} />
                        </S.TitleWrapper>
                        <S.TitleWrapper2><Text {...title2} /></S.TitleWrapper2>
                        <S.DescriptionWrapper><Text {...description} /></S.DescriptionWrapper>
                    </S.MainWrapper>
                )}
                {index === 1 && (
                    <S.MainWrapper>
                        <S.SubMainWrapper>
                            <S.SubLeftWrapper>
                                <img src={fisherImg} style={{ width: '100%', maxWidth: '428px', margin: 'auto' }} alt='어촌계 사진' />
                            </S.SubLeftWrapper>
                            <S.SubRightWrapper>
                                <S.TitleWrapper><Text {...subTitle} /></S.TitleWrapper>
                                <S.DescriptionWrapper><Text {...subDescription} /></S.DescriptionWrapper>
                                <S.LinkWrapper>
                                    <Link {...link} />
                                </S.LinkWrapper>
                            </S.SubRightWrapper>
                        </S.SubMainWrapper>
                    </S.MainWrapper>
                )}
                {index === 2 && (
                    <S.MainWrapper>
                        {videoIdx === 0 && (
                            <S.VideoWrapper>
                                <iframe src="https://www.youtube.com/embed/jAvYoLDmkss" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen="allowfullscreen"
                                    mozallowfullscreen="mozallowfullscreen"
                                    msallowfullscreen="msallowfullscreen"
                                    oallowfullscreen="oallowfullscreen"
                                    webkitallowfullscreen="webkitallowfullscreen"></iframe>
                            </S.VideoWrapper>
                        )}
                        {videoIdx === 1 && (
                            <S.VideoWrapper>
                                <iframe src="https://www.youtube.com/embed/N9FgTq9cinY" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen="allowfullscreen"
                                    mozallowfullscreen="mozallowfullscreen"
                                    msallowfullscreen="msallowfullscreen"
                                    oallowfullscreen="oallowfullscreen"
                                    webkitallowfullscreen="webkitallowfullscreen"></iframe>
                            </S.VideoWrapper>
                        )}
                        <S.VideoStageWrapper>
                            <S.StageWrapper>
                                <S.Stage onClick={() => setVideoIdx(0)} selected={videoIdx === 0} />
                                <S.Stage onClick={() => setVideoIdx(1)} selected={videoIdx === 1} />
                            </S.StageWrapper>
                        </S.VideoStageWrapper>
                    </S.MainWrapper>
                )}
                <S.RightWrapper>
                    <RightArrow onClick={() => { if (index === 2) { setIndex(0) } else { setIndex(index + 1) } }} style={{ cursor: 'pointer' }} stroke={colorPallete.primary} />
                </S.RightWrapper>
            </S.TopWrapper>
            <S.StageWrapper>
                <S.Stage onClick={() => setIndex(0)} selected={index === 0} />
                <S.Stage onClick={() => setIndex(1)} selected={index === 1} />
                <S.Stage onClick={() => setIndex(2)} selected={index === 2} />
            </S.StageWrapper>
            <S.ScrollImg onClick={() => { window.scrollTo({ top: window.innerHeight, behavior: 'smooth' }) }}>
                <span />
                <div style={{ paddingBottom: '1em', color: 'white', fontWeight: 'bold' }}>Scroll</div>
            </S.ScrollImg >
            <S.WaveMain src={waveMain} />
        </S.Container>
    )
})

export default MainSection