import {
	desktopFontSize,
	mobileFontSize,
	fontWeight,
	colorPallete,
} from "../../config";
import { Icon } from "@iconify/react";
import { FiYoutube, FiInstagram, FiFacebook } from "react-icons/fi";

import { logoImg, slideOneImg, slideTwoImg } from "../../config/images";
const navTextProps = {
	main: {
		fontSize: {
			desktop: desktopFontSize.lg,
		},
		fontWeight: fontWeight.semiBold,
		color: colorPallete.display,
	},
	detail: {
		title: {
			fontSize: { desktop: desktopFontSize.xl4, mobile: mobileFontSize.xl3 },
			fontWeight: fontWeight.extraBold,
		},
		menu: {
			fontSize: { desktop: desktopFontSize.xl, mobile: mobileFontSize.lg },
			fontWeight: fontWeight.medium,
		},
	},
	info: {
		title: {
			fontSize: {
				desktop: desktopFontSize.base,
				mobile: mobileFontSize.sm,
			},
			fontWeight: fontWeight.bold,
			color: colorPallete.txt,
		},
		content: {
			fontSize: {
				desktop: desktopFontSize.base,
				mobile: mobileFontSize.sm,
			},
			fontWeight: fontWeight.medium,
			color: colorPallete.txt,
		},
	},
	button: {
		fontSize: {
			desktopFontSize: desktopFontSize.base,
			mobile: mobileFontSize.base,
		},
		color: colorPallete.head,
		fontWeight: fontWeight.bold,
	},
	link: {
		fontSize: {
			desktop: desktopFontSize.base,
			mobile: mobileFontSize.base,
		},
		fontWeight: fontWeight.medium,
		color: colorPallete.head,
	},
};

export const navProps = {
	logo: logoImg,
	mains: [
		{ ...navTextProps.main, children: "About", href: "/" },
		{ ...navTextProps.main, children: "Project", href: "/projects" },
		{
			...navTextProps.main,
			children: "Contact Us",
			href: "/#contact",
			key: "#contact",
		},
		{ ...navTextProps.main, children: " " },
	],
	details: [
		{
			title: { children: "About", ...navTextProps.detail.title },
			menus: [
				{
					children: "Intro",
					...navTextProps.detail.menu,
					href: "/#main",
					key: "#main",
				},
				{
					children: "Tech",
					...navTextProps.detail.menu,
					href: "/#tech",
					key: "#tech",
				},
				{
					children: "Business",
					...navTextProps.detail.menu,
					href: "/#business",
					key: "#business",
				},
				{
					children: "News",
					...navTextProps.detail.menu,
					href: "/#news",
					key: "#news",
				},
				{
					children: "Partners",
					...navTextProps.detail.menu,
					href: "/#partner",
					key: "#partner",
				},
			],
		},
		{
			title: { children: "Project", ...navTextProps.detail.title },
			menus: [
				{
					children: "Device",
					...navTextProps.detail.menu,
					href: "/projects#device",
					key: "device",
				},
				{
					children: "Service",
					...navTextProps.detail.menu,
					href: "/projects#service",
					key: "service",
				},
			],
		},
		{
			title: {
				children: "Contact Us",
				...navTextProps.detail.title,
				href: "/#contact",
				key: "#contact",
			},
			menus: [
				{
					...navTextProps.detail.menu,
					href: "/#contact",
					key: "#contact",
					children: "contact",
				},
				{
					...navTextProps.detail.menu,
					href: "https://www.facebook.com/odncorp",
					key: "device",
					lIcon: <FiFacebook />,
				},
				{
					...navTextProps.detail.menu,
					href: "https://www.instagram.com/odn_promo/",
					key: "service",
					lIcon: <FiInstagram />,
				},
				{
					...navTextProps.detail.menu,
					href: "https://www.youtube.com/channel/UCrAbOPPbTWnVEiFBlYLaN3g/featured",
					key: "service",
					lIcon: <FiYoutube />,
				},
			],
		},
	],
	links: [
		{ ...navTextProps.link, children: "서비스 이용약관" },
		{ ...navTextProps.link, children: "개인정보처리방침" },
	],
	info: [
		{
			title: { ...navTextProps.info.title, children: "Tel" },
			content: { ...navTextProps.info.content, children: "061-752-3655" },
		},
		{
			title: { ...navTextProps.info.title, children: "Fax" },
			content: { ...navTextProps.info.content, children: "061-752-3655" },
		},
		{
			title: { ...navTextProps.info.title, children: "E-mail" },
			content: { ...navTextProps.info.content, children: "odn_promo@odn.us" },
		},
	],
	buttons: [
		{
			LeftIcon: (
				<Icon
					color={colorPallete.cancel}
					style={{ marginRight: "0.5rem" }}
					icon="akar-icons:download"
				/>
			),
			text: { children: "회사소개서", ...navTextProps.button },
			onClick: () => console.log("click"),
			bgColor: "white",
			height: "58px",
		},
		{
			LeftIcon: (
				<Icon
					color={colorPallete.cancel}
					style={{ marginRight: "0.5rem" }}
					icon="bx:map"
				/>
			),
			text: { children: "오시는 길", ...navTextProps.button },
			onClick: () =>
				window.open(
					"https://www.google.com/maps/place/%EC%A3%BC%EC%8B%9D%ED%9A%8C%EC%82%AC+%EC%98%A4%EB%93%A0+(ODN)/data=!3m1!4b1!4m9!1m2!2m1!1z7Iic7LKcIOyYpOuToA!3m5!1s0x356e0bbdc73c2aed:0x608950a0f3829176!8m2!3d34.8976734!4d127.5796496!15sCg3siJzsspwg7Jik65OgkgEQc29mdHdhcmVfY29tcGFueeABAA",
				),
			bgColor: "white",
			height: "58px",
		},
	],
	images: [slideOneImg, slideTwoImg],
};
