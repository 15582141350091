import styled from "styled-components";

export const Container = styled.div`
    display:flex;
    width :100%;
    flex-direction : column;
`

export const TextWrapper = styled.div`
    margin-bottom : ${props => props.marginBottom ? props.marginBottom : '0.5rem'};
    width : 100%;
    text-align : left;
`

export const InputWrapper = styled.div`
    height : 100%;
` 