import React from "react";
import * as S from "./style";
import { Text, Link } from "../../atom";
import { Info } from "../Nav";
import { colorPallete } from "../../../config";

const Content = ({ content }) => {
  switch (content.type) {
    case "multiple":
      return (
        <S.TextWrapper>
          <Info info={content.items} margin="0.25rem 0" />
        </S.TextWrapper>
      );
    case "maker":
      return (
        <S.TextWrapper margin="0.25rem 0" className="maker">
          <S.Image src={content.image.src} alt={content.image.alt} />
          <Text {...content} />
        </S.TextWrapper>
      );

    default:
      return (
        <S.TextWrapper>
          <Text {...content} />
        </S.TextWrapper>
      );
  }
};

const Contents = ({ contents = [] }) => {
  return contents.map((content, index, array) => (
    <Content key={index} content={content} />
  ));
};

const Sections = ({ sections = [] }) => {
  return sections.map((section, index, array) => (
    <S.SectionWrapper key={index}>
      <Text {...section.title} />
      <Contents contents={section.contents} />
    </S.SectionWrapper>
  ));
};

export const FooterLink = ({ links = [], bgColor }) => {
  return links.map((link, index, array) => (
    <React.Fragment key={index}>
      <S.Link>
        <Link {...link} />
      </S.Link>
      {array.length !== index + 1 && <S.Sep bgColor={bgColor} />}
    </React.Fragment>
  ));
};

const Links = ({ links = [], copyright = [], maker = [] }) => {
  return (
    <S.SectionWrapper alignItems="end" margin="auto 0px auto auto">
      <Text></Text>
      <Contents contents={maker} />
      <S.LinkWrapper>
        <FooterLink links={links} bgColor={colorPallete.white} />
      </S.LinkWrapper>
      <Contents contents={copyright} />
    </S.SectionWrapper>
  );
};

const Footer = ({ logo, sections, links, copyright, maker }) => {
  return (
    <S.Container>
      <S.FooterWrapper>
        <S.LogoWrapper>
          <S.Logo src={logo.src} alt="로고이미지" />
          <Text {...logo.text} />
        </S.LogoWrapper>
        <Sections sections={sections} />
        <Links links={links} copyright={copyright} maker={maker} />
      </S.FooterWrapper>
    </S.Container>
  );
};

export default Footer;
