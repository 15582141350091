import styled from "styled-components";
import { viewSize } from "../../../config";

export const Container = styled.div`
	display: flex;
	flex-direction: column;
`;

export const TtitleWrapper = styled.div`
	margin-bottom: 3rem;
	white-space: pre-line;

	@media screen and (max-width: ${viewSize.mobile}) {
		margin-bottom: 1.25rem;
	}
`;

export const SubTitleWrapper = styled.div`
	display: flex;
	flex-wrap: wrap;

	@media screen and (max-width: ${viewSize.mobile}) {
		display: none;
	}
`;

export const SubTitleMobileWrapper = styled.div`
	display: none;

	@media screen and (max-width: ${viewSize.mobile}) {
		display: flex;
		flex-direction: column;
	}
`;

export const SubTitle = styled.div`
	width: 100%;
	margin: 0 auto 1.675rem 0;
	//	margin: 1.675rem auto 1.675rem 0;

	@media screen and (max-width: ${viewSize.mobile}) {
		width: 100%;
		margin: 0.5rem auto 0.5rem 0;
		display: flex;
	}
`;

export const DescriptionWrapper = styled.div`
	display: flex;
	flex-wrap: wrap;
	flex-direction: ${(props) => props.title && "column"};
`;

export const Descirption = styled.div`
	width: ${(props) => (props.title ? " 100% " : "50%")};
	margin: ${(props) => (props.title ? " 0.5rem 0px " : "0.5rem auto")};
`;

export const ContentWrapper = styled.div`
	margin-bottom: 0.25rem;
`;

export const SubContentWrapper = styled.div``;
