import React, { useState } from "react";
import ImageViewer from "react-simple-image-viewer";
import { Text } from "../../atom";
import * as S from "./style";

const SiteMapSection = ({ iconsProps, productImages, companyImages }) => {
  const [isViewerOpen, setIsViewerOpen] = useState({
    company: false,
    product: false,
  });

  iconsProps.items.map((res) => {
    if (res.children === "회사소개서")
      res.onClick = () => setIsViewerOpen({ company: true });
    if (res.children === "제품소개서")
      res.onClick = () => setIsViewerOpen({ product: true });

    return null;
  });

  return (
    <S.IconContainer>
      {iconsProps.items.map((res, index) => (
        <S.Anchor onClick={res.onClick} key={index}>
          <S.IconImg src={res.src} alt="로고 이미지" />
          <Text children={res.children} {...iconsProps.textStyle} />
        </S.Anchor>
      ))}

      {(isViewerOpen.product || isViewerOpen.company) && (
        <ImageViewer
          backgroundStyle={{
            zIndex: 100000000000,
            backgroundColor: "#000000ca",
          }}
          src={isViewerOpen.product ? productImages : companyImages}
          currentIndex={0}
          disableScroll={false}
          closeOnClickOutside={true}
          onClose={() => setIsViewerOpen(false)}
        />
      )}
    </S.IconContainer>
  );
};

export default SiteMapSection;
