import styled from 'styled-components'
import { colorPallete, viewSize } from '../../../config'

export const Container = styled.div`
    width : 100%;
    height : ${props => props.height ? props.height.desktop : '20rem'};
    background-color : ${props => props.bgColor ? props.bgColor : colorPallete.secondary};
    margin :${props => props.margin ? props.margin.desktop : '0'};

    @media screen and (max-width : ${viewSize.tablet}){
        height : ${props => props.height ? props.height.tablet : '20rem'};
        margin :${props => props.margin ? props.margin.tablet : '0'};
    }

    @media screen and (max-width : ${viewSize.mobile}){
        height : ${props => props.height ? props.height.mobile : '20rem'};
        margin :${props => props.margin ? props.margin.mobile : '0'};
    }
`
