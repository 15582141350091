import styled from "styled-components";
import { defaultSize, viewSize } from "../../../config";

export const Container = styled.div`
  display: flex;
  position: relative;
  width: 95%;
  flex-direction: column;
  padding: 5rem 0 10rem 0;
  max-width: ${defaultSize.desktop.container};
  margin: auto;
  height: 600vh;
  @media screen and (max-width: ${viewSize.mobile}) {
    padding: 0.2rem 0;
  }
`;
