import React from "react";
import * as S from "./style";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import { SectionTitle, NewsCard } from "../../organism";
import { ReactComponent as LeftArrow } from "../../../static/images/leftArrow.svg";
import { ReactComponent as RightArrow } from "../../../static/images/rightArrow.svg";
import { IconButton } from "../../atom";

const NewsSection = React.forwardRef(({ sectionTitle, news = [] }, ref) => {
  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    className: "center",
    arrows: false,
    centerPadding: "50px",
    variableHeight: true,
    centerMode: true,
    responsive: [
      {
        breakpoint: 1400,
        settings: {
          slidesToShow: 3,
          infinite: true,
          centerMode: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          infinite: true,
          centerMode: false,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          infinite: true,
          centerMode: false,
        },
      },
    ],
  };

  const sliderRef = React.useRef(null);

  const next = () => {
    sliderRef.current.slickNext();
  };

  const prev = () => {
    sliderRef.current.slickPrev();
  };
  return (
    <S.Container ref={ref}>
      <S.Wrapper>
        <S.TitleWrapper>
          <SectionTitle {...sectionTitle} />
        </S.TitleWrapper>
        <S.ButtonWrapper>
          <IconButton onClick={prev} icon={<LeftArrow />}></IconButton>
          <IconButton onClick={next} icon={<RightArrow />}></IconButton>
        </S.ButtonWrapper>
        <S.NewsWrapper {...settings} ref={sliderRef}>
          {news.map((item, index, array) => (
            <S.CardWrapper key={index}>
              <NewsCard {...item} />
            </S.CardWrapper>
          ))}
        </S.NewsWrapper>
      </S.Wrapper>
    </S.Container>
  );
});

export default NewsSection;
