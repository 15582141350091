import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import reportWebVitals from "./reportWebVitals";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import { RecoilRoot } from "recoil";
import MainPage from "./pages/main";
import Nav from "./pages/nav";
import Footer from "./pages/footer";
import Project from "./pages/project";
import { PrivacyPage } from "./pages";
import SNS from "./pages/etc/sns";

const router = createBrowserRouter([
  {
    path: "/",
    element: <MainPage />,
  },
  {
    path: "/projects",
    element: <Project />,
  },
  {
    path: "/privacy",
    element: <PrivacyPage />,
  },
  {
    path: "/sns",
    element: <SNS />,
  },
]);

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <RecoilRoot>
    <Nav />
    <RouterProvider router={router} />
    <Footer />
  </RecoilRoot>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
