import styled from "styled-components";
import { desktopFontSize, mobileFontSize, viewSize } from "../../../config";

//dl dt dd
export const DescriptionsWrapper = styled.ul`
  padding: 0.5rem;
  @media screen and (max-width: ${viewSize.mobile}) {
    padding: 0.2rem;
  }
`;
export const Title = styled.li`
  list-style-type: none;
  padding-bottom: 0.5rem;
  @media screen and (max-width: ${viewSize.mobile}) {
    padding-bottom: 0.3rem;
  }
`;
export const Descriptions = styled.ol`
  list-style-type: upper-roman;
  padding-left: ${(props) =>
    props.paddingLeft ? props.paddingLeft.mobile : "1rem"};
  &.list {
    padding-left: ${(props) =>
      props.paddingLeft ? props.paddingLeft.mobile : "2.2rem"};
  }
  > span {
    line-height: 1.5rem;
  }
  @media screen and (max-width: ${viewSize.mobile}) {
    padding-left: ${(props) =>
      props.paddingLeft ? props.paddingLeft.mobile : "0.5rem"};
    &.list {
      padding-left: ${(props) =>
        props.paddingLeft ? props.paddingLeft.mobile : "1.2rem"};
    }
    > span {
      line-height: 1rem;
    }
  }
`;
export const Description = styled.li`
  &::marker {
    font-size: ${desktopFontSize.lg};
  }
  > span {
    line-height: 1.5rem;
  }
  @media screen and (max-width: ${viewSize.mobile}) {
    &::marker {
      font-size: ${mobileFontSize.lg};
    }
    > span {
      line-height: 1rem;
    }
  }
`;
export const InnerOlist = styled.ol`
  list-style-type: ${(props) => (props.listType ? props.listType : "decimal")};
  padding-left: ${(props) =>
    props.paddingLeft ? props.paddingLeft.desktop : "2.2rem"};
  &.innerList {
    padding-left: 1.2rem;
  }
  @media screen and (max-width: ${viewSize.mobile}) {
    padding-left: ${(props) =>
      props.paddingLeft ? props.paddingLeft.mobile : "1.5rem"};
    &.innerList {
      padding-left: 0.9rem;
    }
    &::marker {
      font-size: ${mobileFontSize.lg};
    }
  }
`;
export const InnerUlist = styled.ul`
  list-style-type: ${(props) => (props.listType ? props.listType : "disc")};
  padding-left: ${(props) =>
    props.paddingLeft ? props.paddingLeft.desktop : "1.5rem"};
  @media screen and (max-width: ${viewSize.mobile}) {
    padding-left: ${(props) =>
      props.paddingLeft ? props.paddingLeft.mobile : "1.1rem"};
  }
`;
export const InnerItem = styled.li`
  &::marker {
    font-size: ${desktopFontSize.lg};
  }
  > span {
    line-height: 1.5rem;
  }
  @media screen and (max-width: ${viewSize.mobile}) {
    &::marker {
      font-size: ${mobileFontSize.lg};
    }
    > span {
      line-height: 1rem;
    }
  }
`;
