import { css, keyframes } from "styled-components";

export const origin = keyframes`
0% {
transform: translateX(110%);
}
100% {
  transform: translateX(-250%);
}
`;
export const originPartnerAnimation = css`
	${origin} infinite running linear;
`;
export const reverse = keyframes`
0% {
transform: translateX(250%);
}
100% {
  transform: translateX(-100%);
}
`;
export const reversePartnerAnimation = css`
	${reverse} infinite running linear reverse;
`;
