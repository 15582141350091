import {
	dxImg,
	LIcon,
	iotImg,
	blockImg,
	bigImg,
	aiImg,
} from "../../config/images";
import {
	mobileFontSize,
	colorPallete,
	fontWeight,
	desktopFontSize,
} from "../../config";
import { sectionProps } from "../common";


const props = {
	sectionTitle: {
		title: {
			...sectionProps.title,
			children: "TECH",
			color: colorPallete.white,
		},
		description: {
			...sectionProps.description,
			children: "오든의 기술로 디지털 전환을 실현합니다.",
			color: colorPallete.white,
		},
	},
	link: {
		color: colorPallete.white,
		children: "rest API 바로가기",
		href: "https://api.odn-it.com",
		fontWeight: fontWeight.bold,
		fontSize: { desktop: desktopFontSize.base, mobile: mobileFontSize.base },
		bgColor: colorPallete.black,
		hoverBgColor: colorPallete.white,
		hoverColor: colorPallete.black,
		radius: "3rem",
		padding: "0.75rem 1.5rem",
		target: "_blank",
		lIcon: <LIcon style={{ marginRight: "0.5rem" }} />,
	},
	images: {
		main: {
			bottom: {
				children: "DX",
				fontWeight: fontWeight.bold,
				fontSize: { desktop: desktopFontSize.xl3, mobile: mobileFontSize.sm },
				color: colorPallete.head,
			},
			image: { src: dxImg, alt: "dx이미지" },
		},
		left: [
			{
				bottom: {
					children: "IOT",
					fontWeight: fontWeight.bold,
					fontSize: {
						desktop: desktopFontSize.xl,
						mobile: mobileFontSize.sm,
					},
					color: colorPallete.head,
				},
				image: { src: iotImg, alt: "iot이미지" },
			},
			{
				bottom: {
					children: "BLOCK CHAIN",
					fontWeight: fontWeight.bold,
					fontSize: {
						desktop: desktopFontSize.xl,
						mobile: mobileFontSize.sm,
					},
					color: colorPallete.head,
				},
				image: { src: blockImg, alt: "block이미지" },
			},
		],
		right: [
			{
				bottom: {
					children: "BIG DATA",
					fontWeight: fontWeight.bold,
					fontSize: {
						desktop: desktopFontSize.xl,
						mobile: mobileFontSize.sm,
					},
					color: colorPallete.head,
				},
				image: { src: bigImg, alt: "bigData이미지" },
			},
			{
				bottom: {
					children: "AI",
					fontWeight: fontWeight.bold,
					fontSize: {
						desktop: desktopFontSize.xl,
						mobile: mobileFontSize.sm,
					},
					color: colorPallete.head,
				},
				image: { src: aiImg, alt: "ai이미지" },
			},
		],
	},
};

export default props;
