import styled from "styled-components";
import breadcrumbBg from "../../../static/images/breadcrumb_bg.png";
import { viewSize } from "../../../config";

export const Container = styled.div`
	width: 100%;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	background-image: url(${breadcrumbBg});
	background-size: contain;
	background-repeat-y: no-repeat;
	padding: 15rem 0 10rem 0;
	height: 10rem;
	margin-bottom: -10rem;
	z-index: -2;
	@media screen and (max-width: ${viewSize.mobile}) {
		margin-bottom: -10rem;
		background-size: cover;
	}
`;

export const CurrentWrapper = styled.div`
	z-index: 1;
`;

export const BreadcrumbWrapper = styled.div`
	display: flex;
	margin-top: 1rem;
	align-items: center;
	z-index: 1;
`;

export const BreadcrumbItem = styled.div`
	margin: auto 0.25rem;
`;
