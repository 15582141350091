import React from "react";
import { LinkImg } from "../../atom";
import * as S from "./style";

const ImgCard = ({ image, bgColor, width, height, imageWidth, href }) => {
	const imgCardProps = {
		bgColor,
		width,
		height,
		href,
	};

	const linkImgProps = {
		image,
		imageWidth,
		href,
	};
	return (
		<S.Container {...imgCardProps}>
			<LinkImg {...linkImgProps} />
		</S.Container>
	);
};

export default ImgCard;
