import React from 'react'
import * as S from './style'
import { Input, Text, TextArea } from '../../atom/'
import { desktopFontSize, mobileFontSize, fontWeight } from '../../../config'

const defaultFontSize = {
    desktop: desktopFontSize.lg,
    mobile: mobileFontSize.base
}

const InputForm = ({ text, input, textarea }) => {
    text.fontSize = text.fontSize ? text.fontSize : defaultFontSize
    text.fontWeight = text.fontWeight ? text.fontWeight : fontWeight.semiBold

    return (
        <S.Container>
            <S.TextWrapper>
                <Text {...text} />
            </S.TextWrapper>
            <S.InputWrapper>
                {textarea ? (<TextArea {...textarea} />) : (<Input {...input} />)}
            </S.InputWrapper>
        </S.Container>
    )
}

export default InputForm