import { keyframes } from "styled-components";


export const TitleIntro = keyframes`
    0% {
        top:50px;
        opacity: 0;
    } 

    70%{
        top:5px;
        opacity: 0.6;
    }
    100% {
        top:0%;
        opacity: 1;
        }
`

export const ColorLineIntro = keyframes`
    from {opacity: 0}
    to {opacity: 1}
`

export const PopIn = keyframes`

    25% {transform: translateY(10px);}

    50% {transform: translateY(20px);}
    
    0%,100% {transform: translateY(0);}
`

export const OdonataOpening = keyframes`
    from {opacity: 0}
    to {opacity: 1}
`

export const OdonataAni1 = keyframes`
    0% {opacity: 1}
    
    25% {opacity: 0}
    
    50% {opacity: 0}
    
    75% {opacity: 0}
    
    100%{opacity: 1}
`

export const OdonataAni2 = keyframes`
    0% {opacity: 0}
    
    25% {opacity: 1}
    
    50% {opacity: 0}
    
    75% {opacity: 0}
    
    100%{opacity: 0}
`
export const OdonataAni3 = keyframes`
    0% {opacity: 0}

    25% {opacity: 0}

    50% {opacity: 1}

    75%{opacity: 0}

    100%{opacity: 0}
`
export const OdonataAni4 = keyframes`
    0% {opacity: 0}

    25% {opacity: 0}

    50% {opacity: 0}

    75% {opacity: 1}

    100% {opacity: 0}
`

export const SeaWave = keyframes`
    from{transform: translateX(0) scale(1, 1);}

    25%{transform: translateX(5%) scale(1.1, 1);}

    75%{transform: translateX(-5%) scale(1.1, 1);}

    to{transform: translateX(0%) scale(1, 1);}
`


export const ScrollIcon = keyframes`
    0% {
      -webkit-transform: translate(0, 0);
      opacity: 0;
    }
    40% {
      opacity: 1;
    }
    80% {
      -webkit-transform: translate(0, 20px);
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
`