import { keyframes, css } from "styled-components";

export const Rotate = keyframes`
0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
`;
export const BottomAnimation = css`
	${Rotate} 5s 0.5s linear infinite;
`;
export const MiddleAnimation = css`
	${Rotate} 4s 0.5s linear infinite;
`;

export const TopRotate = keyframes`
0% {
    transform: rotate(360deg);
  }

  100% {
    transform: rotate(0deg);
  }
`;
export const TopAnimation = css`
	${TopRotate} 10s 0.5s linear infinite;
`;
