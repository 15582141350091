import React from "react";
import { Text } from "../../atom";
import * as S from "./style";
export const GerenateUl = ({
  ulists = [],
  className,
  listType,
  paddingLeft,
}) => {
  const props = {
    listType,
    paddingLeft,
  };
  return (
    <S.InnerUlist className={className} {...props}>
      {ulists.map((list, index) => (
        <S.InnerItem>
          <Text {...list} />
        </S.InnerItem>
      ))}
    </S.InnerUlist>
  );
};
export const GerenateOl = ({
  lists = [],
  className,
  listType,
  paddingLeft,
}) => {
  const props = {
    listType,
    paddingLeft,
  };
  console.log(props);
  return (
    <S.InnerOlist className={className} {...props}>
      {lists.map((list, index) =>
        list.ulists ? (
          <S.InnerItem>
            <Text {...list} />
            <GerenateUl ulists={list.ulists} />
          </S.InnerItem>
        ) : (
          <S.InnerItem>
            <Text {...list} />
          </S.InnerItem>
        )
      )}
    </S.InnerOlist>
  );
};
const GerenateText = ({ descriptions = [] }) => {
  if (descriptions.length === 1) {
    return (
      <S.Descriptions>
        {descriptions.map((description, index) => (
          <React.Fragment>
            {description.text && <Text {...description.text} />}
            {description.lists && (
              <GerenateOl lists={description.lists} {...description} />
            )}
            {description.ulists && <GerenateUl ulists={description.ulists} />}
          </React.Fragment>
        ))}
      </S.Descriptions>
    );
  }
  return (
    <S.Descriptions className="list">
      {descriptions.map((description, index) => (
        <S.Description>
          {description.text && <Text {...description.text} />}
          {description.lists && (
            <GerenateOl lists={description.lists} {...description} />
          )}
          {description.ulists && <GerenateUl ulists={description.ulists} />}
        </S.Description>
      ))}
    </S.Descriptions>
  );
};
const TextForm = ({ title, descriptions }) => {
  return (
    <S.DescriptionsWrapper>
      <S.Title>
        <Text {...title} />
      </S.Title>
      <GerenateText descriptions={descriptions} />
    </S.DescriptionsWrapper>
  );
};

export default TextForm;
