import React from 'react'
import * as S from './style'
import { Text } from '../../atom'
import { colorPallete, desktopFontSize, fontWeight } from '../../../config'

const SectionTitle = ({ title, description, alignItem }) => {
    title = {
        fontWeight: fontWeight.extraBold,
        fontSize: { desktop: desktopFontSize.xl5 },
        color: colorPallete.white,
        ...title,
    }

    description = {
        fontWeight: fontWeight.medium,
        fontSize: { desktop: desktopFontSize.lg },
        color: colorPallete.white,
        ...description,
    }

    return (
        <S.Container alignItem={alignItem}>
            <S.TitleWrapper>
                <Text {...title} />
            </S.TitleWrapper>
            <S.DesriptionWrapper>
                <Text {...description} />
            </S.DesriptionWrapper>
        </S.Container>
    )
}

export default SectionTitle