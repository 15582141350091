import styled from 'styled-components'
import { viewSize, defaultSize, colorPallete, desktopFontSize, mobileFontSize, fontWeight } from '../../../config'


export const Button = styled.button`
    width : 100%;
    height : ${props => props.height ? props.height : defaultSize.desktop.buttonHeight};
    background-color : ${props => props.bgColor ? props.bgColor : colorPallete.primary};
    display : flex;
    align-items : center;
    justify-content : center;
    border : ${props => props.border ? props.border : `1px solid ${colorPallete.cancel}`}};
    color : ${props => props.color ? props.color : colorPallete.head};
    font-size : ${props => props.fontSize ? props.fontSize.desktop : desktopFontSize.base};
    font-weight : ${props => props.fontWeight ? props.fontWeight : fontWeight.normal};
    cursor : pointer;

    @media screen and (max-width : ${viewSize.mobile}){
        height : ${props => props.height ? props.height : defaultSize.mobile.buttonHeight};
        font-size : ${props => props.fontSize ? props.fontSize.mobile : mobileFontSize.base};
    }
`