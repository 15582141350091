import React from "react";
import Link from "../Link";
import * as S from "./style";

const LinkImg = ({ image, href, width, imageWidth }) => {
	const linkProps = {
		href,
		target: "_blank",
		rel: "noopener noreferrer",
		width,
	};
	const imgProps = {
		imageWidth,
	};
	return (
		<Link {...linkProps}>
			<S.Img {...image} {...imgProps} />
		</Link>
	);
};

export default LinkImg;
