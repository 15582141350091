import React from 'react'
import { footerProps } from './data'
import { Footer as FooterTemplate } from '../../components/template'


const Footer = () => {
    return (
        <FooterTemplate {...footerProps} />
    )
}

export default Footer