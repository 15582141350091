import styled from 'styled-components'
import { colorPallete } from '../../../config'
import { buoyObjRotateAnimation } from '../../../animation/business'
export const Container = styled.div`
    display : flex;
    flex-direction : column;
    align-items : center;
    justify-content : start;
    background-size : cover;
    background-position-y : bottom;
    background-repeat-y : no-repeat;
    background-repeat-x : no-repeat;
    width : 100%;
    background: linear-gradient(rgb(214, 238, 254), rgb(255, 255, 255));
`

export const TitleWrapper = styled.div`
    margin : 5rem auto ;
`

export const DesktopImgWrapper = styled.div`
    position : relative;
    width : 100%;
    display : flex;
    justify-content : center;
    height : 1000px;
    margin-top : 200px;
    @media screen and (max-width : 1350px){
        display : none;
    }
`

export const TabletImgWrapper = styled.div`
    position : relative;
    width : 100%;
    display : flex;
    justify-content : center;
    height : 500px;
    margin-top : 100px;

    display : none;
    @media screen and (min-width : 950px) and (max-width : 1350px){
        display : flex;
    }
`

export const MobileImgWrapper = styled.div`
    position : relative;
    width : 100%;
    display : flex;
    justify-content : center;
    height : 500px;
    display : none;
    @media screen and (min-width : 680px) and (max-width : 950px){
        display : flex;
    }
`

export const SmallMobileImgWrapper = styled.div`
    position : relative;
    width : 100%;
    display : none;
    justify-content : center;
    height : 500px;
    margin-top : 200px;

    @media screen and (max-width : 680px){
        display : flex;
    }
`

export const MainImg = styled.img`
    max-width : ${props => props.maxWidth && props.maxWidth};
    width : ${props => props.width && props.width};
    margin : ${props => props.margin && props.margin};
    
    z-index : 999;
`

export const BuoyLink = styled.a`
    display: black;
    &>img{
        transition:0.5s linear;
        &:hover{
            animation-play-state:paused;
        } 
        /* :hover{
            width: 150px; 
        } */
    }   
`

export const FocusObj = styled.span`
        position: absolute;
        border-radius: 50%;
        width: 12%;
        padding-bottom: 12%;
        border: 1.5px solid white;
        left: -5.5%;
        top : 15%;
        z-index : 9;
        background-color: rgb(97, 183, 245, 0.2);
        animation: ${buoyObjRotateAnimation} ;
        &:hover{
            animation-play-state:paused;
        }
        &>span{
            position: absolute;
            border-radius: 50%;
            width: 10%;
            height: 10%;
            top: 42%;
            right: -5%;
            background-color: ${colorPallete.primary}; 
        }

        @media screen and (min-width : 950px) and (max-width : 1350px){
            width : 12%;
            padding-bottom: 12%;
            left: -5.5%;
            top : 20%;
        }
        @media screen and (max-width : 680px){
            width : 25%;
            padding-bottom: 25%;
            left: 4%;
            top : 6%;
        }
        @media screen and (max-width : 680px){
            width : 25%;
            padding-bottom: 25%;
            left: 4%;
            top : 6%;
        }

`