import React from 'react'
import * as S from './style'
import { Lines, Line } from '../MainSection/style'
import { Text } from '../../atom'
import { MdHomeFilled, MdKeyboardArrowRight } from 'react-icons/md'
import { colorPallete } from '../../../config'

const Breadcrumb = ({ current, breadcrumbs, lines }) => {
    return (
        <S.Container>
            <Lines>
                {lines.map((line, index, array) => (
                    <Line key={index} {...line} />
                ))}
            </Lines>
            <S.CurrentWrapper>
                <Text {...current} />
            </S.CurrentWrapper>
            <S.BreadcrumbWrapper>
                <MdHomeFilled size='18px' color={colorPallete.head} />
                {breadcrumbs.map((breadcrumb, index, array) => (
                    <React.Fragment key={index}>
                        <S.BreadcrumbItem key={index}><Text {...breadcrumb} /></S.BreadcrumbItem>
                        {breadcrumbs.length - 1 !== index && (<MdKeyboardArrowRight size="20px" color={colorPallete.txt} />)}
                    </React.Fragment>
                ))}
            </S.BreadcrumbWrapper>
        </S.Container>
    )
}

export default Breadcrumb