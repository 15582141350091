import styled from "styled-components";
import { colorPallete, viewSize, defaultSize } from "../../../config";
import breadcrumbBg from "../../../static/images/breadcrumb_bg.png";
import {
	ColorLineIntro,
	TitleIntro,
	PopIn,
	OdonataAni1,
	OdonataAni2,
	OdonataAni3,
	OdonataAni4,
	SeaWave,
	OdonataOpening,
	ScrollIcon,
} from "../../../animation/intro";

export const Container = styled.div`
	position: relative;
	display: flex;
	align-items: center;
	justify-content: end;
	flex-direction: column;
	width: 100%;
	background-image: url(${breadcrumbBg});
	background-size: contain;
	background-repeat-y: no-repeat;
	height: 100vh;
	overflow-x: clip;
	@media screen and (max-width: ${viewSize.mobile}) {
		background-size: cover;
	}
`;

export const LeftWrapper = styled.div`
	display: flex;
	align-items: center;
	width: 10%;
	justify-content: center;

	@media screen and (max-width: ${viewSize.tablet}) {
		margin-top: 16em;
	}
`;

export const RightWrapper = styled.div`
	display: flex;
	align-items: center;
	width: 10%;
	justify-content: center;

	@media screen and (max-width: ${viewSize.tablet}) {
		margin-top: 16em;
	}
`;

export const MainWrapper = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	text-align: center;
	width: 80%;
`;

export const TitleWrapper = styled.div`
	/* margin-bottom : 1.5rem; */
	position: relative;
	white-space: pre-line;
	> span {
		display: inline;
		position: relative;
		animation-name: ${TitleIntro};
		animation-duration: 0.5s;
		animation-iteration-count: 1;
		animation-timing-function: ease;
		animation-delay: 0.5s;
		animation-fill-mode: backwards;
	}

	@media screen and (max-width: ${viewSize.mobile}) {
		> span {
			white-space: normal;
		}
	}
`;

export const TitleWrapper2 = styled(TitleWrapper)`
	position: none;
	margin-bottom: 1.5rem;
	> span {
		animation-delay: 0.7s;
	}
`;

export const DescriptionWrapper = styled(TitleWrapper)`
	position: none;
	> span {
		animation-delay: 1.2s;
	}
`;

export const Lines = styled.div`
	display: flex;
	margin-bottom: 1.25rem;
	animation-name: ${ColorLineIntro};
	animation-duration: 0.5s;
	animation-delay: 2s;
	animation-fill-mode: backwards;
`;

export const Line = styled.div`
	background-color: ${(props) => props.bgColor && props.bgColor};
	width: ${(props) => (props.width ? props.width : "1.75rem")};
	height: ${(props) => (props.height ? props.height : "0.5rem")};
`;

export const TopWrapper = styled.div`
	display: flex;
	width: 100%;
	max-width: 1364px;
	height: 65%;
`;

export const StageWrapper = styled.div`
	display: flex;
	height: 10%;
	z-index: 1;
`;

export const ScrollImg = styled.a`
	padding-top: 60px;
	cursor: pointer;
	position: relative;
	z-index: 1;
	& > span {
		position: absolute;
		top: 0;
		left: 50%;
		width: 30px;
		height: 50px;
		margin-left: -15px;
		border: 2px solid #fff;
		border-radius: 50px;
		box-sizing: border-box;
	}
	& > span::before {
		position: absolute;
		top: 10px;
		left: 50%;
		content: "";
		width: 6px;
		height: 6px;
		margin-left: -3px;
		background-color: #fff;
		border-radius: 100%;
		/* -webkit-animation: ${ScrollIcon} 2s infinite; */
		animation: ${ScrollIcon} 2s infinite;
		box-sizing: border-box;
	}
`;

export const Stage = styled.div`
	width: ${(props) => (props.selected ? "0.75rem" : "2rem")};
	height: ${(props) => (props.selected ? "0.75rem" : "0.75rem")};
	background-color: ${(props) =>
		props.selected ? colorPallete.secondary : colorPallete.line};
	border-radius: 1.25rem;
	margin: auto 0.25rem;
	cursor: pointer;
`;

export const SubMainWrapper = styled.div`
	display: flex;
	text-align: left;
	justify-content: space-around;
	width: 80%;
	@media screen and (max-width: ${viewSize.tablet}) {
		flex-direction: column;
	}
`;

export const SubLeftWrapper = styled.div`
	width: 48%;
	margin: auto;
	display: flex;
	justify-content: end;

	@media screen and (max-width: ${viewSize.tablet}) {
		width: 100%;
	}
`;

export const SubRightWrapper = styled.div`
	width: 48%;
	margin: auto;
	text-align: center;
	@media screen and (max-width: ${viewSize.tablet}) {
		width: 100%;
	}
`;

export const LinkWrapper = styled.div`
	width: 12rem;
	height: 2.75rem;
	margin: 2rem auto 0 auto;
`;

export const Odonata = styled.div`
	position: absolute;
	left: -20%;
	top: -200%;
	opacity: 0;
	animation-name: ${PopIn}, ${OdonataOpening};
	animation-delay: 2s;
	animation-duration: 2.5s, 0.5s;
	animation-iteration-count: infinite, 1;
	animation-timing-function: linear;
	animation-fill-mode: forwards;
	& > img {
		:first-child {
			animation: ${OdonataAni2} 3s ease-in-out infinite;
		}
		:nth-child(2) {
			animation: ${OdonataAni3} 3s ease-in-out infinite;
		}
		:nth-child(3) {
			animation: ${OdonataAni4} 3s ease-in-out infinite;
		}
		:last-child {
			animation: ${OdonataAni1} 3s ease-in-out infinite;
		}
	}

	@media screen and (max-width: ${viewSize.mobile}) {
		left: 21%;
		top: -600%;
	}

	@media screen and (max-width: 433px) {
		left: 18%;
		top: -300%;
	}
`;

export const OdonataImg = styled.img`
	position: absolute;
	@media screen and (max-width: ${viewSize.tablet}) {
		transform: scale(0.6, 0.6);
	}
	@media screen and (max-width: ${viewSize.mobile}) {
		transform: scale(0.3, 0.3);
	}
`;

export const WaveMain = styled.img`
	position: absolute;
	width: 100%;
	min-width: ${defaultSize.desktop.container};
	top: 75%;
	animation: ${SeaWave} 5s linear infinite;
`;

export const VideoWrapper = styled.div`
	position: relative;
	padding-bottom: 56.25%;
	padding-top: 30px;
	height: 0;
	overflow: hidden;
	width: 80%;

	& > iframe {
		position: absolute;
		top: 0;
		left: 10%;
		width: 80%;
		height: 80%;
	}

	@media screen and (max-width: ${viewSize.tablet}) {
		width: 100%;

		& > iframe {
			position: absolute;
			top: 0;
			left: 5%;
			width: 90%;
			height: 90%;
		}
	}

	@media screen and (max-width: ${viewSize.mobile}) {
		width: 100%;

		& > iframe {
			position: absolute;
			top: 0;
			left: 0%;
			width: 100%;
			height: 100%;
		}
	}
`;

export const VideoStageWrapper = styled.div`
	margin-top: -10%;
	z-index: 10;

	@media screen and (max-width: ${viewSize.tablet}){
		margin-top: 1rem;
	}
`