import styled from "styled-components";
import { defaultSize, colorPallete, viewSize } from "../../../config";


export const IconButton = styled.button`
    position : ${props => props.position ? props.position : 'static'};
    ${props => props.top && `top : ${props.top}`};
    ${props => props.bottom && `bottom : ${props.bottom}`};
    ${props => props.right && `right : ${props.right}`};
    ${props => props.left && `left : ${props.left}`};
    ${props => props.zIndex && `z-index : ${props.zIndex}`};
    width : ${props => props.width ? props.width.desktop : defaultSize.desktop.iconButtonWidth};
    height: ${props => props.height ? props.height.desktop : defaultSize.desktop.iconButtonHeight};
    border-radius : ${props => props.radius ? props.raidus : '100%'};
    border : ${props => props.border ? props.border : `1px solid ${colorPallete.subtxt}`};
    background-color : ${props => props.bgColor ? props.bgColor : 'transparent'};
    display : flex; 
    flex-direction : column;
    align-items : center;
    justify-content : center;
    z-index: 1;
    cursor : pointer;

    @media screen and (max-width : ${viewSize.mobile}){
        width : ${props => props.width ? props.width.mobile : defaultSize.mobile.iconButtonWidth};
        height: ${props => props.height ? props.height.mobile : defaultSize.mobile.iconButtonHeight};
    }
`

export const TextWrapper = styled.div`
    margin : 0.25rem 0px;
`


