import styled from "styled-components";
import { viewSize } from "../../../config";
import Slider from "react-slick";

export const Container = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	background-size: cover;
	background-repeat: no-repeat;
	position: relative;
	height: 50vh;
	padding-bottom: 17rem;
	background-color: #005a97;

	@media screen and (max-width: ${viewSize.tablet}) {
		padding-top: 0.5rem;
		padding-bottom: 7rem;
	}

	@media screen and (max-width: ${viewSize.mobile}) {
		padding-bottom: 2.7rem;
		padding-top: 1rem;
	}
`;

export const Wrapper = styled.div`
	position: relative;
	max-width: 1364px;
	margin-top: 5rem;
	width: 100%;
	display: flex;
	flex-direction: column;
`;

export const ButtonWrapper = styled.div`
	position: absolute;
	display: flex;
	top: 10%;
	right: 10%;
	width: 50%;
	max-width: 10rem;
	justify-content: space-between;

	@media screen and (max-width: ${viewSize.tablet}) {
		position: static;
		margin: 0 auto 1rem auto;
		justify-content: space-evenly;

		> button {
			width: 2.25rem;
			height: 2.25rem;
		}
	}

	@media screen and (max-width: ${viewSize.mobile}) {
		position: static;
		margin: 0 auto 0 auto;
		justify-content: space-evenly;

		> button {
			width: 2.25rem;
			height: 2.25rem;
		}
	}
`;

export const TitleWrapper = styled.div`
	margin: 3rem auto auto auto;

	@media screen and (max-width: ${viewSize.tablet}) {
		margin: 3rem auto 1rem auto;
	}

	@media screen and (max-width: ${viewSize.mobile}) {
		margin: 0px auto 0.5rem auto;
	}
`;

export const CardWrapper = styled.div``;

export const NewsWrapper = styled(Slider)`
	width: 100%;
	margin: 1rem auto;

	.slick-track {
		display: flex;
		align-items: center;
	}

	.slick-slide {
		display: flex;
		align-items: center;
		justify-content: center;
		margin: auto 1rem;
	}

	.slick-slide > div > div > div {
		transform: scale(0.8);
	}

	.slick-center > div > div > div {
		transform: scale(1);
	}

	@media screen and (max-width: ${viewSize.mobile}) {
		padding-bottom: 5rem;
	}
`;
