import React, { useState, useEffect } from "react";
import props, { breadcrumbProps, waveProps } from "./data";
import * as S from "../../components/template/ProjectsSection/style";
import { ProjectsSection, Breadcrumb } from "../../components/template";
import { Wave } from "../../components/atom";
import { useRecoilValue } from "recoil";
import { navGetState } from "../../recoils/selectors";
import wave_project from "../../static/images/wave_main.png";

const Project = () => {
	const [conf, setConf] = useState({
		serviceIdx: 0,
		deviceIdx: 0,
		category: "device",
	});
	const [isFirst, setIsFirst] = useState(true)
	const currentPage = useRecoilValue(navGetState);

	useEffect(() => {
		let category = window.location.hash.split("#")[1];
		if (category && isFirst) {
			setConf({ ...conf, category: category });
			setIsFirst(false)
		}
	}, [conf, isFirst]);

	useEffect(() => {
		if (currentPage.page) {
			setConf({ ...conf, category: currentPage.page });
		}
	}, [currentPage, conf]);

	props.deviceIndex = conf.deviceIdx;
	props.serviceIdx = conf.serviceIdx;
	props.value = conf.category;

	props.radios.forEach((radio) => {
		radio.onChange = (e) => setConf({ ...conf, category: radio.name });
		radio.selected = radio.name === conf.category;
	});

	props.serviceRadios.forEach((radio) => {
		radio.onChange = (e) => setConf({ ...conf, serviceIdx: radio.name });
		radio.selected = radio.name === conf.serviceIdx;
	});

	props.button.onClick = (e) => {
		setConf({ ...conf, deviceIdx: e });
	};


	breadcrumbProps.breadcrumbs[breadcrumbProps.breadcrumbs.length - 1].children =
		conf.category.toUpperCase();
	return (
		<React.Fragment>
			<Breadcrumb {...breadcrumbProps} />
			<S.WaveBlock>
				<S.WaveProject src={wave_project} />
			</S.WaveBlock>
			<Wave {...waveProps} />
			<ProjectsSection {...props} />
		</React.Fragment>
	);
};

export default Project;
