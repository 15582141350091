import styled from "styled-components";
import {
	desktopFontSize,
	mobileFontSize,
	viewSize,
	colorPallete,
	fontWeight,
} from "../../../config";

export const Link = styled.a`
	font-size: ${(props) =>
		props.fontSize ? props.fontSize.desktop : desktopFontSize.base};
	font-weight: ${(props) =>
		props.fontWeight ? props.fontWeight : fontWeight.normal};
	color: ${(props) => (props.color ? props.color : colorPallete.head)};
	text-decoration: ${(props) => (props.decoration ? props.decoration : "none")};
	padding: ${(props) => (props.padding ? props.padding : "0px")};
	background-color: ${(props) =>
		props.bgColor ? props.bgColor : "transparent"};
	border: ${(props) => (props.border ? props.border : "none")};
	border-radius: ${(props) => (props.radius ? props.radius : "0px")};
	cursor: ${(props) => (props.cursor ? props.cursor : "pointer")};
	display: flex;
	align-items: center;
	justify-content: center;
	cursor: pointer;
	z-index: 0;
	&:hover {
		color: ${(props) => (props.hoverColor ? props.hoverColor : props.color)};
		transition: 0.5s ease;
		background-color: ${(props) =>
			props.hoverBgColor ? props.hoverBgColor : props.bgColor};

		> svg > path {
			fill: ${(props) =>
				props.hoverColor ? props.hoverColor : colorPallete.secondary};
			transition: 0.5s ease;
		}
	}

	@media screen and (max-width: ${viewSize.mobile}) {
		font-size: ${(props) =>
			props.fontSize ? props.fontSize.mobile : mobileFontSize.base};
	}
`;

export const Icon = styled.div``;
