import {
	desktopFontSize,
	mobileFontSize,
	fontWeight,
	colorPallete,
} from "../../config";
import { breadcrumbsProps } from "../common";
import {
	serviceOneImg,
	serviceThreeImg,
	serviceTwoImg,
	deviceBuoyImg,
	deviceSensorImg,
	deviceProbeImg,
} from "../../config/images";
const textProps = {
	title: {
		color: colorPallete.head,
		fontSize: {
			desktop: desktopFontSize.xl4,
			tablet: desktopFontSize.xl2,
			mobile: mobileFontSize.xl,
		},
		fontWeight: fontWeight.bold,
	},
	serviceTitle: {
		fontSize: {
			desktop: desktopFontSize.xl8,
			tablet: mobileFontSize.xl7,
			mobile: mobileFontSize.xl7,
		},
		fontWeight: fontWeight.black,
		color: colorPallete.line,
	},
	descriptionTitle: {
		fontSize: {
			desktop: desktopFontSize.xl4,
			tablet: desktopFontSize.xl2,
			mobile: mobileFontSize.xl2,
		},
		fontWeight: fontWeight.bold,
		color: colorPallete.head,
		underline: true,
		opacity: 0.3,
		marginRight: "0.3rem",
	},
	descriptionSubTitle: {
		color: colorPallete.head,
		fontSize: {
			desktop: desktopFontSize.xl3,
			tablet: desktopFontSize.xl,
			mobile: mobileFontSize.xl,
		},
		fontWeight: fontWeight.bold,
	},
	description: {
		fontSize: {
			desktop: desktopFontSize.xl,
			tablet: desktopFontSize.base,
			mobile: mobileFontSize.sm,
		},
		fontWeight: fontWeight.medium,
		color: colorPallete.txt,
	},
	serviceDescription: {
		fontSize: {
			desktop: desktopFontSize.xl2,
			tablet: desktopFontSize.xl,
			mobile: mobileFontSize.xl,
		},
		fontWeight: fontWeight.medium,
		color: colorPallete.txt,
	},
	serviceRadio: {
		fontSize: {
			desktop: desktopFontSize.xl4,
			tablet: desktopFontSize.xl2,
			mobile: mobileFontSize.xl3,
		},
		fontWeight: fontWeight.bold,
		opacity: 0.7,
	},
	button: {
		fontSize: {
			desktop: desktopFontSize.xl2,
			mobile: mobileFontSize.xl2,
		},
		fontWeight: fontWeight.bold,
		color: colorPallete.subtxt,
	},
	selectButton: {
		fontSize: {
			desktop: desktopFontSize.xl2,
			mobile: mobileFontSize.xl2,
		},
		fontWeight: fontWeight.bold,
		color: colorPallete.white,
	},
};

const nullProjectProps = {
	value: "",
	onChange: () => console.log("not implement"),
};

const projectRadioProps = {
	fontSize: {
		desktop: desktopFontSize.xl5,
		mobile: mobileFontSize.xl5,
	},
	fontWeight: fontWeight.bold,
	opacity: 0.7,
};

const projectProps = {
	value: "device",
	deviceIndex: 0,
	serviceIdx: 0,
	serviceTitle: { children: `META\n OCEAN`, ...textProps.serviceTitle },
	radios: [
		{
			text: { children: "DEVICE", ...projectRadioProps },
			name: "device",
			onChange: nullProjectProps.onChange,
		},
		{
			text: { children: "SERVICE", ...projectRadioProps },
			name: "service",
			onChange: nullProjectProps.onChange,
		},
	],
	serviceRadios: [
		{
			text: { children: "실시간 양식 환경 정보", ...textProps.serviceRadio },
			underline: false,
			name: 0,
			onChange: nullProjectProps.onChange,
		},
		{
			text: { children: "양식장 디지털 트윈", ...textProps.serviceRadio },
			underline: false,
			name: 1,
			onChange: nullProjectProps.onChange,
		},
		{
			text: { children: "해양 환경 예측 서비스", ...textProps.serviceRadio },
			underline: false,
			name: 2,
			onChange: nullProjectProps.onChange,
		},
	],
	serviceImages: [
		{ src: serviceOneImg, alt: "service_one" },
		{ src: serviceTwoImg, alt: "service_two" },
		{ src: serviceThreeImg, alt: "service_three" },
	],
	serviceDescription: [
		{
			childrens: [
				"스마트 부표 연동 서비스",
				`해양, 기상 공공 데이터\n(Open-API) 제공`,
				"데이터 기반 알림 서비스",
				"적정 사육환경 관리 서비스",
			],
			text: { ...textProps.serviceDescription },
		},
		{
			childrens: [
				"양식장 앱 구현",
				`IP-CAM 등 각종 장비 연동`,
				"스마트 부표 컨트롤",
			],
			text: { ...textProps.serviceDescription },
		},
		{
			childrens: [
				"시계열 환경 변화 예측 서비스",
				`해양 기상 예측 서비스\n(Open-Weather API)`,
				"실시간 해류 이동 정보 (Windy-API)",
				"XAI 분석 리포팅",
			],
			text: { ...textProps.serviceDescription },
		},
	],
	devices: [
		{
			title: { children: `SMART\nBUOY`, ...textProps.serviceTitle },
			subDesktopTitles: [
				{
					childrens: ["스마트 아쿠아팜 디바이스"],
					text: textProps.descriptionTitle,
				},
				{
					childrens: ["부이 타입 솔루션"],
					text: textProps.descriptionTitle,
				},
				{
					childrens: ["멀티 센서 사용"],
					text: textProps.descriptionTitle,
				},
			],
			subMobileTitles: [
				{
					childrens: ["스마트 아쿠아팜 디바이스"],
					text: textProps.descriptionTitle,
				},
				{
					childrens: ["부이 타입 솔루션"],
					text: textProps.descriptionTitle,
				},
				{
					childrens: ["멀티 센서 사용"],
					text: textProps.descriptionTitle,
				},
			],
			descriptions: [
				{
					title: { children: "친환경 소재", ...textProps.descriptionSubTitle },
					subTitle: {
						children: "해양 미세플라스틱 감소",
						...textProps.description,
					},
				},
				{
					title: {
						children: "친환경 에너지",
						...textProps.descriptionSubTitle,
					},
					subTitle: { children: "솔라 에너지", ...textProps.description },
				},
				{
					title: { children: "IOT 통신", ...textProps.descriptionSubTitle },
					subTitle: { children: "NB-IOT", ...textProps.description },
				},
				{
					title: {
						children: "Remote Control",
						...textProps.descriptionSubTitle,
					},
					subTitle: { children: "스마트폰 연동", ...textProps.description },
				},
			],
		},
		{
			title: { children: `SENSOR\nMODULE`, ...textProps.serviceTitle },
			subDesktopTitles: [
				{
					childrens: ["스마트 아쿠아팜 디바이스"],
					text: textProps.descriptionTitle,
				},
				{
					childrens: ["포터블 타입 솔루션"],
					text: textProps.descriptionTitle,
				},
				{
					childrens: ["멀티 센서 사용"],
					text: textProps.descriptionTitle,
				},
			],
			subMobileTitles: [
				{
					childrens: ["스마트 아쿠아팜 디바이스"],
					text: textProps.descriptionTitle,
				},
				{
					childrens: ["포터블 타입 솔루션"],
					text: textProps.descriptionTitle,
				},
				{
					childrens: ["멀티 센서 사용"],

					text: textProps.descriptionTitle,
				},
			],
			descriptions: [
				{
					title: { children: "외부 전원", ...textProps.descriptionSubTitle },
					subTitle: {
						children: "DC 12V, AC 220V",
						...textProps.description,
					},
				},
				{
					title: {
						children: "Output Signal",
						...textProps.descriptionSubTitle,
					},
					subTitle: {
						children: "RS-485 \n(Modbus protocal)",
						...textProps.description,
					},
				},
				{
					title: { children: "IOT 통신", ...textProps.descriptionSubTitle },
					subTitle: { children: "NB-IOT", ...textProps.description },
				},
				{
					title: {
						children: "Remote Control",
						...textProps.descriptionSubTitle,
					},
					subTitle: { children: "스마트폰 연동", ...textProps.description },
				},
			],
		},
		{
			title: { children: `MULTI\nSENSOR`, ...textProps.serviceTitle },
			subDesktopTitles: [
				{
					childrens: ["고정밀 멀티 프로브 센서"],
					text: textProps.descriptionTitle,
				},
				{
					childrens: ["해양 환경 특화"],
					text: textProps.descriptionTitle,
				},
				{
					childrens: ["사용자 맞춤형 측정 요인 제공"],
					text: textProps.descriptionTitle,
				},
			],
			subMobileTitles: [
				{
					childrens: ["고정밀 멀티 프로브 센서"],
					text: textProps.descriptionTitle,
				},
				{
					childrens: ["해양 환경 특화"],
					text: textProps.descriptionTitle,
				},
				{
					childrens: ["사용자 맞춤형 측정 요인 제공"],
					text: textProps.descriptionTitle,
				},
			],
			descriptions: [
				{
					//title: { children: "", ...textProps.descriptionSubTitle },
					subTitle: {
						children: "수온, 용존산소, pH, 염분, 전도도, ORP, TDS,",
						...textProps.description,
					},
				},
				{
					//title: { children: "", ...textProps.descriptionSubTitle },
					subTitle: {
						children: "탁도, ChlorophyⅡ, BGA, 질산염, 염소,",
						...textProps.description,
					},
				},
				{
					//title: { children: "", ...textProps.descriptionSubTitle },
					subTitle: {
						children: "비중, 암모니아, 수압",
						...textProps.description,
					},
				},
			],
			descriptionsTitle: { title: "none" },
		},
	],
	images: [
		{ src: deviceBuoyImg, alt: "디바이스" },
		{ src: deviceSensorImg, alt: "센서" },
		{ src: deviceProbeImg, alt: "프로브" },
	],
	button: {
		text: textProps.button,
		onClick: () => nullProjectProps.onChange,
		bgColor: colorPallete.white,
	},
	selectButton: {
		border: "none",
		text: textProps.selectButton,
		bgColor: colorPallete.primary,
	},
};

export const breadcrumbProps = {
	current: {
		...breadcrumbsProps.title,
		children: "PROJECT",
	},
	lines: [
		{ bgColor: "#8DC63F" },
		{ bgColor: "#FFCB05" },
		{ bgColor: "#ED1C24" },
		{ bgColor: "#00AEEF" },
	],
	breadcrumbs: [
		{ children: "HOME", ...breadcrumbsProps.breadcrumb },
		{ children: "PROJECT", ...breadcrumbsProps.breadcrumb },
		{
			...breadcrumbsProps.breadcrumb,
			children: "DEVICE",
			color: colorPallete.head,
		},
	],
};

export const waveProps = {
	marginTop: {
		desktop: "10rem",
		tablet: "10rem",
		mobile: "10rem",
	},
};

export default projectProps;
