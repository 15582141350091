import React from 'react'
import * as S from './style'

const Empty = ({ bgColor, height, margin }) => {
    const emptyProps = {
        bgColor,
        height,
        margin
    }
    return (
        <S.Container {...emptyProps} />
    )
}

export default Empty