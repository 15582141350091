import React, { useEffect } from 'react'
import {
    MainSection, TechSection, BussinessSection,
    NewsSection, StorySection, ContactSection, PartnerSection
} from '../../components/template'
import mainProps from './main'
import techProps from './tech'
import bussinessProps from './business'
import newsProps from './news'
import storyProps from './story'
import partnerProps from './partner'
import contactProps from './contact'
import modalProps, { bottomContent } from './modal'
import navProps, { topProps } from './nav'
import { setInputForm, setRadioForm } from '../../utils'
import { SideNav } from '../../components/organism'
import { IconButton, Empty, Modal, Map } from '../../components/atom'
import axios from 'axios'
import { LAMBDA_URL } from '../../config'
import { useRecoilValue } from 'recoil'
import { navGetState } from '../../recoils/selectors'

let pageIndex = ['#main', '#tech', '#business', '#news', '#partner', '#contact']

const empty = {
    height: {
        desktop: '15rem',
        tablet: '10rem',
        mobile: '10rem'
    },
    margin: {
        desktop: '-22rem 0',
        tablet: '-2rem 0',
        mobile: '-5rem 0'
    }
}

const Main = () => {
    const [selection, setSelection] = React.useState({
        partner: 'investor'
    })
    const [contact, setContact] = React.useState({
        name: '',
        company: '',
        email: '',
        message: '',
        isChecked: false
    })
    const [current, setCurrent] = React.useState(0)
    const [modal, setModal] = React.useState(false)
    // const [popup, setPopup] = React.useState({
    //     open: true,
    //     detail: false
    // })
    const currentPage = useRecoilValue(navGetState)

    const logit = () => {
        refs.current.forEach((ref, index, array) => {
            if (window.scrollY <= ref.offsetTop + (ref.offsetHeight / 2) && window.scrollY >= ref.offsetTop - (ref.offsetHeight / 2)) {
                index !== current && setCurrent(index)

            }
        })
    }


    const refs = React.useRef([])


    useEffect(() => {
        const watchScroll = () => {
            window.addEventListener('scroll', logit)
        }

        watchScroll()
        return () => {
            window.removeEventListener('scroll', logit)
        }
    })

    useEffect(() => {
        pageIndex.forEach((page, idx, array) => {
            if (page === currentPage.page) {
                refs.current[idx].scrollIntoView({ block: 'start' })
            }
        })
    }, [currentPage])

    useEffect(() => {
        let pageId = window.location.hash
        pageIndex.forEach((page, idx, array) => {
            if (page === pageId) {
                refs.current[idx].scrollIntoView({ block: 'start' })
            }
        })
    }, [refs])

    // nav 관련 설정
    navProps.links.forEach((link, index, array) => {
        link.onClick = () => {
            setCurrent(index)
            refs.current[index].scrollIntoView({ block: 'start' })
        }
        link.selected = index === current
    })

    // 메인 프롭스 설정
    mainProps.link.onClick = () => setModal(true)

    // 파트너 관련 설정
    partnerProps.radios.forEach(item => {
        item.selected = item.value === selection.partner
        item.onChange = (e) => setRadioForm(e, selection, setSelection)
    })
    partnerProps.value = selection.partner

    // 연락 관련 설정
    contactProps.leftForms.forEach(item => {
        item.input.value = contact[item.input.name]
        item.input.onChange = (e) => setInputForm(e, contact, setContact)
    })

    contactProps.rightForms.forEach(item => {
        item.textarea.value = contact[item.textarea.name]
        item.textarea.onChange = (e) => setInputForm(e, contact, setContact)
    })
    contactProps.checkForm.check.onChange = () => setContact({ ...contact, isChecked: !contact.isChecked })
    contactProps.checkForm.check.value = contact.isChecked
    contactProps.buttonForm.onClick = () => {
        try {
            axios({
                method: 'post',
                url: LAMBDA_URL,
                data: {
                    type: 'homepage',
                    company: contact.company,
                    name: contact.name,
                    email: contact.email,
                    content: contact.message
                }
            })
        } catch (e) {
            console.log(e)
        }
    }


    return (
        <React.Fragment>
            <Modal isOpen={modal} onClose={() => setModal(false)} content={<Map {...modalProps} />} bottomContent={bottomContent} />
            <SideNav {...navProps} />
            <MainSection ref={el => refs.current[0] = el} {...mainProps} />
            <TechSection ref={el => refs.current[1] = el} {...techProps} />
            <BussinessSection ref={el => refs.current[2] = el} {...bussinessProps} />
            <Empty margin='5rem 0 0 0' {...empty} />
            <NewsSection ref={el => refs.current[3] = el} {...newsProps} />
            <StorySection {...storyProps} />
            <PartnerSection ref={el => refs.current[4] = el}{...partnerProps} />
            <ContactSection ref={el => refs.current[5] = el} {...contactProps} />
            <IconButton {...topProps} />
        </React.Fragment >
    )
}

export default Main