import React from 'react'
import * as S from './style'
import { SectionTitle, ImgText } from '../../organism'
import { Link } from '../../atom'
import firstBubble from '../../../static/images/firstbubble.png'
import mobileBubble from '../../../static/images/mobilebubble.png'
const GenerateImage = ({ images = [] }) => {
    return images.map((image, index, array) => (
        <S.Image key={index}>
            <ImgText {...image} />
        </S.Image>
    ))
}

const TechSection = React.forwardRef(({ images = {}, sectionTitle, link }, ref) => {
    return (
        <S.Container ref={ref}>
            <S.Bubble src={firstBubble} />
            <S.MobileBubble src={mobileBubble} />
            <S.TitleWrapper>
                <SectionTitle {...sectionTitle} />
            </S.TitleWrapper>
            <Link {...link} />
            <S.ImgWrapper>
                <S.ColImgs >
                    <GenerateImage images={images.left} />
                </S.ColImgs>
                <S.MainImg>
                    <ImgText {...images.main} />
                </S.MainImg>
                <S.ColImgs>
                    <GenerateImage images={images.right} />
                </S.ColImgs>
                <S.RowImgs>
                    <GenerateImage images={images.left} />
                </S.RowImgs>
                <S.RowImgs>
                    <GenerateImage images={images.right} />
                </S.RowImgs>
            </S.ImgWrapper>
        </S.Container>
    )
})

export default TechSection