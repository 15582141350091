import React from 'react'
import * as S from './style'
import { } from 'react-icons/'
import { navIcon } from '../../../config'
const Modal = ({ isOpen = false, content, onClose, bottomContent }) => {
    if (!isOpen) {
        return <></>
    }
    return (
        <S.Container>
            <S.Modal>
                <S.CloseWrapper>{navIcon.closeIcon({ size: '2rem', onClick: onClose })}</S.CloseWrapper>
                <S.ContentWrapper>
                    {content}
                </S.ContentWrapper>
                {bottomContent && (
                    <S.BottomContent>
                        {bottomContent}
                    </S.BottomContent>
                )}
            </S.Modal>
        </S.Container>
    )
}

export default Modal