import styled from "styled-components";
import {
  colorPallete,
  desktopFontSize,
  fontWeight,
  mobileFontSize,
  viewSize,
} from "../../../config";

export const Span = styled.span`
  font-size: ${(props) =>
    props.fontSize ? props.fontSize.desktop : desktopFontSize.base};
  font-weight: ${(props) =>
    props.fontWeight ? props.fontWeight : fontWeight.normal};
  color: ${(props) => (props.color ? props.color : colorPallete.black)};
  word-break: ${(props) => (props.wordBreak ? props.wordBreak : "normal")};
  @media screen and (max-width: ${viewSize.tablet}) {
    font-size: ${(props) =>
      props.fontSize ? props.fontSize.tablet : desktopFontSize.base};
    white-space: pre-line;
  }

  @media screen and (max-width: ${viewSize.mobile}) {
    font-size: ${(props) =>
      props.fontSize ? props.fontSize.mobile : mobileFontSize.base};
    white-space: pre-line;
    margin-right: ${(props) => (props.marginRight ? props.marginRight : "0px")};
  }
`;

export const P = styled.p`
  font-size: ${(props) =>
    props.fontSize ? props.fontSize.desktop : desktopFontSize.base};
  font-weight: ${(props) =>
    props.fontWeight ? props.fontWeight : fontWeight.normal};
  color: ${(props) => (props.color ? props.color : colorPallete.black)};

  @media screen and (max-width: ${viewSize.tablet}) {
    font-size: ${(props) =>
      props.fontSize ? props.fontSize.tablet : desktopFontSize.base};
    white-space: pre-line;
  }

  @media screen and (max-width: ${viewSize.mobile}) {
    font-size: ${(props) =>
      props.fontSize ? props.fontSize.mobile : mobileFontSize.base};
  }
`;

export const H1 = styled.h1`
  font-size: ${(props) =>
    props.fontSize ? props.fontSize.desktop : desktopFontSize.xl5};
  font-weight: ${(props) =>
    props.fontWeight ? props.fontWeight : fontWeight.black};
  color: ${(props) => (props.color ? props.color : colorPallete.black)};

  @media screen and (max-width: ${viewSize.tablet}) {
    font-size: ${(props) =>
      props.fontSize ? props.fontSize.tablet : desktopFontSize.xl5};
    white-space: pre-line;
  }

  @media screen and (max-width: ${viewSize.mobile}) {
    font-size: ${(props) =>
      props.fontSize ? props.fontSize.mobile : mobileFontSize.xl4};
  }
`;

export const H2 = styled.h2`
  font-size: ${(props) =>
    props.fontSize ? props.fontSize.desktop : desktopFontSize.xl4};
  font-weight: ${(props) =>
    props.fontWeight ? props.fontWeight : fontWeight.extraBold};
  color: ${(props) => (props.color ? props.color : colorPallete.black)};

  @media screen and (max-width: ${viewSize.tablet}) {
    font-size: ${(props) =>
      props.fontSize ? props.fontSize.tablet : desktopFontSize.xl4};
    white-space: pre-line;
  }

  @media screen and (max-width: ${viewSize.mobile}) {
    font-size: ${(props) =>
      props.fontSize ? props.fontSize.mobile : mobileFontSize.xl3};
  }
`;

export const H3 = styled.h3`
  font-size: ${(props) =>
    props.fontSize ? props.fontSize.desktop : desktopFontSize.xl3};
  font-weight: ${(props) =>
    props.fontWeight ? props.fontWeight : fontWeight.bold};
  color: ${(props) => (props.color ? props.color : colorPallete.black)};

  @media screen and (max-width: ${viewSize.tablet}) {
    font-size: ${(props) =>
      props.fontSize ? props.fontSize.tablet : desktopFontSize.xl3};
    white-space: pre-line;
  }

  @media screen and (max-width: ${viewSize.mobile}) {
    font-size: ${(props) =>
      props.fontSize ? props.fontSize.mobile : mobileFontSize.xl2};
  }
`;

export const H4 = styled.h4`
  font-size: ${(props) =>
    props.fontSize ? props.fontSize.desktop : desktopFontSize.xl2};
  font-weight: ${(props) =>
    props.fontWeight ? props.fontWeight : fontWeight.bold};
  color: ${(props) => (props.color ? props.color : colorPallete.black)};

  @media screen and (max-width: ${viewSize.tablet}) {
    font-size: ${(props) =>
      props.fontSize ? props.fontSize.tablet : desktopFontSize.xl2};
    white-space: pre-line;
  }

  @media screen and (max-width: ${viewSize.mobile}) {
    font-size: ${(props) =>
      props.fontSize ? props.fontSize.mobile : mobileFontSize.xl2};
  }
`;

export const H5 = styled.h5`
  font-size: ${(props) =>
    props.fontSize ? props.fontSize.desktop : desktopFontSize.xl};
  font-weight: ${(props) =>
    props.fontWeight ? props.fontWeight : fontWeight.medium};
  color: ${(props) => (props.color ? props.color : colorPallete.black)};

  @media screen and (max-width: ${viewSize.tablet}) {
    font-size: ${(props) =>
      props.fontSize ? props.fontSize.tablet : desktopFontSize.xl};
    white-space: pre-line;
  }

  @media screen and (max-width: ${viewSize.mobile}) {
    font-size: ${(props) =>
      props.fontSize ? props.fontSize.mobile : mobileFontSize.xl};
  }
`;

export const H6 = styled.h6`
  font-size: ${(props) =>
    props.fontSize ? props.fontSize.desktop : desktopFontSize.lg};
  font-weight: ${(props) =>
    props.fontWeight ? props.fontWeight : fontWeight.medium};
  color: ${(props) => (props.color ? props.color : colorPallete.black)};

  @media screen and (max-width: ${viewSize.tablet}) {
    font-size: ${(props) =>
      props.fontSize ? props.fontSize.tablet : desktopFontSize.lg};
    white-space: pre-line;
  }

  @media screen and (max-width: ${viewSize.mobile}) {
    font-size: ${(props) =>
      props.fontSize ? props.fontSize.mobile : mobileFontSize.lg};
  }
`;

export const Underline = styled.div`
  width: 100%;
  background-color: ${(props) =>
    props.lineColor ? props.lineColor : colorPallete.primary};
  border-radius: ${(props) => (props.radius ? props.radius : "5px")};
  opacity: ${(props) => props.opacity && props.opacity};
  position: absolute;
  bottom: 2px;
  height: 0.5rem;
  z-index: 0;
  mix-blend-mode: darken;

  @media screen and (max-width: ${viewSize.mobile}) {
    height: 0.25rem;
  }
`;

export const UnderlineContainer = styled.div`
  position: relative;
  display: flex;
  width: fit-content;
`;
