import React from "react";
import * as S from "./style";

const GenerateText = ({
  fontSize,
  fontWeight,
  color,
  children,
  textType,
  className,
  marginRight,
  whiteSpace,
  wordBreak,
}) => {
  let textConfig = {
    fontSize,
    fontWeight,
    color,
    className,
    marginRight,
    whiteSpace,
    wordBreak,
  };

  switch (textType) {
    case "h1":
      return <S.H1 {...textConfig}>{children}</S.H1>;
    case "h2":
      return <S.H2 {...textConfig}>{children}</S.H2>;
    case "h3":
      return <S.H3 {...textConfig}>{children}</S.H3>;
    case "h4":
      return <S.H4 {...textConfig}>{children}</S.H4>;
    case "h5":
      return <S.H5 {...textConfig}>{children}</S.H5>;
    case "h6":
      return <S.H6 {...textConfig}>{children}</S.H6>;
    case "p":
      return <S.P {...textConfig}>{children}</S.P>;
    default:
      return <S.Span {...textConfig}>{children}</S.Span>;
  }
};

const Text = ({
  children,
  textType = "span",
  className,
  fontSize,
  fontWeight,
  color,
  lineColor,
  radius,
  underline = false,
  opacity,
  marginRight,
}) => {
  let textConfig = {
    fontSize,
    fontWeight,
    color,
    children,
    textType,
    className,
    marginRight,
  };

  let underlineProps = {
    lineColor,
    radius,
    opacity,
  };

  if (underline) {
    return (
      <S.UnderlineContainer>
        <GenerateText {...textConfig} />
        <S.Underline {...underlineProps} />
      </S.UnderlineContainer>
    );
  }

  return <GenerateText {...textConfig} />;
};

export default Text;
