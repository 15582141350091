import styled from "styled-components";
import { viewSize } from "../../../config";

export const Container = styled.div`
  height: 100%;
`;

export const IconContainer = styled.div`
  width: 60%;
  position: relative;
  margin: 0 auto;
  margin-top: -13rem;
  justify-content: center;
  align-items: center;
  text-align: center;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-template-rows: 9.375rem 9.375rem;
  row-gap: 3rem;

  @media screen and (max-width: ${viewSize.mobile}) {
    margin-top: -17rem;
    width: 100%;
    row-gap: 0rem;
    border-radius: 0.8rem;
    background-color: white;
  }
`;

export const IconImg = styled.img`
  width: 35%;
  margin: 0 auto;
  display: flex;
  padding-bottom: 1.25rem;
  transition: 0.3s;

  &:hover {
    filter: drop-shadow(2px 2px 4px #000);
    transition: 0.3s;
    transform: scale(1.05);
  }

  @media screen and (max-width: ${viewSize.mobile}) {
    width: 45%;
  }
`;

export const Anchor = styled.div`
  justify-content: space-between;
`;
