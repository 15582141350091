import styled, { css, keyframes } from "styled-components";
import { viewSize, defaultSize } from "../../../config";
import { SeaWave } from "../../../animation/intro";

export const Hide = keyframes`
    from{
        opacity:1;
    }
    to{
       opacity:0;
    }
`;

export const Show = keyframes`
    0%{
        opacity:0;
    }
    100% {
        opacity:1;
    }
`;

const hideAnimation = (props) => css`
  ${Hide} 0.01s forwards
`;

const showAnimation = (props) => css`
  ${Show} 0.01s forwards
`;

const reverseCircleRotate = (props) => keyframes`
    0%{
        transform: rotate(60deg) translateX(-100px) translateY(-1200px) rotate(-60deg);
    }
    100% {
        transform: rotate(0deg) translateX(0px) translateY(0px) rotate(0deg) ;
    }
`;

const secondReverseCircleRotate = (props) => keyframes`
    0%{
        transform: rotate(0deg) translateX(0px) translateY(0px) rotate(0deg) ;
    }
    100% {
        transform: rotate(-60deg) translateX(-100px) translateY(1200px) rotate(60deg);
        display : none;
    }
`;

const reverseCircleRotateAnimation = (props) => css`
  ${reverseCircleRotate} 1s forwards;
`;

const secondReverseCircleRotateAnimation = (props) => css`
  ${secondReverseCircleRotate} 1s forwards;
`;

export const Container = styled.div`
  display: flex;
  position: relative;
  width: 95%;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 5rem 0 10rem 0;
  max-width: ${defaultSize.desktop.container};
  margin: auto;
  height: 600vh;
  overflow: clip;
`;

export const Wrapper = styled.div`
  width: 100%;
  height: 100vh;
  margin-top: -3em;
  /* margin: 1rem auto 1rem 0; */
  display: ${(props) => (props.isMobile ? "none" : "flex")};
  flex-direction: column;
  position: relative;
  align-items: center;

  @media screen and (max-width: ${viewSize.mobile}) {
    flex-direction: column;
    margin: -4rem auto 1rem 0;
    display: ${(props) => (props.isMobile ? "flex" : "none")};
  }
`;

export const RadioWrapper = styled.div`
  display: flex;
  margin-top: 3rem;
  z-index: 1;
  /* margin-bottom: 7.25rem; */

  > div {
    margin: 1rem;
  }
  @media screen and (max-width: ${viewSize.mobile}) {
    margin-top: 0rem;
  }
`;

export const DeviceWrapper = styled.div`
  width: 45%;
  animation: ${(props) => (props.selected ? showAnimation : hideAnimation)};
  @media screen and (max-width: ${viewSize.mobile}) {
    width: 100%;
  }
`;

export const DeviceItem = styled.div``;

export const DeviceButtonWrapper = styled.div`
  width: 10%;
  flex-direction: column;
  justify-content: center;
  margin-top: -2rem;
  margin-left: 2rem;
  position: sticky;
  top: 20%;
  > button {
    margin: 0.5rem 0;
  }
  display: flex;
  animation: ${(props) =>
    props.isDesktop && props.selected ? showAnimation : hideAnimation};

  @media screen and (max-width: ${viewSize.mobile}) {
    top: 10%;
    right: 10%;
    margin: auto 0 -35% auto;
    z-index: 9999;
  }
`;

export const MobileDeviceButtonWrapper = styled.div`
  width: 10%;
  flex-direction: column;
  justify-content: center;
  position: absolute;
  top: 20%;
  > button {
    margin: 0.5rem 0;
  }
  display: flex;

  @media screen and (max-width: ${viewSize.mobile}) {
    top: 10%;
    right: 10%;
    margin: auto 0 -35% auto;
    z-index: 9999;
  }
`;

export const DeviceImgWrapper = styled.div`
  position: absolute;
  right: 0%;
  top: 10%;
  width: 40%;
  animation: ${(props) =>
    props.selected
      ? reverseCircleRotateAnimation
      : secondReverseCircleRotateAnimation};

  @media screen and (max-width: ${viewSize.mobile}) {
    position: static;
    width: 100%;
    display: flex;
    justify-content: center;
  }
`;

export const DeviceImg = styled.img`
  width: 100%;
`;

export const Services = styled.div`
  display: flex;
  flex-direction: column;
  height: 100vh;
  width: 100%;
`;

export const ServiceWrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: center;

  @media screen and (max-width: ${viewSize.tablet}) {
    flex-direction: column;
    top: 25%;
  }
`;

export const ServiceDescriptionWrapper = styled.div`
  display: flex;
  width: 25%;
  animation: ${(props) => (props.selected ? showAnimation : hideAnimation)};
  @media screen and (max-width: ${viewSize.tablet}) {
    width: 100%;
  }
  @media screen and (max-width: ${viewSize.mobile}) {
    width: 100%;
  }
`;

export const ServiceItem = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  white-space: pre-line;
  > span {
    margin: 0.625rem 0;
  }

  @media screen and (max-width: ${viewSize.mobile}) {
    width: fit-content;
    margin: auto auto auto 5%;
  }
`;

export const ServiceRadioWrapper = styled.div`
  width: 25%;
  animation: ${(props) => (props.selected ? showAnimation : hideAnimation)};
  > div {
    margin: 1rem 0;
  }
  @media screen and (max-width: ${viewSize.tablet}) {
    width: 100%;
    text-align: end;
  }
  @media screen and (max-width: ${viewSize.mobile}) {
    width: fit-content;
    margin: auto 5% auto auto;
  }
`;

export const ServieImgWrapper = styled.div`
  width: 50%;
  animation: ${(props) => (props.selected ? showAnimation : hideAnimation)};

  @media screen and (max-width: ${viewSize.mobile}) {
    width: 100%;
  }
`;
export const ServiceImg = styled.img`
  width: 100%;
  @media screen and (max-width: ${viewSize.mobile}) {
    width: 80%;
  }
`;

export const ServiceTitle = styled.div`
  width: 100%;
  margin-bottom: 3rem;
  animation: ${(props) => (props.selected ? showAnimation : hideAnimation)};
  @media screen and (max-width: ${viewSize.tablet}) {
    margin-bottom: 1.25rem;
    > span {
      white-space: nowrap;
    }
  }
`;

export const AnimationWrapper = styled.div`
  animation: ${(props) => (props.selected ? showAnimation : hideAnimation)};
  animation: ${(props) => (props.unselected ? hideAnimation : showAnimation)};
  position: absolute;
  top: 10%;
  width: 70%; // project별 내용변화로 크기 변화 막기
`;

export const VisibilityHidden = styled.div`
  visibility: hidden;
  padding-bottom: 20rem;
`;

export const DesktopDeviceWrapper = styled.div`
  display: flex;
  width: 100%;
  position: absolute;
  top: 10%;
  padding-bottom: 20rem;
  animation: ${(props) => (props.selected ? showAnimation : hideAnimation)};
  animation: ${(props) => (props.unselected ? hideAnimation : showAnimation)};
`;

export const SeviceW = styled.div`
  display: flex;
  flex-direction: column;
  position: absolute;
  top: 20%;

  @media screen and (max-width: ${viewSize.tablet}) {
    top: 12rem;
  }
  @media screen and (max-width: ${viewSize.mobile}) {
    top: 5rem; //15%;
  }
`;

export const WaveProject = styled.img`
  position: relative;
  width: 100%;
  animation: ${SeaWave} 5s linear infinite;
`;

export const WaveBlock = styled.div`
  overflow: hidden;
  position: absolute;
  top: 35em;
  width: 100%;
  height: 20vh;
  margin-top: -10vh;
`;

export const StickyBox = styled.div`
  margin-top: -470vh;
  width: 100%;
  position: sticky;
  display: flex;
  flex-direction: column;
  align-items: center;
  top: 0;
  /* background-color: lightgray; */
`;
