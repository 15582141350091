import styled from "styled-components"
import { colorPallete, defaultSize } from "../../../config"

export const Container = styled.div`
    position : fixed;
    width : 100%;
    height: 100vh;
    background-color : ${colorPallete.modalBackground};
    display : flex;
    align-items : center;
    justify-content : center;
    top: 0;
    z-index: 99999999;
`

export const Modal = styled.div`
    background-color : ${colorPallete.white};
    max-width : ${defaultSize.desktop.container};
    width : 95%;
    display : flex;
    flex-direction : column;
    min-height : 30rem;
`

export const CloseWrapper = styled.div`
    width : 100%;
    display : flex;
    align-items : center;
    justify-content : flex-start;
    margin-bottom : -2rem;
    z-index : 99999999;
    cursor : pointer;
`

export const ContentWrapper = styled.div`
    width : 100%;
    display : flex;
`



export const BottomContent = styled.div`
    background-color : ${colorPallete.white};
    display : flex;
    border-radius : 1rem;
    width : fit-content;
    box-sizing : border-box;
    padding : 2rem 1rem;
    min-height : 5rem;
    margin-left: 5%;
    margin-top: -10rem;
    z-index : 99999;

    > div{
        margin : auto 0.5rem;
    }
`