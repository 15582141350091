import styled from "styled-components";
import { colorPallete, defaultSize, viewSize } from "../../../config";

export const Container = styled.div`
	width: ${(props) =>
		props.width ? props.width.desktop : defaultSize.desktop.cardWidth};
	height: ${(props) =>
		props.height ? props.height.desktop : defaultSize.desktop.cardHeight};
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	background-color: ${(props) =>
		props.bgColor ? props.bgColor : colorPallete.bg};
	box-sizing: border-box;
	padding: 1rem;
	position: relative;
	overflow: hidden;
	> span {
		position: absolute;
		top: 0.5rem;
		left: 1rem;
		padding: 5px;
	}

	@media screen and (max-width: ${viewSize.mobile}) {
		width: ${(props) =>
			props.width ? props.width.mobile : defaultSize.mobile.cardWidth};
		height: ${(props) =>
			props.height ? props.height.mobile : defaultSize.mobile.cardHeight};
	}
`;
export const Images = styled.li`
	width: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
	animation: ${(props) => props.animation && props.animation};
	animation-duration: 180s;
	margin-top: 2rem;
	@media screen and (max-width: ${viewSize.mobile}) {
		animation-duration: 50s;
	}
`;
