import React from "react";
import { TextForm } from "../../molecule";
import * as S from "./style";
const TextForms = ({ texts = [] }) => {
  return (
    <S.Container>
      {texts.map((text, index) => (
        <TextForm {...text} />
      ))}
    </S.Container>
  );
};

export default TextForms;
