import React from 'react'
import * as S from './style'
import { Text } from '../index'


const Icon = ({ text, img, icon }) => {
    return (
        <S.Container>
            {img && (<S.Icon {...img} />)}
            {icon && icon()}
            <S.Description>
                <Text {...text} />
            </S.Description>
        </S.Container>
    )
}

export default Icon