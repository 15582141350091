import {
	fontWeight,
	mobileFontSize,
	desktopFontSize,
	colorPallete,
} from "../../config";
import { SearchIcon } from "../../config/images";

const textProps = {
	title: {
		fontSize: {
			desktop: desktopFontSize.xl7,
			tablet: desktopFontSize.xl6,
			mobile: mobileFontSize.xl5,
		},
		fontWeight: fontWeight.extraBold,
		color: colorPallete.display,
	},
	title2: {
		fontSize: {
			desktop: desktopFontSize.xl7,
			tablet: desktopFontSize.xl6,
			mobile: mobileFontSize.xl5,
		},
		fontWeight: fontWeight.extraBold,
		color: colorPallete.display,
	},
	description: {
		fontSize: {
			desktop: desktopFontSize.xl,
			tablet: desktopFontSize.lg,
			mobile: mobileFontSize.xl,
		},
		fontWeight: fontWeight.medium,
		color: colorPallete.txt,
	},
	subTitle: {
		fontSize: {
			desktop: desktopFontSize.xl5,
			tablet: desktopFontSize.xl5,
			mobile: mobileFontSize.xl4,
		},
		fontWeight: fontWeight.extraBold,
		color: colorPallete.display,
	},
	subDescription: {
		fontSize: {
			desktop: desktopFontSize.lg,
		},
		fontWeight: fontWeight.medium,
		color: colorPallete.txt,
	},
};

const props = {
	lines: [
		{ bgColor: "#8DC63F" },
		{ bgColor: "#FFCB05" },
		{ bgColor: "#ED1C24" },
		{ bgColor: "#00AEEF" },
	],
	title: { ...textProps.title, children: `Digital Transformation` },
	title2: { ...textProps.title2, children: `of The Aqua Farm` },
	description: {
		...textProps.description,
		children: "스마트 아쿠아팜의 시작, 오든이 함께 합니다",
	},
	subTitle: {
		...textProps.subTitle,
		children: `지역 어촌계\n어민 여러분들과\n함께 합니다.`,
	},
	//   subDescription: { ...textProps.subDescription, children: `완도군 가학어촌계, 넙고리어촌계, 천동어촌계,\n신안군 마진어촌계, 팽진어촌계, 순천시 와온어촌계`, },
	link: {
		color: colorPallete.white,
		children: "자세히 보기",
		fontWeight: fontWeight.bold,
		fontSize: { desktop: desktopFontSize.base, mobile: mobileFontSize.base },
		border: "1px solid white",
		bgColor: colorPallete.primary,
		hoverBgColor: colorPallete.secondary,
		radius: "3rem",
		padding: "0.75rem 1.5rem",
		lIcon: <SearchIcon style={{ marginRight: "0.5rem" }} />,
	},
};

export default props;
