import {
	sectionProps,
	partnersRadioProps,
	cardProps,
	subCategoryProps,
} from "../common";
import {
	originPartnerAnimation,
	reversePartnerAnimation,
} from "../../animation/partners";
import {
	지역창업고용진흥협회,
	광주광역시,
	국제인증공사,
	순천대학교창업지원단,
	순천시,
	전남정보문화산업진흥원,
	전남테크노파크,
	전라남도,
	중소조선연구원,
	창업연당,
	한국광기술원,
	한국농업기술진흥원,
	한국벤처투자,
	한국산업기술진흥협회,
	한국인공지능협회,
	한국조선해양기자재연구원,
	대진금형,
	모토모,
	엑센트리,
	에스컴텍,
	에이버추얼,
	주영테크,
	푸른중공업,
	scg,
	sf이음,
	탑,
	전남창조경제혁신센터,
	특허법인다울,
	aqualabo,
	빅웨이브에이아이,
	조선대학교,
	한밭대학교,
	신보
} from "../../config/images";

export let nullDataProps = {
	onChange: () => console.log("not implement"),
	value: "",
};

let props = {
	sectionTitle: {
		title: {
			...sectionProps.title,
			children: "PARTNERS",
		},
		description: {
			...sectionProps.description,
			children: "오든과 함께하는 파트너",
		},
	},
	value: nullDataProps.value,
	radios: [
		{
			text: { children: "INVESTOR", ...partnersRadioProps },
			name: "partner",
			value: "investor",
			selected: "investor" === nullDataProps.value,
			onChange: nullDataProps.onChange,
		},
		{
			text: { children: "BUSINESS", ...partnersRadioProps },
			name: "partner",
			value: "business",
			selected: "business" === nullDataProps.value,
			onChange: nullDataProps.onChange,
		},
	],
	imgCards: [
		{
			category: "investor",
			items: [
				{
					image: { src: 엑센트리, alt: "엑센트리" },
					href: "http://www.xntreeventures.com/default/",
					...cardProps,
					imageWidth: "266px",
				},
				{
					image: { src: 전남창조경제혁신센터, alt: "전남창조경제혁신센터" },
					href: "https://ccei.creativekorea.or.kr/jeonnam/",
					...cardProps,
					imageWidth: "266px",
				},
				{
					image: { src: 한국벤처투자, alt: "한국벤처투자" },
					href: "https://www.kvic.or.kr/",
					...cardProps,
					imageWidth: "266px",
				},
				{
					image: { src: 신보, alt: "신용보증기금" },
					href: "https://www.kodit.co.kr/",
					...cardProps,
					imageWidth: "266px",
				},
			],
		},
		{
			category: "business",
			items: [
				{
					...subCategoryProps,
					children: "Public & University",
					animation: originPartnerAnimation,
					items: [
						{
							image: { src: 전라남도, alt: "전라남도" },
							href: "https://www.jeonnam.go.kr/",
						},
						{
							image: { src: 순천시, alt: "순천시" },
							href: "https://www.suncheon.go.kr/kr/",
						},
						{
							image: { src: 광주광역시, alt: "광주광역시" },
							href: "https://www.gwangju.go.kr/main.do",
						},
						{
							image: { src: 순천대학교창업지원단, alt: "순천대학교창업지원단" },
							href: "http://startup.scnu.ac.kr/",
						},
						{
							image: { src: 조선대학교, alt: "조선대학교" },
							href: "https://www.chosunai.kr/",
						},
						{
							image: { src: 한밭대학교, alt: "한밭대학교" },
							href: "https://iucf.hanbat.ac.kr/html/kr/",
						},
						{
							image: { src: 전라남도, alt: "전라남도" },
							href: "https://www.jeonnam.go.kr/",
						},
						{
							image: { src: 순천시, alt: "순천시" },
							href: "https://www.suncheon.go.kr/kr/",
						},
						{
							image: { src: 광주광역시, alt: "광주광역시" },
							href: "https://www.gwangju.go.kr/main.do",
						},
						{
							image: { src: 순천대학교창업지원단, alt: "순천대학교창업지원단" },
							href: "http://startup.scnu.ac.kr/",
						},
						{
							image: { src: 조선대학교, alt: "조선대학교" },
							href: "https://www.chosunai.kr/",
						},
						{
							image: { src: 한밭대학교, alt: "한밭대학교" },
							href: "https://iucf.hanbat.ac.kr/html/kr/",
						},
					],
					...cardProps,
				},
				{
					...subCategoryProps,
					children: "Company",
					animation: reversePartnerAnimation,
					items: [
						{
							image: { src: aqualabo, alt: "aqualabo" },
							href: "https://en.aqualabo.fr/",
						},
						{
							image: { src: 빅웨이브에이아이, alt: "빅웨이브에이아이" },
							href: "https://bigwaveai.com/",
						},
						{
							image: { src: 주영테크, alt: "주영테크" },
							href: "http://juyoungtech.co.kr/",
						},
						{
							image: { src: 에스컴텍, alt: "에스컴텍" },
							href: "http://s-comtech.com/",
						},
						{ image: { src: 탑, alt: "주식회사탑" }, ...cardProps },
						{ image: { src: 대진금형, alt: "대진금형" }, ...cardProps },
						{
							image: { src: 모토모, alt: "모토모" },
							href: "http://motomo.co.kr/",
						},
						{
							image: { src: sf이음, alt: "sf이음" },
							href: "http://sfeum.kr/",
						},
						{
							image: { src: 푸른중공업, alt: "푸른중공업" },
							href: "https://www.ghiyachts.com/",
						},
						{
							image: { src: 에이버추얼, alt: "에이버추얼" },
							href: " http://www.avirtual.co.kr",
						},
					],
					...cardProps,
				},
				{
					...subCategoryProps,
					children: "Institute",
					animation: originPartnerAnimation,
					items: [
						{
							image: {
								src: 한국조선해양기자재연구원,
								alt: "한국조선해양기자재연구원",
							},
							href: "http://www.komeri.re.kr/smartcms/page/main",
						},
						{
							image: { src: 중소조선연구원, alt: "중소조선연구원" },
							href: "https://www.rims.re.kr/",
						},
						{
							image: { src: 전남테크노파크, alt: "전남테크노파크" },
							href: "http://www.jntp.or.kr/home/main.do",
						},
						{
							image: { src: 한국인공지능협회, alt: "한국인공지능협회" },
							href: "https://www.koraia.org/default/",
						},
						{
							image: {
								src: 전남정보문화산업진흥원,
								alt: "전남정보문화산업진흥원",
							},
							href: "https://jcia.or.kr/cf/index.do",
						},
						{
							image: { src: 한국광기술원, alt: "한국광기술원" },
							href: "https://kopti.re.kr/ko/index.do",
						},
						{
							image: { src: 창업연당, alt: "창업연당" },
							href: "https://scbi.kr/",
						},
						{
							image: { src: 한국산업기술진흥협회, alt: "한국산업기술진흥협회" },
							href: "https://www.koita.or.kr/main/main.aspx",
						},
						{
							image: { src: 한국농업기술진흥원, alt: "한국농업기술진흥원" },
							href: "https://www.koat.or.kr/action.do?action=main",
						},
						{
							image: { src: 지역창업고용진흥협회, alt: "지역창업고용진흥협회" },
							href: "https://rsepa.modoo.at/",
						},
						{
							image: { src: 국제인증공사, alt: "국제인증공사" },
							href: "http://www.gc2.or.kr/",
						},
						{
							image: { src: scg, alt: "scg" },
							href: "http://scgtalent.com/",
						},
						{
							image: { src: 특허법인다울, alt: "특허법인다울" },
							href: "http://www.ipdawool.com/ko/index.php",
						},
					],
					...cardProps,
				},
			],
		},
	],
};

export default props;
