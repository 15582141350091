import { Nav as NavTemplate } from '../../components/template'
import { navProps } from './data'
import { navState } from '../../recoils/atoms'
import { useRecoilState } from 'recoil'

const Nav = () => {
    const [page, setPage] = useRecoilState(navState)
    navProps.details[0].menus.forEach(menu => {
        menu.onClick = () => {
            setPage({ isOpen: false, page: menu.key })
        }
    })
    navProps.details[1].menus.forEach(menu => {
        menu.onClick = () => {
            setPage({ isOpen: false, page: menu.key })
        }
    })

    navProps.details[2].title.onClick = () => {
        setPage({ isOpen: false, page: navProps.details[2].title.key })
    }

    navProps.mains[2].onClick = () => {
        setPage({ isOpen: false, page: navProps.mains[2].key })
    }

    navProps.details[2].menus[0].onClick = () => {
        setPage({ isOpen: false, page: navProps.details[2].menus[0].key })
    }

    return (
        <NavTemplate {...navProps} isOpen={page.isOpen} setOpen={(e) => setPage({ ...page, isOpen: e })} />
    )
}

export default Nav