import React from 'react'
import * as S from './style'
import { Text } from '../index'
import { colorPallete } from '../../../config'


const Radio = ({ text, value, onChange, name, selected, underline = true }) => {
    const radioProps = {
        value,
        onChange,
        name,
        type: 'radio'
    }
    if (underline) {
        text = {
            ...text,
            color: selected ? colorPallete.display : colorPallete.txt,
            underline: selected ? true : false,
            lineColor: colorPallete.primary,
            radius: '0.4rem',
        }
    } else {
        text = {
            ...text,
            color: selected ? colorPallete.secondary : colorPallete.subtxt,
        }
    }


    return (
        <S.Container onClick={() => onChange({ target: { name, value } })}>
            <S.HiddenRadio {...radioProps} />
            <S.Label>
                <Text {...text} />
            </S.Label>
        </S.Container>
    )
}

export default Radio